import React, { useEffect, useRef } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Button, Grid } from 'semantic-ui-react';
import {
	josaGaType,
	josaUnType,
	laborContractStyle,
	numStringToNumber,
	printRoe,
} from '../../../../app/common/util/util';
import { listenToClientReq4InFromFirestore } from '../../../../app/firestore/firestoreService4In';
import useFirestoreDoc from '../../../../app/hooks/useFirestoreDoc';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import {
	clearClientReqs4In,
	fetchClientReqs4In,
	listenToSelectedClientReq4In,
} from '../clientReqActions4In';
import ClientReqDetailedHeader4In from './ClientReqDetailedHeader4In';
import ClientReqDLCHeadRuleObey from './clientReqDetailedLCDetail/ClientReqDLCHeadRuleObey';
import PaydocuShortCut from '../../../companyManage/paydocuDashboard/PaydocuShortCut';
import './ClientRDRuleObeyAgree.css'; // Importing CSS file

export default function ClientRDRuleObeyAgree({ match }) {
	const htmlContentRef = useRef(null);

	const dispatch = useDispatch();
	const { selectedClientReq4In, clientReqs4In } = useSelector(
		(state) => state.clientReq4InStore
	);
	const { clientInfo, filter } = useSelector((state) => state.companyReducer);
	const { loading, error } = useSelector((state) => state.async);
	const { currentUserProfile } = useSelector((state) => state.profile);

	//노무사는 노무사가 입력한 회사로 회사이름 설정 가능하고, 유저는 본인 회원가입한 상호로만 회사이름 설정됨.
	const isWorker = currentUserProfile?.isWorker;

	const companyName = isWorker
		? currentUserProfile?.workerInputCompanyName
			? currentUserProfile?.workerInputCompanyName
			: selectedClientReq4In?.companyName
		: clientInfo?.companyName
		? clientInfo?.companyName
		: selectedClientReq4In?.company?.companyName
		? selectedClientReq4In?.company?.companyName
		: selectedClientReq4In?.companyName;

	const worker = selectedClientReq4In?.worker?.name;

	useFirestoreDoc({
		shouldExcute:
			match.params.id !== selectedClientReq4In?.id &&
			Location.pathname !== '/createClientReq4In',
		query: () => listenToClientReq4InFromFirestore(match.params.id),
		data: (clientReq4In) =>
			dispatch(listenToSelectedClientReq4In(clientReq4In)),
		deps: [match.params.id, dispatch],
	});

	useEffect(() => {
		dispatch(fetchClientReqs4In(filter)).then(() => {});
		return () => {
			dispatch(clearClientReqs4In());
		};
	}, [dispatch, filter]);

	if (loading || (!selectedClientReq4In && !error))
		return <LoadingComponent content='Loading event ...' />;
	if (error) return <Redirect to='/error' />;

	let today = new Date();

	const companyGa = josaGaType(companyName);
	const workerUn = josaUnType(worker);
	const workerGa = josaGaType(worker);

	const daylyTimePay = selectedClientReq4In?.daylyPay?.timeWage
		? numStringToNumber(selectedClientReq4In?.daylyPay?.timeWage)
		: 0;
	const isDayOrTimeWorker =
		selectedClientReq4In?.dayWorker?.daylyPay || daylyTimePay ? true : false;

	const printFunc = () => {
		printRoe();
	};
	return (
		<>
			{isWorker ? null : (
				<PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In} />
			)}

			<Grid>
				<Grid.Column width={16}>
					<ClientReqDetailedHeader4In
						selectedClientReq4In={selectedClientReq4In}
						companyName={companyName}
						clientReqs4In={clientReqs4In}
						isDayOrTimeWorker={isDayOrTimeWorker}
						daylyTimePay={daylyTimePay}
					/>
					<Button type='button' color='blue' onClick={() => printFunc()}>
						출력
					</Button>
					<br />
					<br />
					<div id='roeContents' ref={htmlContentRef}>
						<ClientReqDLCHeadRuleObey
							currentUserProfile={currentUserProfile}
							selectedClientReq4In={selectedClientReq4In}
							laborContractStyle={laborContractStyle}
							companyName={companyName}
							worker={worker}
						/>
						<br />
						<div className='jau__contents'>
							<div className='section-title'>복무 서약</div>
							<div className='content-item'>{`${workerUn} ${companyName}의 Mission, Vision, 핵심 가치를 숙지하고 성실히 이행하겠습니다.`}</div>
							<div className='content-item'>{`${workerUn} ${companyName}의 정책, 업무지시, 인사명령 등 본인에게 주어진 의무를 적극 이행하겠습니다.`}</div>
							<div className='content-item'>{`${workerUn} 근로계약을 철저히 준수할 것이며, 위법 사항이 없는 한 이의를 제기하지 않겠습니다.`}</div>
							<div className='content-item'>{`${workerGa} ${companyName}에 제출한 이력 및 학력 그리고 경력사항 등에 허위가 없음을 서약합니다.`}</div>
							<div className='content-item'>{`${worker}의 이력, 학력, 경력사항 등에 허위가 있을 경우 또는 허위 사실이 발견된 경우에는 ${workerUn} 스스로 퇴사하겠습니다.`}</div>
							<div className='content-item'>{`${workerGa} ${companyName}로부터 수령하는 보수에 대해서는 일체 누설하지 않을 것이며, 누설시 징계 등 ${companyName}의 조치에 따르겠습니다.`}</div>
							<div className='content-item'>{`${workerGa} ${companyName}의 영업비밀을 어떠한 경우에도 외부 또는 인가받지 않은 내부인에게 누설하지 않겠습니다.`}</div>
							<div className='content-item'>{`${workerGa} ${companyName}의 영업비밀 누설시 민형사상 책임을 지겠습니다.`}</div>
							<div className='content-item'>{`${workerGa} 고의 또는 과실로 ${companyName}에 유무형의 손해를 끼친 경우 그 일체를 배상하겠습니다.`}</div>
							<div className='content-item'>{`${workerGa} 근로계약에서 동의한 1주 12시간 한도의 연장근로와 휴일근무, 야간근무가 필요한 경우 이의 없이 근무하겠습니다.`}</div>
							<div className='content-item'>{`${workerUn} 재직하는 동안 본인이 생산하거나 가공한 업무와 관련한 모든 자료는 회사 소유임을 확인하며, 이를 업무용 PC에 기록 저장할 것이며, 본인이 저장한 것을 포함하여 업무용 PC에 저장된 일체의 자료는 회사의 허락없이 삭제하지 아니하겠습니다.`}</div>
							<div className='content-item'>{`${workerUn} 회사의 PC에 저장된 문서 내지 파일을 허락없이 삭제하여 발생하는 업무 지연 손해, 복구 비용 등의 민사 손해액을 배상하겠습니다.`}</div>
							<div className='content-item'>{`${workerUn} 회사의 PC에 저장된 문서 내지 파일을 허락없이 삭제하는 것은 고의로 회사업무를 방해하는 것임을 인지하며, 업무방해죄 등의 형사처벌을 이의없이 감수하겠습니다.`}</div>
						</div>

						<div className='jau__contents'>
							<div className='section-title'>정보 보안 준수 서약</div>
							<div className='content-item'>{`${workerUn} ${companyName}의 정보보안 규정을 준수하며, 보안사고가 발생하지 않도록 예방, 주의에 최선을 다하겠습니다.`}</div>
							<div className='content-item'>{`${workerGa} ${companyName}에서 근무하는 동안 취득한 ${companyName}의 정보, 개인정보, 지적 재산권들을 엄격히 보호할 것이며, 이를 본인이나 제3자를 위해 이용하거나 누설하지 않겠습니다.`}</div>
							<div className='content-item'>{`${workerGa} ${companyName}의 정보자산(문서서류, 전자파일, 저장매체, 전산망, 전산장비 등)을 무단변조, 훼손, 분실 등으로부터 안전하게 사용하고 관리할 것이며, 불법적인 목적으로 사용하지 않을 것입니다.`}</div>
							<div className='content-item'>{`${workerUn} 업무용 PC 등 디바이스에 승인받은 정품 소프트웨어만을 사용할 것이며, ${workerGa} 설치한 불법 소프트웨어 사용으로 인한 모든 민형사 책임을 지겠습니다.`}</div>
							<div className='content-item'>{`${worker}의 퇴직시에는 재직시 취득한 ${companyName} 소유의 모든 정보자산을 반드시 반납할 것이며, 이후 어떠한 경우에도 개인적으로 이용하지 않을 것입니다.`}</div>
							<div className='content-item'>{`${workerUn} 상기 사항을 숙지하고 이를 성실히 준수할 것을 동의하며, 본 서약서의 준수사항을 위반한 경우에는 징계 또는 해고됨과 동시에 관련 법령에 의한 일체의 민형사상 책임과 ${companyName}에 끼친 손해에 대해 지체없이 배상, 변상, 복구하겠습니다.`}</div>
						</div>

						<div className='jau__contents'>
							<div className='section-title'>비밀 유지 서약</div>
							<div className='content-item'>{`${workerUn} ${companyName}에 근무하면서 알게 되는 아래의 사항은 ${companyName}의 영업비밀에 해당하는 것을 주지하고 있습니다.`}</div>
							<div className='content-subitem'>
								{companyName}의 기술기밀에 관한 사항
							</div>
							<div className='content-subitem'>
								{companyName}의 수주방법, 견적방법, 사업성 검토방법 등
								영업기밀에 관한 사항
							</div>
							<div className='content-subitem'>
								{companyName}의 인사, 조직, 재무, 노무 등 관리기밀에 관한 사항
							</div>
							<div className='content-subitem'>
								{companyName}의 연구, 개발, 사업계획 및 교육, 훈련 등에 관한
								기밀사항
							</div>
							<div className='content-subitem'>
								{companyName}의 타사와의 제휴사업에 관한 기밀사항
							</div>
							<div className='content-subitem'>
								{companyName}의 고객명단, 고객관리, 프로모션, 판촉기법 등
								영업방법에 관한 사항
							</div>
							<div className='content-subitem'>
								{companyName}의 기타 영업비밀, 극비, 대외비로 분류되는 사항
							</div>
							<div className='content-item'>
								{workerGa} 업무수행 중 생산한 지적 자산에 관한 권리는{' '}
								{companyGa} 소유합니다.
							</div>
							<div className='content-item'>
								{workerUn} {companyName}의 서면 허가 없이는 어떠한 자료나
								정보라도 다음의 기간에는 외부로 유출, 발설, 공표하지 아니하며,
								만일 이를 위반하였을 경우 어떠한 처벌도 감수함은 물론{' '}
								{companyName}에 손해를 끼친 경우 해당 손해액을 지체 없이 배상,
								변상합니다.
							</div>
							<div className='content-subitem'>근로계약기간</div>
							<div className='content-subitem'>근로계약 종료 이후의 1년</div>
							<div className='content-item'>
								{workerUn} 불공정거래의 방지를 위하여 근로계약 종료 이후의 1년
								까지는 다음과 같은 동종업종의 재취업을 하지 아니하며, 만일 이를
								위반하였을 경우 어떠한 처벌도 감수함은 물론 {companyName}에
								손해를 끼친 경우 해당 손해액을 지체 없이 변상합니다.
							</div>
							<div className='content-subitem'>
								근로계약 종료 시점 前後1년 이내에 신설된 동종업종기업
							</div>
							<div className='content-subitem'>현재의 동종업종기업</div>
						</div>

						<div className='date'>{format(today, 'yyyy-MM-dd')}</div>
						<div className='signature'>{`위 복무서약, 정보보안 및 영업비밀 준수 서약자 : ${worker} (서명 또는 인)`}</div>
					</div>
				</Grid.Column>
			</Grid>
		</>
	);
}
