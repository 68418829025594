import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Button, Table } from 'semantic-ui-react';
import {
	calALFinSpecificBagicYear,
	calALFinSpecificPlusYear,
	calALFinSpecificRatioYear,
} from '../../../../annualLeave-libs/finALSpec-lib';
import {
	calALMonth,
	calALSpecificBagicYear,
	calALSpecificMonth,
	calALSpecificPlusYear,
	calALSpecificTotal,
} from '../../../../annualLeave-libs/lawALSpec-lib';
import {
	absentDaysYearCntForALFSpecificYear,
	sumLastYearPaidAnnualLeaveDaysForYear,
	sumPaidAnnualLeaveDaysForYear,
	usedAnnualLeavesInThisYearSumFSpecificYear,
} from '../../../../app/common/util/util';
import { apiService } from '../../../../annualLeave-libs/calculateAnnualLeaveCalALFinMonthApiService';

export default function OpenDetailAnnualLeavesListItem4InPastYear({
	numOfWorkers,
	Item,
	finBaseDate,
	annualLeaves,
	overFiveStartDay,
	finBaseMonthDay,
	anuualLeaveEnterDate,
	underOneYearWorkPeriod,
	enterDate,
	paydocus,
	workerId,
	secondYearForAnnualLeave,
	targetYear,
	usedAnnualLeavesInLastYearSumF,
}) {
	const [calALFinSpecificMonthV, setCalALFinSpecificMonthV] = useState(0);

	const workerEnteredTargetYearBeforeYes =
		moment(enterDate).format('yyyy') > +targetYear ? true : false;
	const specificTargetYear = String(targetYear);
	const specificTargetYearDate = String(targetYear) + '-12-31';

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await apiService.calculateAnnualLeaveCalALFinMonth({
					enterDate: enterDate,
					finBaseDate: finBaseDate,
					workersNumber: numOfWorkers,
					specificDate: specificTargetYearDate,
				});
				setCalALFinSpecificMonthV(data[0]); // API의 응답 구조에 따라 조정 필요
			} catch (error) {
				console.error('Error fetching data: ', error);
			}
		};

		fetchData();
	}, [enterDate, finBaseDate, numOfWorkers, specificTargetYearDate]);

	// const calALFinSpecificMonthV = calculateAnnualLeaveCalALFinMonth(enterDate, finBaseDate, numOfWorkers, specificTargetYearDate)[0]

	const [openLastThreeYearAL, setOpenLastThreeYearAL] = useState(false);

	const calALFinSpecificRatioYearV = calALFinSpecificRatioYear(
		anuualLeaveEnterDate,
		finBaseDate,
		numOfWorkers,
		specificTargetYearDate
	);
	const calALFinSpecificBagicYearV = calALFinSpecificBagicYear(
		anuualLeaveEnterDate,
		finBaseDate,
		numOfWorkers,
		specificTargetYearDate
	);
	const calALFinSpecificPlusYearV = calALFinSpecificPlusYear(
		anuualLeaveEnterDate,
		finBaseDate,
		numOfWorkers,
		specificTargetYearDate
	);
	const sumCalALFinSpecific =
		(calALFinSpecificMonthV ? calALFinSpecificMonthV : 0) +
		(calALFinSpecificRatioYearV ? calALFinSpecificRatioYearV : 0) +
		(calALFinSpecificBagicYearV ? calALFinSpecificBagicYearV : 0) +
		(calALFinSpecificPlusYearV ? calALFinSpecificPlusYearV : 0);

	const calALSpecificMonthThisYearV = calALMonth(
		enterDate,
		numOfWorkers,
		specificTargetYearDate
	)[0];
	const calALSpecificMonthV = Math.max(
		calALSpecificMonth(enterDate, numOfWorkers, specificTargetYearDate),
		0
	);
	const calALSpecificBagicYearV = calALSpecificBagicYear(
		anuualLeaveEnterDate,
		numOfWorkers,
		specificTargetYearDate
	);
	const calALSpecificPlusYearV = calALSpecificPlusYear(
		anuualLeaveEnterDate,
		numOfWorkers,
		specificTargetYearDate
	);
	const sumCalALSpecific = secondYearForAnnualLeave(enterDate, targetYear)
		? (calALSpecificMonthThisYearV ? calALSpecificMonthThisYearV : 0) +
		  (calALSpecificBagicYearV ? calALSpecificBagicYearV : 0) +
		  (calALSpecificPlusYearV ? calALSpecificPlusYearV : 0)
		: (calALSpecificMonthV ? calALSpecificMonthV : 0) +
		  (calALSpecificBagicYearV ? calALSpecificBagicYearV : 0) +
		  (calALSpecificPlusYearV ? calALSpecificPlusYearV : 0);

	const monthALCondition =
		(underOneYearWorkPeriod &&
			absentDaysYearCntForALFSpecificYear(
				enterDate,
				paydocus,
				workerId,
				specificTargetYear
			)[2]) ||
		secondYearForAnnualLeave(enterDate, targetYear); //1년미만 결근일수가 있거나 2년차 이상일 경우

	const paidALUnusedDays =
		sumPaidAnnualLeaveDaysForYear(paydocus, specificTargetYear) +
		sumLastYearPaidAnnualLeaveDaysForYear(
			paydocus,
			specificTargetYear
		); /* 지급된 연차수당 사용하지 않은 일수 */

	return (
		<>
			{!workerEnteredTargetYearBeforeYes && (
				<Button onClick={() => setOpenLastThreeYearAL(!openLastThreeYearAL)}>
					{!openLastThreeYearAL ? (
						<span style={{ color: 'blue' }}>{targetYear}년 연차현황 보기</span>
					) : (
						<span style={{ color: 'teal' }}>{targetYear}년 연차현황 닫기</span>
					)}
				</Button>
			)}
			{!workerEnteredTargetYearBeforeYes && openLastThreeYearAL && (
				<>
					<hr />
					{finBaseDate &&
					(annualLeaves === '회계년도기준' ||
						annualLeaves === '특정일(회계년도 등)기준') ? (
						<>
							<Item.Description>
								{overFiveStartDay ? (
									<div>
										<strong>
											{specificTargetYear}년12월31일 현재 연차현황[회계기준(
											{finBaseMonthDay})] [5인이상 적용일 :{' '}
											{anuualLeaveEnterDate}]
										</strong>
										<span style={{ color: 'red' }}>* (참고용)</span>
									</div>
								) : (
									<div>
										<strong>
											{specificTargetYear}년12월31일 현재 연차현황[회계기준(
											{finBaseMonthDay})]
										</strong>
										<span style={{ color: 'red' }}>* (참고용)</span>
									</div>
								)}
							</Item.Description>
							<Item.Description>
								<Table style={{ textAlign: 'center' }}>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>구분</Table.HeaderCell>
											<Table.HeaderCell>월 단위</Table.HeaderCell>
											<Table.HeaderCell>비례</Table.HeaderCell>
											<Table.HeaderCell>년기본</Table.HeaderCell>
											<Table.HeaderCell>년가산</Table.HeaderCell>
											<Table.HeaderCell>합계</Table.HeaderCell>
											{monthALCondition && (
												<Table.HeaderCell>
													월 단위
													<br />
													결근공제
												</Table.HeaderCell>
											)}
											<Table.HeaderCell>
												{targetYear}년 <br /> 사용연차
											</Table.HeaderCell>
											<Table.HeaderCell>
												{targetYear}년 <br /> 수당지급연차
											</Table.HeaderCell>
											<Table.HeaderCell>
												{targetYear}년 현재 <br />
												잔여연차
											</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										<Table.Row>
											<Table.Cell>
												<strong>회계기준</strong>
											</Table.Cell>
											<Table.Cell>{calALFinSpecificMonthV}</Table.Cell>{' '}
											{/* 월단위 */}
											<Table.Cell>{calALFinSpecificRatioYearV}</Table.Cell>{' '}
											{/* 비례 */}
											<Table.Cell>{calALFinSpecificBagicYearV}</Table.Cell>{' '}
											{/* 년기본 */}
											<Table.Cell>{calALFinSpecificPlusYearV}</Table.Cell>{' '}
											{/* 년가산 */}
											<Table.Cell>{sumCalALFinSpecific}</Table.Cell>{' '}
											{/* 합계 */}
											{monthALCondition && (
												<Table.Cell>
													{' '}
													{/* 월단위 결근공제 */}
													{absentDaysYearCntForALFSpecificYear(
														enterDate,
														paydocus,
														workerId,
														specificTargetYear
													)[2] * -1}
												</Table.Cell>
											)}
											<Table.Cell>
												{' '}
												{/* 사용연차 */}
												{usedAnnualLeavesInThisYearSumFSpecificYear(
													paydocus,
													workerId,
													specificTargetYear
												) * -1}
											</Table.Cell>
											<Table.Cell>
												{' '}
												{/* 수당지급연차 */}
												{paidALUnusedDays * -1}
											</Table.Cell>
											{monthALCondition ? (
												<Table.Cell>
													{' '}
													{/* 현재 잔여연차 */}
													{sumCalALFinSpecific -
														usedAnnualLeavesInThisYearSumFSpecificYear(
															paydocus,
															workerId,
															specificTargetYear
														) -
														absentDaysYearCntForALFSpecificYear(
															enterDate,
															paydocus,
															workerId,
															specificTargetYear
														)[2] -
														paidALUnusedDays}
												</Table.Cell>
											) : (
												<Table.Cell>
													{sumCalALFinSpecific -
														usedAnnualLeavesInThisYearSumFSpecificYear(
															paydocus,
															workerId,
															specificTargetYear
														) -
														paidALUnusedDays}
												</Table.Cell>
											)}
										</Table.Row>
										<Table.Row>
											<Table.Cell>
												<strong>법정기준</strong>
											</Table.Cell>
											{secondYearForAnnualLeave(enterDate, targetYear) ? (
												<Table.Cell>{calALSpecificMonthThisYearV}</Table.Cell>
											) : (
												<Table.Cell>{calALSpecificMonthV}</Table.Cell>
											)}
											<Table.Cell>'해당없음'</Table.Cell>
											<Table.Cell>{calALSpecificBagicYearV}</Table.Cell>
											<Table.Cell>{calALSpecificPlusYearV}</Table.Cell>
											<Table.Cell>{sumCalALSpecific}</Table.Cell>
											{monthALCondition && (
												<Table.Cell>
													{absentDaysYearCntForALFSpecificYear(
														enterDate,
														paydocus,
														workerId,
														specificTargetYear
													)[2] * -1}
												</Table.Cell>
											)}
											<Table.Cell>
												{usedAnnualLeavesInThisYearSumFSpecificYear(
													paydocus,
													workerId,
													specificTargetYear
												) * -1}
											</Table.Cell>
											<Table.Cell>{paidALUnusedDays * -1}</Table.Cell>
											{monthALCondition ? (
												<Table.Cell>
													{sumCalALSpecific -
														usedAnnualLeavesInThisYearSumFSpecificYear(
															paydocus,
															workerId,
															specificTargetYear
														) -
														absentDaysYearCntForALFSpecificYear(
															enterDate,
															paydocus,
															workerId,
															specificTargetYear
														)[2] -
														paidALUnusedDays}
												</Table.Cell>
											) : (
												<Table.Cell>
													{sumCalALSpecific -
														usedAnnualLeavesInThisYearSumFSpecificYear(
															paydocus,
															workerId,
															specificTargetYear
														) -
														paidALUnusedDays}
												</Table.Cell>
											)}
										</Table.Row>
									</Table.Body>
								</Table>
							</Item.Description>
						</>
					) : (
						<>
							<Item.Description>
								{overFiveStartDay ? (
									<div>
										<strong>
											{specificTargetYear}년12월31일 현재
											연차현황[법정(입사일)기준] [5인이상 적용일 :{' '}
											{anuualLeaveEnterDate}]
										</strong>
										<span style={{ color: 'red' }}>* (참고용)</span>
									</div>
								) : (
									<div>
										<strong>
											{specificTargetYear}년12월31일 현재
											연차현황[법정(입사일)기준]
										</strong>
										<span style={{ color: 'red' }}>* (참고용)</span>
									</div>
								)}
							</Item.Description>
							<Item.Description>
								<Table style={{ textAlign: 'center' }}>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>구분</Table.HeaderCell>
											<Table.HeaderCell>월 단위</Table.HeaderCell>
											<Table.HeaderCell>년기본</Table.HeaderCell>
											<Table.HeaderCell>년가산</Table.HeaderCell>
											{underOneYearWorkPeriod &&
												absentDaysYearCntForALFSpecificYear(
													enterDate,
													paydocus,
													workerId,
													specificTargetYear
												)[2] && (
													<Table.HeaderCell>
														월 단위
														<br />
														결근공제
													</Table.HeaderCell>
												)}
											<Table.HeaderCell>합계</Table.HeaderCell>
											<Table.HeaderCell>
												{targetYear}년 <br /> 사용연차
											</Table.HeaderCell>
											<Table.HeaderCell>*잔여연차</Table.HeaderCell>
											<Table.HeaderCell>
												{targetYear}년 <br /> 사용연차 <br />
												(입사기준 <br /> 참고용)
											</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										<Table.Row>
											<Table.Cell>법정기준</Table.Cell>
											{secondYearForAnnualLeave(enterDate, targetYear) ? (
												<Table.Cell>{calALSpecificMonthThisYearV}</Table.Cell>
											) : (
												<Table.Cell>{calALSpecificMonthV}</Table.Cell>
											)}
											<Table.Cell>{calALSpecificBagicYearV}</Table.Cell>
											<Table.Cell>{calALSpecificPlusYearV}</Table.Cell>
											{underOneYearWorkPeriod &&
												absentDaysYearCntForALFSpecificYear(
													enterDate,
													paydocus,
													workerId,
													specificTargetYear
												)[2] && (
													<Table.Cell>
														{absentDaysYearCntForALFSpecificYear(
															enterDate,
															paydocus,
															workerId,
															specificTargetYear
														)[2] * -1}
													</Table.Cell>
												)}
											{underOneYearWorkPeriod &&
											absentDaysYearCntForALFSpecificYear(
												enterDate,
												paydocus,
												workerId,
												specificTargetYear
											)[2] ? (
												<Table.Cell>
													{calALSpecificTotal(
														anuualLeaveEnterDate,
														numOfWorkers,
														specificTargetYearDate
													) -
														absentDaysYearCntForALFSpecificYear(
															enterDate,
															paydocus,
															workerId,
															specificTargetYear
														)[2]}
												</Table.Cell>
											) : (
												<Table.Cell>
													{calALSpecificTotal(
														anuualLeaveEnterDate,
														numOfWorkers,
														specificTargetYearDate
													)}
												</Table.Cell>
											)}
											<Table.Cell>
												{usedAnnualLeavesInThisYearSumFSpecificYear(
													paydocus,
													workerId,
													specificTargetYear
												)}
											</Table.Cell>
											{underOneYearWorkPeriod &&
											absentDaysYearCntForALFSpecificYear(
												enterDate,
												paydocus,
												workerId,
												specificTargetYear
											)[2] ? (
												<Table.Cell>
													{calALSpecificTotal(
														anuualLeaveEnterDate,
														numOfWorkers,
														specificTargetYearDate
													) -
														usedAnnualLeavesInThisYearSumFSpecificYear(
															paydocus,
															workerId,
															specificTargetYear
														) -
														absentDaysYearCntForALFSpecificYear(
															enterDate,
															paydocus,
															workerId,
															specificTargetYear
														)[2]}
												</Table.Cell>
											) : (
												<Table.Cell>
													{calALSpecificTotal(
														anuualLeaveEnterDate,
														numOfWorkers,
														specificTargetYearDate
													) -
														usedAnnualLeavesInThisYearSumFSpecificYear(
															paydocus,
															workerId,
															specificTargetYear
														)}
												</Table.Cell>
											)}
											<Table.Cell>
												{usedAnnualLeavesInLastYearSumF(paydocus, workerId)}
											</Table.Cell>
										</Table.Row>
									</Table.Body>
								</Table>
							</Item.Description>
						</>
					)}
				</>
			)}
		</>
	);
}
