import React from 'react';
import {
	josaGaType,
	josaUnType,
	josaWaType,
} from '../../../../../app/common/util/util';
import WeeCenterSC from './specialComment/WeeCenterSC';

export default function ClientReqDLCSecretEtc({
	worker,
	companyName,
	contractWorker,
	contractOldWorker,
	numOfWorkers,
	selectedClientReq4In,
	clientInfo,
	currentUserProfile,
}) {
	const companyUn = josaUnType(companyName);
	const companyGa = josaGaType(companyName);
	const companyWa = josaWaType(companyName);
	const workerUn = josaUnType(worker);
	const workerGa = josaGaType(worker);

	const workerEnterDate = selectedClientReq4In?.workerEnteredDate;
	const wageContractStartDate = selectedClientReq4In?.wageContractStartDate;
	const companyId = clientInfo ? clientInfo.id : currentUserProfile?.id;
	const comparisonDate = new Date('2024-12-13').getTime();

	// 날짜 비교 (타임스탬프 기준)
	const isWorkerEnterBeforeDate =
		new Date(workerEnterDate).getTime() < comparisonDate;

	return (
		<>
			<div className='jau__contents'>
				<span style={{ fontSize: '1rem' }}>
					<strong>제11조 비밀유지 및 손해배상 등</strong>
				</span>
				<ul>
					<li>
						① {workerUn} 영업비밀 및 부당경쟁 방지법상의 비밀유지의무를 지고
						영업비밀을 누출할 경우 민사상, 형사상 책임을 진다.
					</li>
					<li>
						② {companyUn} 노동관계 법령의 준수 의무를 위반한 경우, {workerUn}{' '}
						채무불이행 책임 또는 불법행위 책임으로 {companyName}에 손해를 입힌
						경우 상대방에 대해 민형사상 책임을 진다.
					</li>
					<li>
						③ {workerUn} {companyGa} 지급한 장비 및 소모품, 유니폼 등 일체의
						비품에 대하여 선량한 관리자의 주의의무를 부담하며, 어떠한 경우라도
						분실, 훼손, 미반납시에는 해당 물품의 현가(당사자가 합의한 금액 또는
						소송으로 확정된 금액)를 배상해야 한다.
					</li>
					<li>
						④ 취업규칙 등 회사 내규(단체협약을 포함한다.) 및 노동관계법령에 본
						계약서에 명기되지 아니한 내용(조항) 또는 {worker}에게 유리한
						내용(조항)이 있는 경우에는 그에 따른다.
					</li>
					<li>
						⑤ {companyWa} {worker}간 소송은 {companyName}의 소재지 관할법원으로
						한다.
					</li>
				</ul>
			</div>
			{companyId === '0rnUsXoNP6TRjnWHOW3sim39qLB2' &&
				isWorkerEnterBeforeDate && (
					<div className='section'>
						<WeeCenterSC
							companyName={companyName}
							worker={worker}
							workerEnterDate={workerEnterDate}
							wageContractStartDate={wageContractStartDate}
							companyId={companyId}
						/>
					</div>
				)}

			{numOfWorkers >= 5 ? (
				<>
					{contractWorker ? (
						contractOldWorker ? (
							<div className='jau__contents'>
								<span style={{ fontSize: '1rem' }}>
									<strong>제12조 촉탁 계약직 특례 및 계약기간 인지 확인</strong>
								</span>
								<ul>
									<>
										<li>
											① 계약직 연차계산 특례 : {workerUn} 정년퇴직 이후 재입사한
											촉탁 계약직 직원으로 정년이전 계속근속기간은 단절되지만,
											최초 촉탁직 계약이{' '}
											<span style={{ color: 'blue' }}>
												근로제공 단절없이 수회 재계약되어 계속근로가 1년을 넘는
												경우{' '}
											</span>
											대법원93다 26168 판결을 참조하여 연차계산을 위한 계속 근로
											연수는 최초 촉탁직 계약 입사일부터 기산한다.
										</li>
										<li>
											② 계약직 퇴직금 정산 특례 : 연봉계약서의 제5조 퇴직금
											조항에도 불구하고 {workerUn} 정년퇴직 이후 재입사한 촉탁
											계약직으로 정년이전 계속근속기간은 단절되므로 촉탁 계약직
											계속근로기간이 1년미만인 경우는 퇴직금이 지급되지 아니함.
											단, 최초 촉탁직 계약이{' '}
											<span style={{ color: 'blue' }}>
												근로제공 단절없이 수회 재계약되어 계속근로가 1년을 넘는
												경우{' '}
											</span>
											대법원93다 26168 판결을 참조하여 퇴직금 계산을 위한 계속
											근로 연수는 최초 촉탁직 계약 입사일부터 기산하고 마지막
											촉탁 계약직 계약기간 마지막 근무일을 산정사유발생일로
											간주하여 퇴직금을 정산한다.
										</li>
										<li>
											③ 계약직 계약기간 인지 동의 : 위 조항은 {workerGa} 촉탁
											계약직 계약 이후 근로제공 단절없는 연속 계약갱신되어
											1년이상 재직하는 경우에만 적용됨을 확인하며, 그 경우
											제1항의 연차계산 특례와 제2항 퇴직금 정산 특례에 따라 두
											제도의 계속 근로 연수를 연속근로로 가정한다하더라도 이는{' '}
											{worker}의 각각의 계약직 근로계약기간 및 근로관계에 영향을
											미지지 못하므로 {workerUn} 이를 이유로 계속근로 기대
											갱신권을 주장할 수도 없고 주장하지도 않는 것에 동의한다.
										</li>
										<b>
											<li style={{ color: 'blue' }}>
												위 ③항 동의자 : {worker} (인 또는 sign)
											</li>
										</b>
									</>
								</ul>
							</div>
						) : (
							<div className='jau__contents'>
								<span style={{ fontSize: '1rem' }}>
									<strong>제12조 계약직 특례 및 계약기간 인지 확인</strong>
								</span>
								<ul>
									<>
										<li>
											① 계약직 연차계산 특례 : {workerUn} 계약직이지만 계약이{' '}
											<span style={{ color: 'blue' }}>
												근로제공 단절없이 수회 재계약되어 계속근로가 1년을 넘는
												경우{' '}
											</span>
											대법원93다 26168 판결을 참조하여 연차계산을 위한 계속 근로
											연수는 최초 계약직 입사일부터 기산한다.
										</li>
										<li>
											② 계약직 퇴직금 정산 특례 : 연봉계약서의 제5조 퇴직금
											조항에도 불구하고 {workerUn} 계약직이지만 계약이{' '}
											<span style={{ color: 'blue' }}>
												근로제공 단절없이 수회 재계약되어 계속근로가 1년을 넘는
												경우{' '}
											</span>
											대법원93다 26168 판결을 참조하여 퇴직금 계산을 위한 계속
											근로 연수는 최초 계약직 입사일부터 기산하고 마지막 계약직
											계약기간 마지막 근무일을 산정사유발생일로 간주하여
											퇴직금을 정산한다.
										</li>
										<li>
											③ 계약직 계약기간 인지 동의 : 위 제1항과 제2항은{' '}
											{workerGa} 근로제공 단절없는 연속 계약갱신되어 1년이상
											재직하는 경우에만 적용됨을 확인하며, 그 경우 제6항의
											연차계산 특례와 제7항 퇴직금 정산 특례에 따라 두 제도의
											계속 근로 연수를 연속근로로 가정한다하더라도 이는 {worker}
											의 각각의 계약직 근로계약기간 및 근로관계에 영향을 미지지
											못하므로 {workerUn} 이를 이유로 계속근로 기대 갱신권을
											주장할 수도 없고 주장하지도 않는 것에 동의한다.
										</li>
										<b>
											<li style={{ color: 'blue' }}>
												위 ③항 동의자 : {worker} (인 또는 sign)
											</li>
										</b>
									</>
								</ul>
							</div>
						)
					) : null}
				</>
			) : (
				<>
					{contractWorker ? (
						contractOldWorker ? (
							<div className='jau__contents'>
								<span style={{ fontSize: '1rem' }}>
									<strong>제12조 촉탁 계약직 특례 및 계약기간 인지 확인</strong>
								</span>
								<ul>
									<>
										<li>
											① 계약직 퇴직금 정산 특례 : 연봉계약서의 제5조 퇴직금
											조항에도 불구하고 {workerUn} 정년퇴직 이후 재입사한 촉탁
											계약직으로 정년이전 계속근속기간은 단절되므로 촉탁 계약직
											계속근로기간이 1년미만인 경우는 퇴직금이 지급되지 아니함.
											단, 최초 촉탁 계약이{' '}
											<span style={{ color: 'blue' }}>
												근로제공 단절없이 수회 재계약되어 계속근로가 1년을 넘는
												경우{' '}
											</span>
											대법원93다 26168 판결을 참조하여 퇴직금 계산을 위한 계속
											근로 연수는 최초 촉탁직 계약 입사일부터 기산하고 마지막
											촉탁 계약직 계약기간 마지막 근무일을 산정사유발생일로
											간주하여 퇴직금을 정산한다.
										</li>
										<li>
											② 계약직 계약기간 인지 동의 : 위 제1항은 {workerGa} 촉탁
											계약직 계약 이후 근로제공 단절없는 연속 계약갱신되어
											1년이상 재직하는 경우에만 적용됨을 확인하며, 그 경우 제1항
											퇴직금 정산 특례에 따라 계속 근로 연수를 연속근로로
											가정한다하더라도 이는 {worker}의 각각의 계약직
											근로계약기간 및 근로관계에 영향을 미지지 못하므로{' '}
											{workerUn} 이를 이유로 계속근로 기대 갱신권을 주장할 수도
											없고 주장하지도 않는 것에 동의한다.
										</li>
										<b>
											<li style={{ color: 'blue' }}>
												위 ②항 동의자 : {worker} (인 또는 sign)
											</li>
										</b>
									</>
								</ul>
							</div>
						) : (
							<div className='jau__contents'>
								<span style={{ fontSize: '1rem' }}>
									<strong>제12조 계약직 특례 및 계약기간 인지 확인</strong>
								</span>
								<ul>
									<>
										<li>
											① 계약직 퇴직금 정산 특례 : 연봉계약서의 제5조 퇴직금
											조항에도 불구하고 {workerUn} 계약직이지만 계약이{' '}
											<span style={{ color: 'blue' }}>
												근로제공 단절없이 수회 재계약되어 계속근로가 1년을 넘는
												경우{' '}
											</span>
											대법원93다 26168 판결을 참조하여 퇴직금 계산을 위한 계속
											근로 연수는 최초 촉탁직 계약 입사일부터 기산하고 마지막
											계약직 계약기간 마지막 근무일을 산정사유발생일로 간주하여
											퇴직금을 정산한다.
										</li>
										<li>
											② 계약직 계약기간 인지 동의 : 위 제1항은 {workerGa}{' '}
											근로제공 단절없는 연속 계약갱신되어 1년이상 재직하는
											경우에만 적용됨을 확인하며, 그 경우 제1항 퇴직금 정산
											특례에 따라 계속 근로 연수를 연속근로로 가정한다하더라도
											이는 {worker}의 각각의 계약직 근로계약기간 및 근로관계에
											영향을 미지지 못하므로 {workerUn} 이를 이유로 계속근로
											기대 갱신권을 주장할 수도 없고 주장하지도 않는 것에
											동의한다.
										</li>
										<b>
											<li style={{ color: 'blue' }}>
												위 ②항 동의자 : {worker} (인 또는 sign)
											</li>
										</b>
									</>
								</ul>
							</div>
						)
					) : null}
				</>
			)}
		</>
	);
}
