import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
	numberToCommaString,
	safeStringCompare,
} from '../../../app/common/util/util';
import { Table } from 'semantic-ui-react';
import SortableTable from '../../../app/common/form/SortableTable';

export default function AnnaulLeavesTableWorkerList({
	paydocus,
	workerViewData,
	searchResults,
	setSearchResults,
	searchTerm,
	titleOfTable,
}) {
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { clientInfo } = useSelector((state) => state.companyReducer);

	const id = clientInfo?.id
		? clientInfo?.id
		: clientInfo?.clientUid
		? clientInfo?.clientUid
		: currentUserProfile?.id;

	useEffect(() => {
		const searchedPaydocus = paydocus.filter((paydocu) => {
			return paydocu?.workerInfo?.worker?.includes(searchTerm);
		});
		setSearchResults(searchedPaydocus);
	}, [searchTerm, setSearchResults, paydocus]);

	const calculations = {
		sumOfUsedALsCom: () =>
			searchResults
				.map((result) => result?.usedAnnualLeaves)
				.reduce((prev, curr) => (prev ? +prev : 0) + (curr ? +curr : 0), 0),
		sumOfPaidALsCom: () =>
			searchResults
				.map((result) => result?.addAverWage?.plusUnusedAnnualLeavesDays)
				.reduce((prev, curr) => (prev ? +prev : 0) + (curr ? +curr : 0), 0),
		sumOfPaidALsLastYearCom: () =>
			searchResults
				.map(
					(result) => result?.addAverWage?.plusLastYearUnusedAnnualLeavesDays
				)
				.reduce((prev, curr) => (prev ? +prev : 0) + (curr ? +curr : 0), 0),
		sumOfAbsentDaysCom: () =>
			searchResults
				.map((result) => result?.absentMinusWage?.absentDays)
				.reduce((prev, curr) => (prev ? +prev : 0) + (curr ? +curr : 0), 0),
		sumOfAbsentTimesCom: () =>
			searchResults
				.map((result) => result?.absentMinusWage?.absentTimes)
				.reduce((prev, curr) => (prev ? +prev : 0) + (curr ? +curr : 0), 0),
		sumOfUsedALsWorker: () =>
			workerViewData
				.map((data) => data?.usedAnnualLeaves)
				.reduce((prev, curr) => (prev ? +prev : 0) + (curr ? +curr : 0), 0),
	};

	const tableHeadersWorker = [
		{ key: 'yearMonth', label: '년월' },
		{ key: 'worker', label: '직원명' },
		{ key: 'usedAnnualLeaves', label: '사용연차(일)' },
		{ key: 'usedAnnualLeaveDates', label: '회사등록일' },
	];

	const sortFunctionsWorker = {
		yearMonth: (a, b) => safeStringCompare(a.yearMonth, b.yearMonth),
		worker: (a, b) => safeStringCompare(a.worker, b.worker),
		usedAnnualLeaves: (a, b) =>
			safeStringCompare(a.usedAnnualLeaves, b.usedAnnualLeaves),
		usedAnnualLeaveDates: (a, b) =>
			safeStringCompare(a.usedAnnualLeaveDates, b.usedAnnualLeaveDates),
	};

	const formattedDataWorker = searchResults.map((result) => ({
		yearMonth: result?.info?.yearMonth,
		worker: result?.workerInfo?.worker,
		usedAnnualLeaves: result?.usedAnnualLeaves,
		usedAnnualLeaveDates: result?.usedAnnualLeaveDates,
	}));

	const footerWorker = (
		<Table.Row>
			<Table.Cell colSpan='2'>합계</Table.Cell>
			<Table.Cell></Table.Cell>
			<Table.Cell>{calculations.sumOfUsedALsWorker()}일</Table.Cell>
			<Table.Cell></Table.Cell>
		</Table.Row>
	);

	const tableHeadersCompany = [
		{ key: 'companyName', label: '회사명' },
		{ key: 'yearMonth', label: '년월' },
		{ key: 'year', label: '년' },
		{ key: 'month', label: '월' },
		{ key: 'workerDivisions', label: '부서명' },
		{ key: 'workerTitles', label: '직위/직책' },
		{ key: 'worker', label: '직원명' },
		{ key: 'workerEnteredDate', label: '입사일' },
		{ key: 'workerRetiredDate', label: '마지막근무일' },
		{ key: 'usedAnnualLeaves', label: '사용연차수' },
		{ key: 'usedAnnualLeaveDates', label: '사용연차내역' },
		{ key: 'plusUnusedAnnualLeavesDays', label: '지급한 연차수당일수' },
		{
			key: 'plusLastYearUnusedAnnualLeavesDays',
			label: '지급한 전년도 연차수당일수',
		},
		{ key: 'absentDays', label: '결근수' },
		{ key: 'absentDates', label: '결근내역' },
		{ key: 'absentTimes', label: '지각/조퇴/외출수' },
		{ key: 'absentTimeDates', label: '지각/조퇴/외출내역' },
	];

	const sortFunctionsCompany = {
		companyName: (a, b) => safeStringCompare(a.companyName, b.companyName),
		yearMonth: (a, b) => safeStringCompare(a.yearMonth, b.yearMonth),
		year: (a, b) => safeStringCompare(a.year, b.year),
		month: (a, b) => safeStringCompare(a.month, b.month),
		workerDivisions: (a, b) =>
			safeStringCompare(a.workerDivisions, b.workerDivisions),
		workerTitles: (a, b) => safeStringCompare(a.workerTitles, b.workerTitles),
		worker: (a, b) => safeStringCompare(a.worker, b.worker),
		workerEnteredDate: (a, b) =>
			safeStringCompare(a.workerEnteredDate, b.workerEnteredDate),
		workerRetiredDate: (a, b) =>
			safeStringCompare(a.workerRetiredDate, b.workerRetiredDate),
		usedAnnualLeaves: (a, b) =>
			safeStringCompare(a.usedAnnualLeaves, b.usedAnnualLeaves),
		usedAnnualLeaveDates: (a, b) =>
			safeStringCompare(a.usedAnnualLeaveDates, b.usedAnnualLeaveDates),
		plusUnusedAnnualLeavesDays: (a, b) =>
			safeStringCompare(
				a.plusUnusedAnnualLeavesDays,
				b.plusUnusedAnnualLeavesDays
			),
		plusLastYearUnusedAnnualLeavesDays: (a, b) =>
			safeStringCompare(
				a.plusLastYearUnusedAnnualLeavesDays,
				b.plusLastYearUnusedAnnualLeavesDays
			),
		absentDays: (a, b) => safeStringCompare(a.absentDays, b.absentDays),
		absentDates: (a, b) => safeStringCompare(a.absentDates, b.absentDates),
		absentTimes: (a, b) => safeStringCompare(a.absentTimes, b.absentTimes),
		absentTimeDates: (a, b) =>
			safeStringCompare(a.absentTimeDates, b.absentTimeDates),
	};

	const filteredDataCompany = searchResults
		.filter(
			(result) =>
				result.companyInfo.companyId === id ||
				currentUserProfile?.authLevel >= 100 ||
				result?.companyInfo?.agentUid === id
		)
		.filter(
			(result) =>
				result?.usedAnnualLeaves ||
				result?.absentMinusWage?.absentDays ||
				result?.absentMinusWage?.absentTimes ||
				result?.addAverWage?.plusUnusedAnnualLeavesDays ||
				result?.addAverWage?.plusLastYearUnusedAnnualLeavesDays
		)
		.map((result) => ({
			companyName: result?.companyInfo?.companyName,
			yearMonth: result?.info?.yearMonth,
			year: result?.info?.year,
			month: result?.info?.month,
			workerDivisions: result?.workerInfo?.workerDivisions,
			workerTitles: result?.workerInfo?.workerTitles,
			worker: result?.workerInfo?.worker,
			workerEnteredDate: result?.workerInfo?.workerEnteredDate,
			workerRetiredDate: result?.workerInfo?.workerRetiredDate,
			usedAnnualLeaves: result?.usedAnnualLeaves,
			usedAnnualLeaveDates: result?.usedAnnualLeaveDates,
			plusUnusedAnnualLeavesDays:
				result?.addAverWage?.plusUnusedAnnualLeavesDays,
			plusLastYearUnusedAnnualLeavesDays:
				result?.addAverWage?.plusLastYearUnusedAnnualLeavesDays,
			absentDays: result?.absentMinusWage?.absentDays,
			absentDates: result?.absentMinusWage?.absentDates,
			absentTimes: result?.absentMinusWage?.absentTimes,
			absentTimeDates: result?.absentMinusWage?.absentTimeDates,
		}));

	const footerCompany = (
		<Table.Row>
			<Table.Cell colSpan='9'>합계</Table.Cell>
			{calculations.sumOfUsedALsCom && (
				<Table.Cell colSpan='2'>
					연차 총 {numberToCommaString(calculations.sumOfUsedALsCom())}개 사용
				</Table.Cell>
			)}
			<Table.Cell>총 {calculations.sumOfPaidALsCom()}일</Table.Cell>
			<Table.Cell>총 {calculations.sumOfPaidALsLastYearCom()}일</Table.Cell>
			<Table.Cell colSpan='2'>
				총 {calculations.sumOfAbsentDaysCom()}일 결근
			</Table.Cell>
			<Table.Cell colSpan='2'>
				공제 총 {calculations.sumOfAbsentTimesCom()}시간
			</Table.Cell>
		</Table.Row>
	);

	return (
		<>
			{paydocus.length !== 0 && (
				<>
					{!currentUserProfile.ceoName ? (
						<SortableTable
							headers={tableHeadersWorker}
							data={formattedDataWorker}
							sortFunctions={sortFunctionsWorker}
							footer={footerWorker}
							title={titleOfTable}
						/>
					) : (
						<SortableTable
							headers={tableHeadersCompany}
							data={filteredDataCompany}
							sortFunctions={sortFunctionsCompany}
							footer={footerCompany}
							title={titleOfTable}
						/>
					)}
				</>
			)}
		</>
	);
}
