import React from 'react';
import {
	josaGaType,
	josaUnType,
	josaWaType,
} from '../../../../../../app/common/util/util';

export default function WeeCenterSC({ worker, companyName }) {
	const companyGa = josaGaType(companyName);
	const companyWa = josaWaType(companyName);
	const workerUn = josaUnType(worker);

	return (
		<>
			{
				<div className='jau__contents'>
					<ul>
						{
							<>
								<span style={{ fontSize: '1rem' }}>
									<strong>제11조의 1 기타 특약사항 등</strong>
								</span>
								<li>
									{`① ${companyWa} ${workerUn} 본 근로계약 및 임금계약 이전에 ${companyGa} 임금의 구성항목 및 계산방법 지급방법 등이 표시되지 않았던 잘못된 근로계약서 및 연봉 근로계약서를 작성했지만, 
              이는 ${companyGa} 노동법을 잘못 해석해서 작성된 것이였음을 서로 확인하며, 본 근로계약 및 임금계약의 소정근로시간, 휴일, 휴가, 임금의 구성항목, 계산방법, 지급방법 등의 
              근로조건은 입사시부터 ${worker} 본인에게 적용되던 근로조건임을 확인합니다.`}
								</li>
								<b>
									<li style={{ color: 'blue' }}>
										{`위 ①항 확인자 : ${worker} (인 또는 sign)`}
									</li>
								</b>
							</>
						}
					</ul>
				</div>
			}
		</>
	);
}
