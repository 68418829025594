import React from 'react';
import { josaUnType } from '../../../../../../app/common/util/util';

// import { josaGaType, josaUnType, josaWaType } from '../../../../../../app/common/util/util';

export default function SonghakService({
	worker,
	companyName,
	workerEnterDate,
	wageContractStartDate,
}) {
	const companyUn = josaUnType(companyName);
	// const companyGa = josaGaType(companyName);
	// const companyWa = josaWaType(companyName);
	const workerUn = josaUnType(worker);

	return (
		<>
			{(companyName === '주식회사 송악서비스' ||
				companyName === '노무법인 인업 강상훈' ||
				companyName === '노무법인 인업 김은정' ||
				companyName === '노무법인 인업 김경모' ||
				companyName === '노무업인 인업 이덕환') && (
				<div className='jau__contents'>
					<span style={{ fontSize: '1rem' }}>
						<strong>제11조 기타 특약사항 등</strong>
					</span>
					<ul>
						<li>
							{`① ${companyUn} 취업규칙 제7조의 3 제1항에 의거 2주단위 탄력적 근로시간제를 운영하므로 본 근로계약서 제5조 제1항의 토요일 근무는 격주로 시행하되, 근무하는 
          토요일의 종업시간은 13:00으로 하고, 추가 휴게시간은 12:00~12:30에 30분을 부여하며, ${workerUn} 본인의 1주 평균 소정근로시간은 40시간, 1주 평균 연장근로시간은 12시간 이하(즉 1주 52시간 이하)임을 확인합니다.`}
						</li>
						<b>
							<li style={{ color: 'blue' }}>
								{`위 ①항 확인자 : ${worker} (인 또는 sign)`}
							</li>
						</b>
						{/* {(workerEnterDate < new Date("2021-09-01") || wageContractStartDate < new Date("2021-09-01"))  &&
            <>
              <li>{`② ${companyWa} ${workerUn} 본 근로계약 및 임금계약 이전에 ${companyGa} 임금의 구성항목 및 계산방법 지급방법 등이 표시되지 않았던 잘못된 근로계약서 및 연봉 근로계약서를 작성했지만, 
              이는 ${companyGa} 노동법을 잘못 해석해서 작성된 것이였음을 서로 확인하며, 본 근로계약 및 임금계약의 소정근로시간, 휴일, 휴가, 임금의 구성항목, 계산방법, 지급방법, 2주단위 탄력적 근로시간제(토요일 격주근무) 등의 
              근로조건은 입사시부터 ${worker} 본인에게 적용되던 근로조건임을 확인합니다.`}
              </li>
              <b>
                <li style={{color : 'blue' }}>
                  {`위 ②항 확인자 : ${worker} (인 또는 sign)`}
                </li>
              </b>
            </>
          } */}
					</ul>
				</div>
			)}
		</>
	);
}
