// UpdateCompanyInfo.js
import React, { useEffect, useState } from 'react';
import {
	collection,
	query,
	where,
	getDocs,
	writeBatch,
	deleteField,
} from 'firebase/firestore';
import { db } from '../../app/config/firebase';
import { useDispatch, useSelector } from 'react-redux';
import {
	clearClientReqs4In,
	fetchClientReqs4In,
} from '../clientsRequest/clientReq4In/clientReqActions4In';

const UpdateCompanyInfo = () => {
	const { clientReqs4In } = useSelector((state) => state.clientReq4InStore);
	const [companyId, setCompanyId] = useState('');
	const [companyName, setCompanyName] = useState('');
	const [companyAddress, setCompanyAddress] = useState('');
	const [companyRegion, setCompanyRegion] = useState('');
	const [ceoName, setCeoName] = useState('');
	const [startToEndDate, setStartToEndDate] = useState('');
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchClientReqs4In(companyName)).then(() => {});

		return () => {
			dispatch(clearClientReqs4In());
		};
	}, [dispatch, companyName]);

	const handleUpdate = async () => {
		if (!companyId) {
			alert('Please enter a company ID');
			return;
		}

		try {
			const workersRef = collection(db, 'workers');
			const q = query(workersRef, where('hostUid', '==', companyId));
			const querySnapshot = await getDocs(q);

			const batch = writeBatch(db);

			querySnapshot.forEach((doc) => {
				const docRef = doc.ref;
				batch.update(docRef, {
					// companyName,
					// companyAddress,
					// companyRegion,
					// ceoName,
					loginHistory: deleteField(),
					'monthlyPay.startToEndDate': startToEndDate, // monthlyPay.payDay 값을 변경
				});
			});

			await batch.commit();

			alert('Company information updated successfully!');
			setCompanyId('');
			setCompanyAddress('');
			setCompanyRegion('');
			setCeoName('');
		} catch (error) {
			console.error('Error updating company information: ', error);
			alert('Failed to update company information.');
		}
	};

	const inputStyle = {
		width: '400px',
		height: '40px',
		fontSize: '16px',
		padding: '10px',
		margin: '10px 0',
	};

	return (
		<div>
			<h2>Update Company Information</h2>
			<div>
				회사ID :
				<input
					type='text'
					placeholder='Company ID'
					value={companyId}
					onChange={(e) => setCompanyId(e.target.value)}
					style={inputStyle}
				/>
			</div>
			<div>
				대표이름 :
				<input
					type='text'
					placeholder='CEO Name'
					value={ceoName}
					onChange={(e) => setCeoName(e.target.value)}
					style={inputStyle}
				/>
			</div>
			<div>
				회사주소 :
				<input
					type='text'
					placeholder='Company Address'
					value={companyAddress}
					onChange={(e) => setCompanyAddress(e.target.value)}
					style={inputStyle}
				/>
			</div>
			<div>
				회사지역 :
				<input
					type='text'
					placeholder='Company Region'
					value={companyRegion}
					onChange={(e) => setCompanyRegion(e.target.value)}
					style={inputStyle}
				/>
			</div>
			<div>
				상호 :
				<input
					type='text'
					placeholder='Company Name'
					value={companyName}
					onChange={(e) => setCompanyName(e.target.value)}
					style={inputStyle}
				/>
			</div>
			<div>
				월급산정기간 :
				<input
					type='text'
					placeholder='New Pay Day'
					value={startToEndDate}
					onChange={(e) => setStartToEndDate(e.target.value)}
					style={inputStyle}
				/>
			</div>
			<button
				onClick={handleUpdate}
				style={{ margin: '20px 0', padding: '10px 20px', fontSize: '16px' }}>
				Update
			</button>
			<hr />
			등록된 직원수 : {clientReqs4In.length}
			<hr />
			{clientReqs4In.map((row, index) => (
				<span key={index} style={{ margin: '5px' }}>
					{row.worker.name}
				</span>
			))}
		</div>
	);
};

export default UpdateCompanyInfo;
