import React, { useEffect } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';
import {
	numberToCommaString,
	sumOfAbsentDaysLawOverWagesCom,
	sumOfAbsentDaysOrdinaryWagesCom,
	sumOfAbsentPaidHoliDaysOrdinaryWagesCom,
	sumOfAbsentTimeWagesCom,
	sumOfAWage1WagesCom,
	sumOfAWage2WagesCom,
	sumOfAWage3WagesCom,
	sumOfAWage4WagesCom,
	sumOfAWage5WagesCom,
	sumOfAWage6WagesCom,
	sumOfAWage7WagesCom,
	sumOfAWageplusDeductDuruCom,
	sumOfAWageplusMinus4InssCom,
	sumOfAWageplusDeductHealthCareCom,
	sumOfAWageplusDeductOldCareCom,
	sumOfAWageplusDeductWorkerCompanyLoanCom,
	sumOfAWageplusDeductWorkRegionTaxCom,
	sumOfAWageplusDeductWorkTaxCom,
	sumOfAWageplusIrregularBonusCom,
	sumOfAWageplusLastMonthAverWageCom,
	sumOfAWageplusPersonalIncentiveCom,
	sumOfAWageplusRegularBonusCom,
	sumOfAWageplusRetiredUnusedAnnualLeavesWageCom,
	sumOfAWageplusUnusedAnnualLeavesWageCom,
	sumOfAWageTF1WagesCom,
	sumOfAWageTF2WagesCom,
	sumOfAWageTF3WagesCom,
	sumOfAWageTF4WagesCom,
	sumOfBasicWagesCom,
	sumOfCalHealthCaresSumCom,
	sumOfCalNoJobInsurancesSumCom,
	sumOfCalOldCaresSumCom,
	sumOfCalPensionsSumCom,
	sumOfCalRegionTaxsSumCom,
	sumOfCalTaxsSumCom,
	sumOfCarWagesCom,
	sumOfChildWagesCom,
	sumOfdaysOfInterInAbusentLawOverWagesCom,
	sumOfdaysOfInterInAbusentOrdinaryWagesCom,
	sumOfdaysOfInterOutAbusentLawOverWagesCom,
	sumOfdaysOfInterOutAbusentOrdinaryWagesCom,
	sumOfDedectTaxEtcsSumCom,
	sumOfFinalRetireHealthCareRecalFeeCom,
	sumOfFinalRetireOldCareRecalFeeCom,
	sumOfHoliBTimePaidWagesCom,
	sumOfHoliNTimePaidWagesCom,
	sumOfHoliOTimePaidWagesCom,
	sumOfJobWagesCom,
	sumOfLicenseWagesCom,
	sumOfLongTermWagesCom,
	sumOfMealWagesCom,
	sumOfMonthDeductedWagesCom,
	sumOfMonthDeductedWagesWorker,
	sumOfMonthlyBonusWagesCom,
	sumOfMonthWagesCom,
	sumOfMonthWagesWorker,
	sumOfNumDeductEtcAWage1sCom,
	sumOfNumDeductEtcAWage2sCom,
	sumOfNumDeductEtcAWage3sCom,
	sumOfOWEtcWagesCom,
	sumOfStudyWagesCom,
	sumOfTFEtcWagesCom,
	sumOfTitleWagesCom,
	sumOfUsedALsWorker,
	sumOfWorkDayNTimePaidWagesCom,
	sumOfWorkDayOTimePaidWagesCom,
	sumOfAWageplusSpecialRandomWageCom,
	roundUp,
	sumOfAnnualLeavesIncludeMonthWagesCom,
	sumOfAWageplusIrregularDayMealTFWageCom,
	childCompanyCOWTitleName,
	sumOfAWageplusLastYearUnusedAnnualLeavesWageCom,
} from '../../../app/common/util/util';

export default function PaydocusTableWorkerList({
	paydocus,
	searchResults,
	setSearchResults,
	searchTerm,
	paydocuWorkerName,
}) {
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { clientInfo } = useSelector((state) => state.companyReducer);
	const id = clientInfo?.clientUid
		? clientInfo?.clientUid
		: clientInfo?.id
		? clientInfo?.id
		: currentUserProfile?.id;
	const companyName = clientInfo?.companyName
		? clientInfo?.companyName
		: currentUserProfile?.companyName;

	useEffect(() => {
		const searchedPaydocus = paydocus.filter((paydocu) => {
			return (
				paydocu?.workerInfo?.worker?.includes(searchTerm) ||
				paydocu?.info?.year?.includes(searchTerm)
			);
		});
		setSearchResults(searchedPaydocus);
	}, [searchTerm, setSearchResults, paydocus]);

	function exportTableToExcel(tableId, fileName) {
		let table = document.getElementById(tableId);
		let workbook = XLSX.utils.table_to_book(table, { sheet: fileName });
		XLSX.writeFile(workbook, `${fileName}.xlsx`);
	}

	const authLevel = clientInfo?.authLevel
		? clientInfo?.authLevel
		: currentUserProfile?.authLevel;

	const mealWage = searchResults.map(
		(searchResult) => searchResult?.fixedWage?.mealWage
	);
	const mealWageValue = mealWage.find((a) => a);

	const carWage = searchResults.map(
		(searchResult) => searchResult?.fixedWage?.carWage
	);
	const carWageValue = carWage.find((a) => a);

	const childWage = searchResults.map(
		(searchResult) => searchResult?.fixedWage?.childWage
	);
	const childWageValue = childWage.find((a) => a);

	const studyWage = searchResults.map(
		(searchResult) => searchResult?.fixedWage?.studyWage
	);
	const studyWageValue = studyWage.find((a) => a);

	const tFEtcWage = searchResults.map(
		(searchResult) => searchResult?.fixedWage?.tFEtcWage
	);
	const tFEtcWageValue = tFEtcWage.find((a) => a);

	const jobWage = searchResults.map(
		(searchResult) => searchResult?.fixedWage?.jobWage
	);
	const jobWageValue = jobWage.find((a) => a);

	const titleWage = searchResults.map(
		(searchResult) => searchResult?.fixedWage?.titleWage
	);
	const titleWageValue = titleWage.find((a) => a);

	const licenseWage = searchResults.map(
		(searchResult) => searchResult?.fixedWage?.licenseWage
	);
	const licenseWageValue = licenseWage.find((a) => a);

	const longTermWage = searchResults.map(
		(searchResult) => searchResult?.fixedWage?.longTermWage
	);
	const longTermWageValue = longTermWage.find((a) => a);

	const monthlyBonusWage = searchResults.map(
		(searchResult) => searchResult?.fixedWage?.monthlyBonusWage
	);
	const monthlyBonusWageValue = monthlyBonusWage.find((a) => a);

	const oWEtcWage = searchResults.map(
		(searchResult) => searchResult?.fixedWage?.oWEtcWage
	);
	const oWEtcWageValue = oWEtcWage.find((a) => a);

	const workDayOTimePaidWage = searchResults.map(
		(searchResult) => searchResult?.lawOverTimeWage?.workDayOTimePaidWage
	);
	const workDayOTimePaidWageValue = workDayOTimePaidWage.find((a) => a);

	const workDayNTimePaidWage = searchResults.map(
		(searchResult) => searchResult?.lawOverTimeWage?.workDayNTimePaidWage
	);
	const workDayNTimePaidWageValue = workDayNTimePaidWage.find((a) => a);

	const holiBTimePaidWage = searchResults.map(
		(searchResult) => searchResult?.lawOverTimeWage?.holiBTimePaidWage
	);
	const holiBTimePaidWageValue = holiBTimePaidWage.find((a) => a);

	const holiOTimePaidWage = searchResults.map(
		(searchResult) => searchResult?.lawOverTimeWage?.holiOTimePaidWage
	);
	const holiOTimePaidWageValue = holiOTimePaidWage.find((a) => a);

	const holiNTimePaidWage = searchResults.map(
		(searchResult) => searchResult?.lawOverTimeWage?.holiNTimePaidWage
	);
	const holiNTimePaidWageValue = holiNTimePaidWage.find((a) => a);

	const aWENameTF1 = searchResults.map(
		(searchResult) => searchResult?.fixedWage?.aWENameTF1
	);
	const aWENameTF1Value = aWENameTF1.find((a) => a);

	const aWENameTF2 = searchResults.map(
		(searchResult) => searchResult?.fixedWage?.aWENameTF2
	);
	const aWENameTF2Value = aWENameTF2.find((a) => a);

	const aWENameTF3 = searchResults.map(
		(searchResult) => searchResult?.fixedWage?.aWENameTF3
	);
	const aWENameTF3Value = aWENameTF3.find((a) => a);

	const aWENameTF4 = searchResults.map(
		(searchResult) => searchResult?.fixedWage?.aWENameTF4
	);
	const aWENameTF4Value = aWENameTF4.find((a) => a);

	const aWEName1 = searchResults.map(
		(searchResult) => searchResult?.fixedWage?.aWEName1
	);
	const aWEName1Value = aWEName1.find((a) => a);

	const aWEName2 = searchResults.map(
		(searchResult) => searchResult?.fixedWage?.aWEName2
	);
	const aWEName2Value = aWEName2.find((a) => a);

	const aWEName3 = searchResults.map(
		(searchResult) => searchResult?.fixedWage?.aWEName3
	);
	const aWEName3Value = aWEName3.find((a) => a);

	const aWEName4 = searchResults.map(
		(searchResult) => searchResult?.fixedWage?.aWEName4
	);
	const aWEName4Value = aWEName4.find((a) => a);

	const plusIrregularDayMealTFWage = searchResults.map(
		(searchResult) => searchResult?.addAverWage?.plusIrregularDayMealTFWage
	);
	const plusIrregularDayMealTFWageValue = plusIrregularDayMealTFWage.find(
		(a) => a
	);

	const plusRegularBonus = searchResults.map(
		(searchResult) => searchResult?.addAverWage?.plusRegularBonus
	);
	const plusRegularBonusValue = plusRegularBonus.find((a) => a);

	const plusIrregularBonus = searchResults.map(
		(searchResult) => searchResult?.addAverWage?.plusIrregularBonus
	);
	const plusIrregularBonusValue = plusIrregularBonus.find((a) => a);

	const plusUnusedAnnualLeavesWage = searchResults.map(
		(searchResult) => searchResult?.addAverWage?.plusUnusedAnnualLeavesWage
	);
	const plusUnusedAnnualLeavesWageValue = plusUnusedAnnualLeavesWage.find(
		(a) => a
	);

	const plusLastYearUnusedAnnualLeavesWage = searchResults.map(
		(searchResult) =>
			searchResult?.addAverWage?.plusLastYearUnusedAnnualLeavesWage
	);
	const plusLastYearUnusedAnnualLeavesWageValue =
		plusLastYearUnusedAnnualLeavesWage.find((a) => a);

	const plusRetiredUnusedAnnualLeavesWage = searchResults.map(
		(searchResult) => searchResult?.plusRetiredUnusedAnnualLeavesWage
	);
	const plusRetiredUnusedAnnualLeavesWageValue =
		plusRetiredUnusedAnnualLeavesWage.find((a) => a);

	const plusSpecialRandomWage = searchResults.map(
		(searchResult) => searchResult?.plusSpecialRandomWage
	);
	const plusSpecialRandomWageValue = plusSpecialRandomWage.find((a) => a);

	const plusLastMonthAverWage = searchResults.map(
		(searchResult) => searchResult?.addAverWage?.plusLastMonthAverWage
	);
	const plusLastMonthAverWageValue = plusLastMonthAverWage.find((a) => a);

	const plusPersonalIncentive = searchResults.map(
		(searchResult) => searchResult?.addAverWage?.plusPersonalIncentive
	);
	const plusPersonalIncentiveValue = plusPersonalIncentive.find((a) => a);

	const aWEName5 = searchResults.map(
		(searchResult) => searchResult?.addAverWage?.aWEName5
	);
	const aWEName5Value = aWEName5.find((a) => a);

	const aWEName6 = searchResults.map(
		(searchResult) => searchResult?.addAverWage?.aWEName6
	);
	const aWEName6Value = aWEName6.find((a) => a);

	const aWEName7 = searchResults.map(
		(searchResult) => searchResult?.addAverWage?.aWEName7
	);
	const aWEName7Value = aWEName7.find((a) => a);

	const absentDaysOrdinaryWage = searchResults.map(
		(searchResult) => searchResult?.absentMinusWage?.absentDaysOrdinaryWage
	);
	const absentDaysOrdinaryWageValue = absentDaysOrdinaryWage.find((a) => a);

	const absentPaidHoliDaysOrdinaryWage = searchResults.map(
		(searchResult) =>
			searchResult?.absentMinusWage?.absentPaidHoliDaysOrdinaryWage
	);
	const absentPaidHoliDaysOrdinaryWageValue =
		absentPaidHoliDaysOrdinaryWage.find((a) => a);

	const absentDaysLawOverWage = searchResults.map(
		(searchResult) => searchResult?.absentMinusWage?.absentDaysLawOverWage
	);
	const absentDaysLawOverWageValue = absentDaysLawOverWage.find((a) => a);

	const daysOfInterInAbusentOrdinaryWage = searchResults.map(
		(searchResult) =>
			searchResult?.absentMinusWage?.daysOfInterInAbusentOrdinaryWage
	);
	const daysOfInterInAbusentOrdinaryWageValue =
		daysOfInterInAbusentOrdinaryWage.find((a) => a);

	const daysOfInterInAbusentLawOverWage = searchResults.map(
		(searchResult) =>
			searchResult?.absentMinusWage?.daysOfInterInAbusentLawOverWage
	);
	const daysOfInterInAbusentLawOverWageValue =
		daysOfInterInAbusentLawOverWage.find((a) => a);

	const daysOfInterOutAbusentOrdinaryWage = searchResults.map(
		(searchResult) =>
			searchResult?.absentMinusWage?.daysOfInterOutAbusentOrdinaryWage
	);
	const daysOfInterOutAbusentOrdinaryWageValue =
		daysOfInterOutAbusentOrdinaryWage.find((a) => a);

	const daysOfInterOutAbusentLawOverWage = searchResults.map(
		(searchResult) =>
			searchResult?.absentMinusWage?.daysOfInterOutAbusentLawOverWage
	);
	const daysOfInterOutAbusentLawOverWageValue =
		daysOfInterOutAbusentLawOverWage.find((a) => a);

	const absentTimeWage = searchResults.map(
		(searchResult) => searchResult?.absentMinusWage?.absentTimeWage
	);
	const absentTimeWageValue = absentTimeWage.find((a) => a);

	const plusDeductHealthCare = searchResults.map(
		(searchResult) => searchResult?.deductTaxAnd4In?.plusDeductHealthCare
	);
	const plusDeductHealthCareValue = plusDeductHealthCare.find((a) => a);

	const plusDeductOldCare = searchResults.map(
		(searchResult) => searchResult?.deductTaxAnd4In?.plusDeductOldCare
	);
	const plusDeductOldCareValue = plusDeductOldCare.find((a) => a);

	const plusDeductWorkTax = searchResults.map(
		(searchResult) => searchResult?.deductTaxAnd4In?.plusDeductWorkTax
	);
	const plusDeductWorkTaxValue = plusDeductWorkTax.find((a) => a);

	const plusDeductWorkRegionTax = searchResults.map(
		(searchResult) => searchResult?.deductTaxAnd4In?.plusDeductWorkRegionTax
	);
	const plusDeductWorkRegionTaxValue = plusDeductWorkRegionTax.find((a) => a);

	const plusDeductDuru = searchResults.map(
		(searchResult) => searchResult?.deductTaxAnd4In?.plusDeductDuru
	);
	const plusDeductDuruValue = plusDeductDuru.find((a) => a);

	const plusMinus4Ins = searchResults.map(
		(searchResult) => searchResult?.deductTaxAnd4In?.plusMinus4Ins
	);
	const plusMinus4InsValue = plusMinus4Ins.find((a) => a);

	const plusDeductWorkerCompanyLoan = searchResults.map(
		(searchResult) => searchResult?.deductTaxAnd4In?.plusDeductWorkerCompanyLoan
	);
	const plusDeductWorkerCompanyLoanValue = plusDeductWorkerCompanyLoan.find(
		(a) => a
	);

	const deductEtcName1 = searchResults.map(
		(searchResult) => searchResult?.deductTaxAnd4In?.deductEtcName1
	);
	const deductEtcName1Value = deductEtcName1.find((a) => a);

	const deductEtcName2 = searchResults.map(
		(searchResult) => searchResult?.deductTaxAnd4In?.deductEtcName2
	);
	const deductEtcName2Value = deductEtcName2.find((a) => a);

	const deductEtcName3 = searchResults.map(
		(searchResult) => searchResult?.deductTaxAnd4In?.deductEtcName3
	);
	const deductEtcName3Value = deductEtcName3.find((a) => a);

	const finalRetireHealthCareRecalFee = searchResults.map(
		(searchResult) =>
			searchResult?.deductTaxAnd4In?.finalRetireHealthCareRecalFee
	);
	const finalRetireHealthCareRecalFeeValue = finalRetireHealthCareRecalFee.find(
		(a) => a
	);

	const finalRetireOldCareRecalFee = searchResults.map(
		(searchResult) => searchResult?.deductTaxAnd4In?.finalRetireOldCareRecalFee
	);
	const finalRetireOldCareRecalFeeValue = finalRetireOldCareRecalFee.find(
		(a) => a
	);

	const annualLeavesIncludeMonthWage = searchResults.map(
		(searchResult) => searchResult?.annualLeavesIncludeMonthWage
	);
	const annualLeavesIncludeMonthWageValue = annualLeavesIncludeMonthWage.find(
		(a) => a
	);

	return (
		<>
			{paydocus.length !== 0 && (
				<>
					<Button
						onClick={() =>
							exportTableToExcel(
								'table-to-xls',
								`${paydocuWorkerName} 임금대장`
							)
						}
						color='green'>
						{`${paydocuWorkerName} 임금대장 엑셀파일 전환`}
					</Button>
					<br />
					<br />
					{!currentUserProfile.ceoName ? (
						<Table border='1' width='100%' align='center' id='table-to-xls'>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>년월</Table.HeaderCell>
									<Table.HeaderCell>사번</Table.HeaderCell>
									<Table.HeaderCell>직원명</Table.HeaderCell>
									<Table.HeaderCell>공제 전 임금(월)</Table.HeaderCell>
									<Table.HeaderCell>공제 후 임금(원)</Table.HeaderCell>
									<Table.HeaderCell>사용연차(일)</Table.HeaderCell>
									<Table.HeaderCell>회사등록일</Table.HeaderCell>
									<Table.HeaderCell>상세보기</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							{paydocus.map((workerData) => (
								<Table.Body>
									<Table.Row key={workerData.id}>
										<Table.Cell>{workerData?.info?.yearMonth}</Table.Cell>
										<Table.Cell>
											{workerData?.workerInfo?.workerComNumber}
										</Table.Cell>
										<Table.Cell>{workerData?.workerInfo?.worker}</Table.Cell>
										<Table.Cell>
											{numberToCommaString(
												workerData?.info?.totalMonthWageAmount
											)}
										</Table.Cell>
										<Table.Cell>
											{numberToCommaString(workerData?.info?.deductWage)}
										</Table.Cell>
										<Table.Cell>{workerData?.usedAnnualLeaves}</Table.Cell>
										<Table.Cell>
											{workerData?.createdAt
												? format(
														new Date(workerData?.createdAt),
														'yyyy-MM-dd h:mm a'
												  )
												: null}
										</Table.Cell>
										<Table.Cell>
											<Button
												as={Link}
												to={`/paydocuDetailedPage/${workerData?.id}`}
												color='orange'
												floated='right'>
												임금명세서 보기
											</Button>
										</Table.Cell>
									</Table.Row>
								</Table.Body>
							))}
							<tfoot>
								<Table.Row>
									<Table.Cell>합계</Table.Cell>
									<Table.Cell></Table.Cell>
									<Table.Cell>
										{numberToCommaString(sumOfMonthWagesWorker(paydocus))}원
									</Table.Cell>
									<Table.Cell>
										{numberToCommaString(
											sumOfMonthDeductedWagesWorker(paydocus)
										)}
										원
									</Table.Cell>
									<Table.Cell>{sumOfUsedALsWorker(paydocus)}일</Table.Cell>
									<Table.Cell></Table.Cell>
									<Table.Cell></Table.Cell>
									<Table.Cell></Table.Cell>
								</Table.Row>
							</tfoot>
						</Table>
					) : (
						<div
							style={{
								overflowX: 'auto',
								width: '100%',
								whiteSpace: 'nowrap',
								borderCollapse: 'collapse',
							}}>
							<Table
								style={{ textAlign: 'center', width: '100%', border: '1' }}
								id='table-to-xls'>
								<Table.Header>
									<Table.Row>
										{authLevel < 100 ? null : (
											<Table.HeaderCell>회사명</Table.HeaderCell>
										)}
										<Table.HeaderCell>년월</Table.HeaderCell>
										<Table.HeaderCell>년</Table.HeaderCell>
										<Table.HeaderCell>월</Table.HeaderCell>
										<Table.HeaderCell>사번</Table.HeaderCell>
										<Table.HeaderCell>부서명</Table.HeaderCell>
										<Table.HeaderCell>직위/직책</Table.HeaderCell>
										<Table.HeaderCell>직원명</Table.HeaderCell>
										<Table.HeaderCell>입사일</Table.HeaderCell>
										<Table.HeaderCell>
											마지막 <br /> 근무일
										</Table.HeaderCell>
										<Table.HeaderCell>기본급</Table.HeaderCell>
										{mealWageValue && <Table.HeaderCell>식대</Table.HeaderCell>}
										{carWageValue && (
											<Table.HeaderCell>차량유지비</Table.HeaderCell>
										)}
										{childWageValue && (
											<Table.HeaderCell>육아수당</Table.HeaderCell>
										)}
										{studyWageValue && (
											<Table.HeaderCell>연구수당</Table.HeaderCell>
										)}
										{tFEtcWageValue && (
											<Table.HeaderCell>기타비과세</Table.HeaderCell>
										)}
										{jobWageValue && (
											<Table.HeaderCell>직무수당</Table.HeaderCell>
										)}
										{titleWageValue && (
											<Table.HeaderCell>
												{childCompanyCOWTitleName(companyName)}
											</Table.HeaderCell>
										)}
										{licenseWageValue && (
											<Table.HeaderCell>
												자격/면허 <br /> 수당
											</Table.HeaderCell>
										)}
										{longTermWageValue && (
											<Table.HeaderCell>장기근속수당</Table.HeaderCell>
										)}

										{monthlyBonusWageValue && (
											<Table.HeaderCell>월상여금</Table.HeaderCell>
										)}
										{oWEtcWageValue && (
											<Table.HeaderCell>
												기타과세 <br /> 통상임금
											</Table.HeaderCell>
										)}
										{annualLeavesIncludeMonthWageValue && (
											<Table.HeaderCell>유급연차수당</Table.HeaderCell>
										)}
										{workDayOTimePaidWageValue && (
											<Table.HeaderCell>연장수당</Table.HeaderCell>
										)}
										{workDayNTimePaidWageValue && (
											<Table.HeaderCell>야간수당</Table.HeaderCell>
										)}
										{holiBTimePaidWageValue && (
											<Table.HeaderCell>
												휴일기본 <br /> 수당
											</Table.HeaderCell>
										)}
										{holiOTimePaidWageValue && (
											<Table.HeaderCell>
												휴일연장 <br /> 수당
											</Table.HeaderCell>
										)}
										{holiNTimePaidWageValue && (
											<Table.HeaderCell>
												휴일야간 <br /> 수당
											</Table.HeaderCell>
										)}

										{aWENameTF1Value && (
											<Table.HeaderCell>{aWENameTF1Value}</Table.HeaderCell>
										)}
										{aWENameTF2Value && (
											<Table.HeaderCell>{aWENameTF2Value}</Table.HeaderCell>
										)}
										{aWENameTF3Value && (
											<Table.HeaderCell>{aWENameTF3Value}</Table.HeaderCell>
										)}
										{aWENameTF4Value && (
											<Table.HeaderCell>{aWENameTF4Value}</Table.HeaderCell>
										)}

										{aWEName1Value && (
											<Table.HeaderCell>{aWEName1Value}</Table.HeaderCell>
										)}
										{aWEName2Value && (
											<Table.HeaderCell>{aWEName2Value}</Table.HeaderCell>
										)}
										{aWEName3Value && (
											<Table.HeaderCell>{aWEName3Value}</Table.HeaderCell>
										)}
										{aWEName4Value && (
											<Table.HeaderCell>{aWEName4Value}</Table.HeaderCell>
										)}

										{plusIrregularDayMealTFWageValue && (
											<Table.HeaderCell>출근식대</Table.HeaderCell>
										)}

										{plusRegularBonusValue && (
											<Table.HeaderCell>
												정기 <br /> 상여금
											</Table.HeaderCell>
										)}
										{plusIrregularBonusValue && (
											<Table.HeaderCell>
												비정기 <br /> 상여금
											</Table.HeaderCell>
										)}
										{plusUnusedAnnualLeavesWageValue && (
											<Table.HeaderCell>
												미사용 <br /> 연차수당
											</Table.HeaderCell>
										)}
										{plusLastYearUnusedAnnualLeavesWageValue && (
											<Table.HeaderCell>
												전년도 미사용 <br /> 연차수당
											</Table.HeaderCell>
										)}
										{plusLastMonthAverWageValue && (
											<Table.HeaderCell>과오납 급여</Table.HeaderCell>
										)}
										{plusPersonalIncentiveValue && (
											<Table.HeaderCell>
												개인실적 <br /> 인센티브
											</Table.HeaderCell>
										)}

										{aWEName5Value && (
											<Table.HeaderCell>{aWEName5Value}</Table.HeaderCell>
										)}
										{aWEName6Value && (
											<Table.HeaderCell>{aWEName6Value}</Table.HeaderCell>
										)}
										{aWEName7Value && (
											<Table.HeaderCell>{aWEName7Value}</Table.HeaderCell>
										)}
										{plusSpecialRandomWageValue && (
											<Table.HeaderCell>
												기타금품 <br /> (특별상여금)
											</Table.HeaderCell>
										)}

										{plusRetiredUnusedAnnualLeavesWageValue && (
											<Table.HeaderCell>
												퇴직 미사용 <br /> 연차수당
											</Table.HeaderCell>
										)}

										{daysOfInterInAbusentOrdinaryWageValue && (
											<Table.HeaderCell>
												중도입사 <br /> 공제
											</Table.HeaderCell>
										)}
										{daysOfInterInAbusentLawOverWageValue && (
											<Table.HeaderCell>
												중도입사 <br /> 법정수당공제
											</Table.HeaderCell>
										)}
										{daysOfInterOutAbusentOrdinaryWageValue && (
											<Table.HeaderCell>
												중도퇴사 <br /> 공제
											</Table.HeaderCell>
										)}
										{daysOfInterOutAbusentLawOverWageValue && (
											<Table.HeaderCell>
												중도퇴사 <br /> 법정수당공제
											</Table.HeaderCell>
										)}
										{absentDaysOrdinaryWageValue && (
											<Table.HeaderCell>
												결근일 <br /> 공제
											</Table.HeaderCell>
										)}

										{absentPaidHoliDaysOrdinaryWageValue && (
											<Table.HeaderCell>
												주휴 <br /> 공제
											</Table.HeaderCell>
										)}

										{absentDaysLawOverWageValue && (
											<Table.HeaderCell>
												결근법정수당 <br /> 공제
											</Table.HeaderCell>
										)}
										{absentTimeWageValue && (
											<Table.HeaderCell>
												결근시간 <br /> 공제
											</Table.HeaderCell>
										)}
										<Table.HeaderCell>임금합계</Table.HeaderCell>
										<Table.HeaderCell>소득세</Table.HeaderCell>
										<Table.HeaderCell>지방소득세</Table.HeaderCell>
										<Table.HeaderCell>국민연금</Table.HeaderCell>
										<Table.HeaderCell>건강보험</Table.HeaderCell>
										<Table.HeaderCell>요양보험</Table.HeaderCell>
										<Table.HeaderCell>고용보험</Table.HeaderCell>

										{plusDeductHealthCareValue && (
											<Table.HeaderCell>
												건강보험료 <br /> 정산금
											</Table.HeaderCell>
										)}
										{plusDeductOldCareValue && (
											<Table.HeaderCell>
												장기요양보험료 <br /> 정산금
											</Table.HeaderCell>
										)}
										{plusDeductWorkTaxValue && (
											<Table.HeaderCell>
												근로소득세 <br /> 정산금
											</Table.HeaderCell>
										)}
										{plusDeductWorkRegionTaxValue && (
											<Table.HeaderCell>
												지방소득세 <br /> 정산금
											</Table.HeaderCell>
										)}

										{finalRetireHealthCareRecalFeeValue && (
											<Table.HeaderCell>
												건강보험 <br /> 퇴직 정산금
											</Table.HeaderCell>
										)}
										{finalRetireOldCareRecalFeeValue && (
											<Table.HeaderCell>
												요양보험 <br /> 퇴직 정산금
											</Table.HeaderCell>
										)}

										{plusDeductWorkerCompanyLoanValue && (
											<Table.HeaderCell>
												대여금 <br /> 정산금
											</Table.HeaderCell>
										)}
										{plusDeductDuruValue && (
											<Table.HeaderCell>
												두루누리 <br /> 지원금
											</Table.HeaderCell>
										)}
										{plusMinus4InsValue && (
											<Table.HeaderCell>공제 조정</Table.HeaderCell>
										)}

										{deductEtcName1Value && (
											<Table.HeaderCell>{deductEtcName1Value}</Table.HeaderCell>
										)}
										{deductEtcName2Value && (
											<Table.HeaderCell>{deductEtcName2Value}</Table.HeaderCell>
										)}
										{deductEtcName3Value && (
											<Table.HeaderCell>{deductEtcName3Value}</Table.HeaderCell>
										)}
										<Table.HeaderCell>공제계</Table.HeaderCell>
										<Table.HeaderCell>실수령액</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								{searchResults.map(
									(searchResult, index) =>
										(searchResult?.companyInfo?.agentUid === id ||
											searchResult.companyInfo.companyId === id ||
											currentUserProfile?.authLevel >= 100) && (
											<React.Fragment key={searchResult.id + index}>
												<Table.Body>
													<Table.Row>
														{authLevel < 100 ? null : (
															<Table.Cell>
																{searchResult?.companyInfo?.companyName}
															</Table.Cell>
														)}
														<Table.Cell>
															{searchResult?.info?.yearMonth}
														</Table.Cell>
														<Table.Cell>{searchResult?.info?.year}</Table.Cell>
														<Table.Cell>
															{searchResult?.info?.month
																.toString()
																.padStart(2, '0')}
														</Table.Cell>
														<Table.Cell>
															{searchResult?.workerInfo?.workerComNumber}
														</Table.Cell>
														<Table.Cell>
															{searchResult?.workerInfo?.workerDivisions}
														</Table.Cell>
														<Table.Cell>
															{searchResult?.workerInfo?.workerTitles}
														</Table.Cell>
														<Table.Cell>
															{searchResult?.workerInfo?.worker}
														</Table.Cell>
														<Table.Cell>
															{searchResult?.workerInfo?.workerEnteredDate
																? format(
																		new Date(
																			searchResult?.workerInfo?.workerEnteredDate
																		),
																		'yyyy-MM-dd'
																  )
																: ''}
														</Table.Cell>
														<Table.Cell>
															{searchResult?.workerInfo?.workerRetiredDate
																? format(
																		new Date(
																			searchResult?.workerInfo?.workerRetiredDate
																		),
																		'yyyy-MM-dd'
																  )
																: ''}
														</Table.Cell>
														<Table.Cell>
															{numberToCommaString(
																roundUp(searchResult?.fixedWage?.basicWage)
															)}
														</Table.Cell>
														{mealWageValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.fixedWage?.mealWage
																)}
															</Table.Cell>
														)}
														{carWageValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.fixedWage?.carWage
																)}
															</Table.Cell>
														)}
														{childWageValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.fixedWage?.childWage
																)}
															</Table.Cell>
														)}
														{studyWageValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.fixedWage?.studyWage
																)}
															</Table.Cell>
														)}
														{tFEtcWageValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.fixedWage?.tFEtcWage
																)}
															</Table.Cell>
														)}
														{jobWageValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.fixedWage?.jobWage
																)}
															</Table.Cell>
														)}
														{titleWageValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.fixedWage?.titleWage
																)}
															</Table.Cell>
														)}
														{licenseWageValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.fixedWage?.licenseWage
																)}
															</Table.Cell>
														)}
														{longTermWageValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.fixedWage?.longTermWage
																)}
															</Table.Cell>
														)}
														{monthlyBonusWageValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.fixedWage?.monthlyBonusWage
																)}
															</Table.Cell>
														)}
														{oWEtcWageValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.fixedWage?.oWEtcWage
																)}
															</Table.Cell>
														)}
														{annualLeavesIncludeMonthWageValue && (
															<Table.Cell>
																{numberToCommaString(
																	roundUp(
																		searchResult?.annualLeavesIncludeMonthWage
																	)
																)}
															</Table.Cell>
														)}
														{workDayOTimePaidWageValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.lawOverTimeWage
																		?.workDayOTimePaidWage
																)}
															</Table.Cell>
														)}
														{workDayNTimePaidWageValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.lawOverTimeWage
																		?.workDayNTimePaidWage
																)}
															</Table.Cell>
														)}
														{holiBTimePaidWageValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.lawOverTimeWage
																		?.holiBTimePaidWage
																)}
															</Table.Cell>
														)}
														{holiOTimePaidWageValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.lawOverTimeWage
																		?.holiOTimePaidWage
																)}
															</Table.Cell>
														)}
														{holiNTimePaidWageValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.lawOverTimeWage
																		?.holiNTimePaidWage
																)}
															</Table.Cell>
														)}

														{aWENameTF1Value && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.fixedWage?.aWageTF1Wage
																)}
															</Table.Cell>
														)}
														{aWENameTF2Value && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.fixedWage?.aWageTF2Wage
																)}
															</Table.Cell>
														)}
														{aWENameTF3Value && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.fixedWage?.aWageTF3Wage
																)}
															</Table.Cell>
														)}
														{aWENameTF4Value && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.fixedWage?.aWageTF4Wage
																)}
															</Table.Cell>
														)}

														{aWEName1Value && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.fixedWage?.aWage1Wage
																)}
															</Table.Cell>
														)}
														{aWEName2Value && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.fixedWage?.aWage2Wage
																)}
															</Table.Cell>
														)}
														{aWEName3Value && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.fixedWage?.aWage3Wage
																)}
															</Table.Cell>
														)}
														{aWEName4Value && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.fixedWage?.aWage4Wage
																)}
															</Table.Cell>
														)}

														{plusIrregularDayMealTFWageValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.addAverWage
																		?.plusIrregularDayMealTFWage
																)}
															</Table.Cell>
														)}

														{plusRegularBonusValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.addAverWage?.plusRegularBonus
																)}
															</Table.Cell>
														)}
														{plusIrregularBonusValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.addAverWage?.plusIrregularBonus
																)}
															</Table.Cell>
														)}
														{plusUnusedAnnualLeavesWageValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.addAverWage
																		?.plusUnusedAnnualLeavesWage
																)}
															</Table.Cell>
														)}
														{plusLastYearUnusedAnnualLeavesWageValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.addAverWage
																		?.plusLastYearUnusedAnnualLeavesWage
																)}
															</Table.Cell>
														)}
														{plusLastMonthAverWageValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.addAverWage
																		?.plusLastMonthAverWage
																)}
															</Table.Cell>
														)}
														{plusPersonalIncentiveValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.addAverWage
																		?.plusPersonalIncentive
																)}
															</Table.Cell>
														)}

														{aWEName5Value && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.addAverWage?.aWage5Wage
																)}
															</Table.Cell>
														)}
														{aWEName6Value && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.addAverWage?.aWage6Wage
																)}
															</Table.Cell>
														)}
														{aWEName7Value && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.addAverWage?.aWage7Wage
																)}
															</Table.Cell>
														)}
														{plusSpecialRandomWageValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.plusSpecialRandomWage
																)}
															</Table.Cell>
														)}
														{plusRetiredUnusedAnnualLeavesWageValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.plusRetiredUnusedAnnualLeavesWage
																)}
															</Table.Cell>
														)}
														{daysOfInterInAbusentOrdinaryWageValue && (
															<Table.Cell>
																{searchResult?.absentMinusWage
																	?.daysOfInterInAbusentOrdinaryWage
																	? numberToCommaString(
																			searchResult?.absentMinusWage
																				?.daysOfInterInAbusentOrdinaryWage === 0
																				? 0
																				: searchResult?.absentMinusWage
																						?.daysOfInterInAbusentOrdinaryWage *
																						-1
																	  )
																	: null}
															</Table.Cell>
														)}

														{daysOfInterInAbusentLawOverWageValue && (
															<Table.Cell>
																{searchResult?.absentMinusWage
																	?.daysOfInterInAbusentLawOverWage
																	? numberToCommaString(
																			searchResult?.absentMinusWage
																				?.daysOfInterInAbusentLawOverWage === 0
																				? 0
																				: searchResult?.absentMinusWage
																						?.daysOfInterInAbusentLawOverWage *
																						-1
																	  )
																	: null}
															</Table.Cell>
														)}

														{daysOfInterOutAbusentOrdinaryWageValue && (
															<Table.Cell>
																{searchResult?.absentMinusWage
																	?.daysOfInterOutAbusentOrdinaryWage
																	? numberToCommaString(
																			searchResult?.absentMinusWage
																				?.daysOfInterOutAbusentOrdinaryWage ===
																				0
																				? 0
																				: searchResult?.absentMinusWage
																						?.daysOfInterOutAbusentOrdinaryWage *
																						-1
																	  )
																	: null}
															</Table.Cell>
														)}

														{daysOfInterOutAbusentLawOverWageValue && (
															<Table.Cell>
																{searchResult?.absentMinusWage
																	?.daysOfInterOutAbusentLawOverWage
																	? numberToCommaString(
																			searchResult?.absentMinusWage
																				?.daysOfInterOutAbusentLawOverWage === 0
																				? 0
																				: searchResult?.absentMinusWage
																						?.daysOfInterOutAbusentLawOverWage *
																						-1
																	  )
																	: null}
															</Table.Cell>
														)}

														{absentDaysOrdinaryWageValue && (
															<Table.Cell>
																{searchResult?.absentMinusWage
																	?.absentDaysOrdinaryWage
																	? numberToCommaString(
																			searchResult?.absentMinusWage
																				?.absentDaysOrdinaryWage === 0
																				? 0
																				: searchResult?.absentMinusWage
																						?.absentDaysOrdinaryWage * -1
																	  )
																	: null}
															</Table.Cell>
														)}

														{absentPaidHoliDaysOrdinaryWageValue && (
															<Table.Cell>
																{searchResult?.absentMinusWage
																	?.absentPaidHoliDaysOrdinaryWage
																	? numberToCommaString(
																			searchResult?.absentMinusWage
																				?.absentPaidHoliDaysOrdinaryWage === 0
																				? 0
																				: searchResult?.absentMinusWage
																						?.absentPaidHoliDaysOrdinaryWage *
																						-1
																	  )
																	: null}
															</Table.Cell>
														)}

														{absentDaysLawOverWageValue && (
															<Table.Cell>
																{searchResult?.absentMinusWage
																	?.absentDaysLawOverWage
																	? numberToCommaString(
																			searchResult?.absentMinusWage
																				?.absentDaysLawOverWage === 0
																				? 0
																				: searchResult?.absentMinusWage
																						?.absentDaysLawOverWage * -1
																	  )
																	: null}
															</Table.Cell>
														)}
														{absentTimeWageValue && (
															<Table.Cell>
																{searchResult?.absentMinusWage?.absentTimeWage
																	? numberToCommaString(
																			searchResult?.absentMinusWage
																				?.absentTimeWage === 0
																				? 0
																				: searchResult?.absentMinusWage
																						?.absentTimeWage * -1
																	  )
																	: null}
															</Table.Cell>
														)}
														<Table.Cell>
															{numberToCommaString(
																roundUp(
																	searchResult?.info?.totalMonthWageAmount
																)
															)}
														</Table.Cell>
														<Table.Cell>
															{numberToCommaString(
																searchResult?.deductTaxAnd4In?.userFixedTax !==
																	'' &&
																	!isNaN(
																		searchResult?.deductTaxAnd4In?.userFixedTax
																	)
																	? searchResult?.deductTaxAnd4In?.userFixedTax
																	: searchResult?.deductTaxAnd4In?.calTax
															)}
														</Table.Cell>
														<Table.Cell>
															{numberToCommaString(
																searchResult?.deductTaxAnd4In
																	?.userFixedRegionTax !== '' &&
																	!isNaN(
																		searchResult?.deductTaxAnd4In
																			?.userFixedRegionTax
																	)
																	? searchResult?.deductTaxAnd4In
																			?.userFixedRegionTax
																	: searchResult?.deductTaxAnd4In?.calRegionTax
															)}
														</Table.Cell>
														<Table.Cell>
															{numberToCommaString(
																searchResult?.deductTaxAnd4In
																	?.userFixedPension !== '' &&
																	!isNaN(
																		searchResult?.deductTaxAnd4In
																			?.userFixedPension
																	)
																	? searchResult?.deductTaxAnd4In
																			?.userFixedPension
																	: searchResult?.deductTaxAnd4In?.calPension
															)}
														</Table.Cell>
														<Table.Cell>
															{numberToCommaString(
																searchResult?.deductTaxAnd4In
																	?.userFixedHealthCare !== '' &&
																	!isNaN(
																		searchResult?.deductTaxAnd4In
																			?.userFixedHealthCare
																	)
																	? searchResult?.deductTaxAnd4In
																			?.userFixedHealthCare
																	: searchResult?.deductTaxAnd4In?.calHealthCare
															)}
														</Table.Cell>
														<Table.Cell>
															{numberToCommaString(
																searchResult?.deductTaxAnd4In
																	?.userFixedOldCare !== '' &&
																	!isNaN(
																		searchResult?.deductTaxAnd4In
																			?.userFixedOldCare
																	)
																	? searchResult?.deductTaxAnd4In
																			?.userFixedOldCare
																	: searchResult?.deductTaxAnd4In?.calOldCare
															)}
														</Table.Cell>
														<Table.Cell>
															{numberToCommaString(
																searchResult?.deductTaxAnd4In
																	?.userFixedNoJobInsurance !== '' &&
																	!isNaN(
																		searchResult?.deductTaxAnd4In
																			?.userFixedNoJobInsurance
																	)
																	? searchResult?.deductTaxAnd4In
																			?.userFixedNoJobInsurance
																	: searchResult?.deductTaxAnd4In
																			?.calNoJobInsurance
															)}
														</Table.Cell>

														{plusDeductHealthCareValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.deductTaxAnd4In
																		?.plusDeductHealthCare
																)}
															</Table.Cell>
														)}
														{plusDeductOldCareValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.deductTaxAnd4In
																		?.plusDeductOldCare
																)}
															</Table.Cell>
														)}
														{plusDeductWorkTaxValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.deductTaxAnd4In
																		?.plusDeductWorkTax
																)}
															</Table.Cell>
														)}
														{plusDeductWorkRegionTaxValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.deductTaxAnd4In
																		?.plusDeductWorkRegionTax
																)}
															</Table.Cell>
														)}

														{finalRetireHealthCareRecalFeeValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.deductTaxAnd4In
																		?.finalRetireHealthCareRecalFee
																)}
															</Table.Cell>
														)}
														{finalRetireOldCareRecalFeeValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.deductTaxAnd4In
																		?.finalRetireOldCareRecalFee
																)}
															</Table.Cell>
														)}

														{plusDeductWorkerCompanyLoanValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.deductTaxAnd4In
																		?.plusDeductWorkerCompanyLoan
																)}
															</Table.Cell>
														)}
														{plusDeductDuruValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.deductTaxAnd4In?.plusDeductDuru
																)}
															</Table.Cell>
														)}
														{plusMinus4InsValue && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.deductTaxAnd4In?.plusMinus4Ins
																)}
															</Table.Cell>
														)}

														{deductEtcName1Value && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.deductTaxAnd4In
																		?.numDeductEtcAWage1
																)}
															</Table.Cell>
														)}
														{deductEtcName2Value && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.deductTaxAnd4In
																		?.numDeductEtcAWage2
																)}
															</Table.Cell>
														)}
														{deductEtcName3Value && (
															<Table.Cell>
																{numberToCommaString(
																	searchResult?.deductTaxAnd4In
																		?.numDeductEtcAWage3
																)}
															</Table.Cell>
														)}
														<Table.Cell>
															{numberToCommaString(
																searchResult?.deductTaxAnd4In?.dedectTaxEtc
															)}
														</Table.Cell>
														<Table.Cell>
															{numberToCommaString(
																searchResult?.info?.deductWage
															)}
														</Table.Cell>
													</Table.Row>
												</Table.Body>
											</React.Fragment>
										)
								)}
								<Table.Body>
									<Table.Row>
										{authLevel < 100 ? (
											<Table.Cell colSpan='9'>합계</Table.Cell>
										) : (
											<Table.Cell colSpan='10'>합계</Table.Cell>
										)}
										<Table.Cell>
											{numberToCommaString(sumOfBasicWagesCom(searchResults))}
										</Table.Cell>
										{mealWageValue && (
											<Table.Cell>
												{numberToCommaString(sumOfMealWagesCom(searchResults))}
											</Table.Cell>
										)}
										{carWageValue && (
											<Table.Cell>
												{numberToCommaString(sumOfCarWagesCom(searchResults))}
											</Table.Cell>
										)}
										{childWageValue && (
											<Table.Cell>
												{numberToCommaString(sumOfChildWagesCom(searchResults))}
											</Table.Cell>
										)}
										{studyWageValue && (
											<Table.Cell>
												{numberToCommaString(sumOfStudyWagesCom(searchResults))}
											</Table.Cell>
										)}
										{tFEtcWageValue && (
											<Table.Cell>
												{numberToCommaString(sumOfTFEtcWagesCom(searchResults))}
											</Table.Cell>
										)}
										{jobWageValue && (
											<Table.Cell>
												{numberToCommaString(sumOfJobWagesCom(searchResults))}
											</Table.Cell>
										)}
										{titleWageValue && (
											<Table.Cell>
												{numberToCommaString(sumOfTitleWagesCom(searchResults))}
											</Table.Cell>
										)}
										{licenseWageValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfLicenseWagesCom(searchResults)
												)}
											</Table.Cell>
										)}
										{longTermWageValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfLongTermWagesCom(searchResults)
												)}
											</Table.Cell>
										)}
										{monthlyBonusWageValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfMonthlyBonusWagesCom(searchResults)
												)}
											</Table.Cell>
										)}
										{oWEtcWageValue && (
											<Table.Cell>
												{numberToCommaString(sumOfOWEtcWagesCom(searchResults))}
											</Table.Cell>
										)}
										{annualLeavesIncludeMonthWageValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAnnualLeavesIncludeMonthWagesCom(searchResults)
												)}
											</Table.Cell>
										)}
										{workDayOTimePaidWageValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfWorkDayOTimePaidWagesCom(searchResults)
												)}
											</Table.Cell>
										)}
										{workDayNTimePaidWageValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfWorkDayNTimePaidWagesCom(searchResults)
												)}
											</Table.Cell>
										)}
										{holiBTimePaidWageValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfHoliBTimePaidWagesCom(searchResults)
												)}
											</Table.Cell>
										)}
										{holiOTimePaidWageValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfHoliOTimePaidWagesCom(searchResults)
												)}
											</Table.Cell>
										)}
										{holiNTimePaidWageValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfHoliNTimePaidWagesCom(searchResults)
												)}
											</Table.Cell>
										)}

										{aWENameTF1Value && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAWageTF1WagesCom(searchResults)
												)}
											</Table.Cell>
										)}
										{aWENameTF2Value && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAWageTF2WagesCom(searchResults)
												)}
											</Table.Cell>
										)}
										{aWENameTF3Value && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAWageTF3WagesCom(searchResults)
												)}
											</Table.Cell>
										)}
										{aWENameTF4Value && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAWageTF4WagesCom(searchResults)
												)}
											</Table.Cell>
										)}

										{aWEName1Value && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAWage1WagesCom(searchResults)
												)}
											</Table.Cell>
										)}
										{aWEName2Value && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAWage2WagesCom(searchResults)
												)}
											</Table.Cell>
										)}
										{aWEName3Value && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAWage3WagesCom(searchResults)
												)}
											</Table.Cell>
										)}
										{aWEName4Value && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAWage4WagesCom(searchResults)
												)}
											</Table.Cell>
										)}

										{plusIrregularDayMealTFWageValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAWageplusIrregularDayMealTFWageCom(searchResults)
												)}
											</Table.Cell>
										)}

										{plusRegularBonusValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAWageplusRegularBonusCom(searchResults)
												)}
											</Table.Cell>
										)}
										{plusIrregularBonusValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAWageplusIrregularBonusCom(searchResults)
												)}
											</Table.Cell>
										)}
										{plusUnusedAnnualLeavesWageValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAWageplusUnusedAnnualLeavesWageCom(searchResults)
												)}
											</Table.Cell>
										)}
										{plusLastYearUnusedAnnualLeavesWageValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAWageplusLastYearUnusedAnnualLeavesWageCom(
														searchResults
													)
												)}
											</Table.Cell>
										)}
										{plusLastMonthAverWageValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAWageplusLastMonthAverWageCom(searchResults)
												)}
											</Table.Cell>
										)}
										{plusPersonalIncentiveValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAWageplusPersonalIncentiveCom(searchResults)
												)}
											</Table.Cell>
										)}

										{aWEName5Value && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAWage5WagesCom(searchResults)
												)}
											</Table.Cell>
										)}
										{aWEName6Value && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAWage6WagesCom(searchResults)
												)}
											</Table.Cell>
										)}
										{aWEName7Value && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAWage7WagesCom(searchResults)
												)}
											</Table.Cell>
										)}
										{plusRetiredUnusedAnnualLeavesWageValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAWageplusRetiredUnusedAnnualLeavesWageCom(
														searchResults
													)
												)}
											</Table.Cell>
										)}
										{plusSpecialRandomWageValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAWageplusSpecialRandomWageCom(searchResults)
												)}
											</Table.Cell>
										)}
										{daysOfInterInAbusentOrdinaryWageValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfdaysOfInterInAbusentOrdinaryWagesCom(
														searchResults
													) * -1
												)}
											</Table.Cell>
										)}
										{daysOfInterInAbusentLawOverWageValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfdaysOfInterInAbusentLawOverWagesCom(
														searchResults
													) * -1
												)}
											</Table.Cell>
										)}
										{daysOfInterOutAbusentOrdinaryWageValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfdaysOfInterOutAbusentOrdinaryWagesCom(
														searchResults
													) * -1
												)}
											</Table.Cell>
										)}
										{daysOfInterOutAbusentLawOverWageValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfdaysOfInterOutAbusentLawOverWagesCom(
														searchResults
													) * -1
												)}
											</Table.Cell>
										)}

										{absentDaysOrdinaryWageValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAbsentDaysOrdinaryWagesCom(searchResults) * -1
												)}
											</Table.Cell>
										)}

										{absentPaidHoliDaysOrdinaryWageValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAbsentPaidHoliDaysOrdinaryWagesCom(
														searchResults
													) * -1
												)}
											</Table.Cell>
										)}

										{absentDaysLawOverWageValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAbsentDaysLawOverWagesCom(searchResults) * -1
												)}
											</Table.Cell>
										)}
										{absentTimeWageValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAbsentTimeWagesCom(searchResults) * -1
												)}
											</Table.Cell>
										)}

										<Table.Cell>
											{numberToCommaString(sumOfMonthWagesCom(searchResults))}
										</Table.Cell>
										<Table.Cell>
											{numberToCommaString(sumOfCalTaxsSumCom(searchResults))}
										</Table.Cell>
										<Table.Cell>
											{numberToCommaString(
												sumOfCalRegionTaxsSumCom(searchResults)
											)}
										</Table.Cell>
										<Table.Cell>
											{numberToCommaString(
												sumOfCalPensionsSumCom(searchResults)
											)}
										</Table.Cell>
										<Table.Cell>
											{numberToCommaString(
												sumOfCalHealthCaresSumCom(searchResults)
											)}
										</Table.Cell>
										<Table.Cell>
											{numberToCommaString(
												sumOfCalOldCaresSumCom(searchResults)
											)}
										</Table.Cell>
										<Table.Cell>
											{numberToCommaString(
												sumOfCalNoJobInsurancesSumCom(searchResults)
											)}
										</Table.Cell>

										{plusDeductHealthCareValue &&
											sumOfAWageplusDeductHealthCareCom(searchResults) !==
												0 && (
												<Table.Cell>
													{numberToCommaString(
														sumOfAWageplusDeductHealthCareCom(searchResults)
													)}
												</Table.Cell>
											)}
										{plusDeductOldCareValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAWageplusDeductOldCareCom(searchResults)
												)}
											</Table.Cell>
										)}
										{plusDeductWorkTaxValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAWageplusDeductWorkTaxCom(searchResults)
												)}
											</Table.Cell>
										)}
										{plusDeductWorkRegionTaxValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAWageplusDeductWorkRegionTaxCom(searchResults)
												)}
											</Table.Cell>
										)}

										{finalRetireHealthCareRecalFeeValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfFinalRetireHealthCareRecalFeeCom(searchResults)
												)}
											</Table.Cell>
										)}
										{finalRetireOldCareRecalFeeValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfFinalRetireOldCareRecalFeeCom(searchResults)
												)}
											</Table.Cell>
										)}

										{plusDeductWorkerCompanyLoanValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAWageplusDeductWorkerCompanyLoanCom(
														searchResults
													)
												)}
											</Table.Cell>
										)}
										{plusDeductDuruValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAWageplusDeductDuruCom(searchResults)
												)}
											</Table.Cell>
										)}
										{plusMinus4InsValue && (
											<Table.Cell>
												{numberToCommaString(
													sumOfAWageplusMinus4InssCom(searchResults)
												)}
											</Table.Cell>
										)}

										{deductEtcName1Value && (
											<Table.Cell>
												{numberToCommaString(
													sumOfNumDeductEtcAWage1sCom(searchResults)
												)}
											</Table.Cell>
										)}
										{deductEtcName2Value && (
											<Table.Cell>
												{numberToCommaString(
													sumOfNumDeductEtcAWage2sCom(searchResults)
												)}
											</Table.Cell>
										)}
										{deductEtcName3Value && (
											<Table.Cell>
												{numberToCommaString(
													sumOfNumDeductEtcAWage3sCom(searchResults)
												)}
											</Table.Cell>
										)}

										<Table.Cell>
											{numberToCommaString(
												sumOfDedectTaxEtcsSumCom(searchResults)
											)}
										</Table.Cell>
										<Table.Cell>
											{numberToCommaString(
												sumOfMonthDeductedWagesCom(searchResults)
											)}
										</Table.Cell>
									</Table.Row>
								</Table.Body>
							</Table>
						</div>
					)}
				</>
			)}
		</>
	);
}
