import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WorkerRegisterListDashboard from '../../clientsRequest/clientReq4In/clientsReqDashboard4In/WorkerRegisterListDashboard';
import PaydocuShortCut from './PaydocuShortCut';
import {
	clearClientReqs4In,
	fetchClientReqs4In,
} from '../../clientsRequest/clientReq4In/clientReqActions4In';

export default function PaydocuWorkerCodeDashboard() {
	const dispatch = useDispatch();
	const { filter, currentUserProfile, clientReqs4In } = useSelector(
		(state) => ({
			filter: state.companyReducer.filter,
			currentUserProfile: state.profile.currentUserProfile,
			clientReqs4In: state.clientReq4InStore.clientReqs4In,
		})
	);

	const isWorker = currentUserProfile?.isWorker;

	useEffect(() => {
		const fetchData = async () => {
			try {
				await dispatch(fetchClientReqs4In(filter));
			} catch (error) {
				console.error('Error fetching client requests:', error);
			}
		};

		fetchData();

		return () => {
			dispatch(clearClientReqs4In());
		};
	}, [dispatch, filter]);

	if (isWorker) return null;

	const title = '직원 등록 코드';

	return (
		<>
			<PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In} />
			<hr />
			<div className='dashboard-title'>
				<span>{title}</span>
			</div>
			<WorkerRegisterListDashboard
				title={title}
				clientReqs4In={clientReqs4In}
			/>
			<hr />
			<br />
		</>
	);
}
