import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import SortableTable from '../../../../app/common/form/SortableTable';
import {
	numberToCommaString,
	safeStringCompare,
} from '../../../../app/common/util/util';

export default function WorkerTimeInfoList({
	workersList = [], // 기본값 설정
	getNextClientReqs,
	loading,
	moreClientReqs,
	title,
}) {
	// 검색어 상태 추가
	const [searchTerm, setSearchTerm] = useState('');
	// 특정 열이 존재하는지 확인
	const hasExpectWeeklyOverWorks = workersList.some(
		(workerList) => workerList?.realWorkTime?.expectWeeklyOverWorks
	);
	const hasExpectWeeklyNightWorks = workersList.some(
		(workerList) => workerList?.realWorkTime?.expectWeeklyNightWorks
	);
	const headers = [
		{ key: 'workerDivisions', label: '부서' },
		{ key: 'workerTitles', label: '직위/직책' },
		{ key: 'status', label: '직원구분' },
		{ key: 'name', label: '직원성명' },
		{ key: 'workerEnteredDate', label: '입사일' },
		{ key: 'retiredDate', label: '퇴직일' },
		{ key: 'annualSalary', label: '책정 연봉액' },
		{ key: 'monthSalary', label: '책정 월급액' },

		{ key: 'maternityLeaves', label: '출산휴가' },
		{ key: 'childLeaveOfAbsences', label: '육아휴직' },
		{ key: 'childShortTimes', label: '육아근로단축' },
		{ key: 'leaveOfAbsences', label: '기타개인휴직' },

		...(hasExpectWeeklyOverWorks
			? [{ key: 'expectWeeklyOverWorks', label: '1주 약정연장시간' }]
			: []),
		...(hasExpectWeeklyNightWorks
			? [{ key: 'expectWeeklyNightWorks', label: '1주 약정야간시간' }]
			: []),
	];

	const sortFunctions = {
		status: (a, b) => safeStringCompare(a.status, b.status),
		name: (a, b) => safeStringCompare(a.name, b.name),
		workerDivisions: (a, b) =>
			safeStringCompare(a.workerDivisions, b.workerDivisions),
		workerTitles: (a, b) => safeStringCompare(a.workerTitles, b.workerTitles),
		annualSalary: (a, b) => safeStringCompare(a.annualSalary, b.annualSalary),
		monthSalary: (a, b) => safeStringCompare(a.monthSalary, b.monthSalary),
		expectWeeklyOverWorks: (a, b) =>
			safeStringCompare(a.expectWeeklyOverWorks, b.expectWeeklyOverWorks),
		expectWeeklyNightWorks: (a, b) =>
			safeStringCompare(a.expectWeeklyNightWorks, b.expectWeeklyNightWorks),
		maternityLeaves: (a, b) =>
			safeStringCompare(a.maternityLeaves, b.maternityLeaves),
		childLeaveOfAbsences: (a, b) =>
			safeStringCompare(a.childLeaveOfAbsences, b.childLeaveOfAbsences),
		workerEnteredDate: (a, b) =>
			safeStringCompare(a.workerEnteredDate, b.workerEnteredDate),
		retiredDate: (a, b) => safeStringCompare(a.retiredDate, b.retiredDate),
	};

	const data = workersList
		.map((workerList) => {
			let status = '정규직';
			if (workerList?.dayWorker?.daylyPay > 0) {
				status = '일용직';
			} else if (workerList?.dayWorker?.timeWage > 0) {
				status = '시급직';
			} else if (workerList?.isBizWorker) {
				status = '사업소득직';
			} else if (workerList?.contractEndDate) {
				status = '계약직';
			}

			const workerEnteredDate = new Date(workerList?.workerEnteredDate);
			const formattedEnteredDate = workerEnteredDate
				.toISOString()
				.split('T')[0];
			const retiredDate = workerList?.retiredDate;
			const formattedRetiredDate = retiredDate
				? retiredDate.toISOString().split('T')[0]
				: null;

			// 출산휴가 데이터 처리
			const maternityLeaves = workerList?.maternityLeaves
				?.map((leave) => {
					const startDate = new Date(
						leave.maternityLeaveStartDate.seconds * 1000
					)
						.toISOString()
						.split('T')[0];
					const endDate = new Date(leave.maternityLeaveEndDate.seconds * 1000)
						.toISOString()
						.split('T')[0];
					return `(${leave.maternityLeaveType}\n ${startDate} ~ ${endDate} )`;
				})
				.join('\n');

			// 육아휴직 데이터 처리
			const childLeaveOfAbsences = workerList?.childLeaveOfAbsences
				?.map((leave) => {
					const startDate = new Date(
						leave.childLeaveOfAbsenceStartDate.seconds * 1000
					)
						.toISOString()
						.split('T')[0];
					const endDate = new Date(
						leave.childLeaveOfAbsenceEndDate.seconds * 1000
					)
						.toISOString()
						.split('T')[0];
					return `(${leave.childLeaveOfAbsenceType})\n ${startDate} ~ ${endDate} `;
				})
				.join('\n'); // 줄 바꿈으로 조합

			// 육아기 근로시간 단축 데이터 처리
			const childShortTimes = workerList?.childShortTimes
				?.map((leave) => {
					const startDate = new Date(
						leave.childShortTimeStartDate.seconds * 1000
					)
						.toISOString()
						.split('T')[0];
					const endDate = new Date(leave.childShortTimeEndDate.seconds * 1000)
						.toISOString()
						.split('T')[0];
					return `(${leave.childShortTimeType})\n ${startDate} ~ ${endDate} `;
				})
				.join('\n'); // 줄 바꿈으로 조합

			// 육아기 근로시간 단축 데이터 처리
			const leaveOfAbsences = workerList?.leaveOfAbsences
				?.map((leave) => {
					const startDate = new Date(
						leave.leaveOfAbsenceStartDate.seconds * 1000
					)
						.toISOString()
						.split('T')[0];
					const endDate = new Date(leave.leaveOfAbsenceEndDate.seconds * 1000)
						.toISOString()
						.split('T')[0];
					return `(${leave.leaveOfAbsenceType})\n ${startDate} ~ ${endDate} `;
				})
				.join('\n'); // 줄 바꿈으로 조합

			const annualSalary =
				Math.floor((workerList?.totalMonthWageAmount * 12) / 10) * 10 || 0;
			const annualSalaryString = numberToCommaString(annualSalary);
			const monthSalary = numberToCommaString(workerList?.totalMonthWageAmount);

			return {
				status: status,
				workerDivisions: workerList?.workerDivisions || '부서 없음',
				workerTitles: workerList?.workerTitles || '직위 없음',
				name: workerList?.worker?.name,
				workerEnteredDate: formattedEnteredDate,
				retiredDate: formattedRetiredDate,
				annualSalary: annualSalaryString || 0,
				monthSalary: monthSalary || 0,
				expectWeeklyOverWorks:
					workerList?.realWorkTime?.expectWeeklyOverWorks || 0,
				expectWeeklyNightWorks:
					workerList?.realWorkTime?.expectWeeklyNightWorks || 0,
				maternityLeaves: maternityLeaves || '없음',
				childLeaveOfAbsences: childLeaveOfAbsences || '없음',
				childShortTimes: childShortTimes || '없음',
				leaveOfAbsences: leaveOfAbsences || '없음',
			};
		})
		.filter((worker) =>
			worker.name.toLowerCase().includes(searchTerm.toLowerCase())
		);

	const customRenderers = {
		maternityLeaves: (row) => (
			<div style={{ whiteSpace: 'pre-line' }}>{row.maternityLeaves}</div>
		),
		childLeaveOfAbsences: (row) => (
			<div style={{ whiteSpace: 'pre-line' }}>{row.childLeaveOfAbsences}</div>
		),
		childShortTimes: (row) => (
			<div style={{ whiteSpace: 'pre-line' }}>{row.childShortTimes}</div>
		),
		leaveOfAbsences: (row) => (
			<div style={{ whiteSpace: 'pre-line' }}>{row.leaveOfAbsences}</div>
		),
	};

	return (
		<>
			<div>
				<input
					type='text'
					placeholder='직원 이름 검색'
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					style={{ marginBottom: '10px', padding: '5px', width: '200px' }}
				/>
			</div>
			{workersList.length !== 0 ? (
				<InfiniteScroll
					pageStart={0}
					loadMore={getNextClientReqs}
					hasMore={!loading && moreClientReqs}
					initialLoad={false}>
					<br />
					<SortableTable
						headers={headers}
						data={data}
						sortFunctions={sortFunctions}
						title={title}
						customRenderers={customRenderers}
					/>
				</InfiniteScroll>
			) : (
				<div>데이터가 없습니다.</div>
			)}
		</>
	);
}
