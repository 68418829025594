import React, { useState, useMemo, useEffect } from 'react';
import '../Roe1GeneralRules.css'; // CSS 파일 불러오기
import moment from 'moment';

const RuleSection = ({
	number,
	title,
	content,
	isOpen,
	toggleOpen,
	amended,
}) => {
	const formatContent = (content) => {
		if (!content) return null; // content가 undefined일 경우 null 반환

		let mainCount = 0; // 0부터 시작하여 CSS에서 올바르게 매핑될 수 있도록 설정

		return content.map((item, index) => {
			if (Array.isArray(item)) {
				return (
					<ol key={index} type='1'>
						{item.map((subItem, subIndex) => (
							<li
								key={subIndex}
								className='rule-sub-item'
								data-index={subIndex}>
								{subItem}
							</li>
						))}
					</ol>
				);
			} else {
				const formattedItem = item;
				return (
					<li key={index} className='rule-item' data-index={mainCount++}>
						{formattedItem}
					</li>
				);
			}
		});
	};

	return (
		<div>
			<div className='jau__title sectionup' onClick={toggleOpen}>
				{`제${number}조 ${title}`}
			</div>
			{isOpen && (
				<div className='jau__contents'>
					<ul>{formatContent(content)}</ul>
					{amended && (
						<div className='no-print'>
							<ul>
								{amended.map((amendment, i) => (
									<li
										key={i}
										style={{
											color: amendment?.includes('개정') ? 'red' : 'black',
										}}>
										{amendment}
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default function Roe2TimeRules({
	serviceStartDate,
	profile,
	company,
	companyUn,
	companyGa,
	companyWa,
	allOpen,
	previousSectionCount1, // 이전 섹션 수를 전달받음
	setSectionCount,
}) {
	const alDate = moment(new Date(profile.finBaseDate));
	const finBaseDateMonth = moment(
		moment().set({ month: alDate.get('month') })
	).format('MM');
	const alPormotionMonth = +finBaseDateMonth + 6;
	const alPormotionEndMonth = +finBaseDateMonth + 9;
	const finBaseDateDate = moment(
		moment().set({ date: alDate.get('date') })
	).format('DD');
	const alPormotionDate = +finBaseDateDate + 9;

	const sections = useMemo(() => {
		let baseSections = [
			{
				title: `소정근로시간`,
				content: [
					`사원의 소정근로시간은 아래와 같다.`,
					[
						`1일 소정근로시간은 휴게시간을 제외하고 8시간을 원칙으로 하되, 단시간 사원의 경우 1일 8시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
						`1주 소정근로시간은 40시간을 원칙으로 하되, 단시간 사원의 경우 1주 40시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
					],
					`15세 이상 18세 미만인 사원의 소정근로시간은 아래와 같다.`,
					[
						`1일 소정근로시간은 휴게시간을 제외하고 7시간을 원칙으로 하되, 단시간 사원의 경우 1일 7시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
						`1주 소정근로시간은 35시간을 원칙으로 하되, 단시간 사원의 경우 1주 35시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
					],
					`1주 소정근로시간은 월요일의 시업시각부터 기산하고, 1월 소정근로시간은 매월 1일의 시업시각부터 기산한다.`,
					`경영상 필요한 경우 근로계약서 또는 임금 계약서의 작성을 통하여 기존에 제1항 및 제2항의 규정의 적용받는 것과 달리 1일 또는 1주 소정근로시간을 약정할 수 있다.`,
					`고용노동부장관의 승인을 받은 감시적, 단속적 근로에 종사하는 자(경비직, 시설관리직, 운전기사 등) 및 관리, 감독업무 또는 기밀 사무를 취급하는 자는 본 규칙에서 정한 근로시간, 휴게와 휴일에 관한 규정은 적용하지 아니한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `시업시각과 종업시각`,
				content: [
					`시업시각과 종업시각은 사원별로 근로계약서에 명기한다.`,
					`업무개시의 준비는 시업시각 10분 전까지 완료한다.`,
					`업무의 정리정돈은 종업시각 후 10분 이내에 완료한다.`,
					`${companyUn} 제2항의 작업준비시간 및 제3항의 정리정돈 시간 동안 사원에게 업무명령을 내릴 수 없다.`,
					`제1항에도 불구하고 근무지와 근무형태 또는 기타 업무상의 필요에 따라 제1항의 근로시간을 ${company}의 기안 또는 고시에 의하여 변경할 수 있고, 교대제를 실시할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
				amended:
					serviceStartDate < `2024-02-01`
						? [
								`2024년 2월 1일 개정`,
								`변경전 : 제1항에도 불구하고 근무지와 근무형태 또는 기타 업무상의 필요에 따라 제1항의 근로시간을 ${company}의 기안 또는 고시에 의하여 변경할 수 있다.`,
								`변경후 : 제1항에도 불구하고 근무지와 근무형태 또는 기타 업무상의 필요에 따라 제1항의 근로시간을 ${company}의 기안 또는 고시에 의하여 변경할 수 있고, 교대제를 실시할 수 있다.`,
								`개정이유 : 교대제 실시 근거 마련.`,
						  ]
						: null,
			},
			{
				title: `휴게시간`,
				content: [
					`${companyUn} 사원에게 근로시간 4시간에 30분, 8시간에 60분의 휴게시간을 부여하되 구체적인 시각은 사원별 근로계약서에 의한다.`,
					`사원은 ${company}의 규율질서를 지키는 범위 내에서 휴게시간을 자유롭게 이용할 수 있다.`,
					`제1항에도 불구하고 근무지와 근무형태 또는 기타 업무상의 필요에 따라 휴게시간의 길이 및 부여시기를 ${company}의 기안 또는 고시로 변경할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `간주근로시간제`,
				content: [
					`사원이 국내외 출장이나 그 밖의 사유로 근로시간의 전부 또는 일부를 사업장 밖에서 근로하여 근로시간을 산정하기 어려운 경우에는 전조 제1항 또는 근로계약서에 정한 소정근로시간을 근로한 것으로 본다. 다만, 그 업무를 수행하기 위하여 통상적으로 소정근로시간을 초과하여 근로할 필요가 있는 경우에는 그 업무의 수행에 통상 필요한 시간을 근로한 것으로 본다.`,
					`제1항 단서에도 불구하고 그 업무에 관하여 사원대표와의 서면합의를 한 경우에는 그 합의에서 정하는 시간을 그 업무의 수행에 통상 필요한 시간으로 본다.`,
					`전항의 노사 합의에서 정하는 근로시간이 근로기준법 제50조 제1항에서 정한 시간을 초과하는 부분에 대해서는 통상임금의 100분의 50이상의 가산수당을 지급하거나 보상휴가를 부여한다.`,
					`간주근로시간제 적용을 받는 사원이 휴일근로 또는 야간근로를 48시간 이전까지 ${company}에게 신청하여 승인을 득한 경우 또는 ${companyGa} 휴일근로 또는 야간근로를 서면 또는 이메일을 포함한 전자적 방법 등 명시적으로 지시한 경우에는 근로기준법에서 정하는 바에 따른 휴일근로 또는 야간근로 가산수당을 지급한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `탄력적 근로시간제`,
				content: [
					`${companyUn} 본 규정에 따라 2주 이내의 일정한 단위기간을 평균하여 1주 간의 근로시간이 본 규정에 따른 소정근로시간을 초과하지 아니하는 범위에서 특정한 주에 1주의 소정근로시간을, 특정한 날에 1일의 소정근로시간을 초과하여 근로하게 할 수 있다. 다만, 특정한 주의 근로시간은 48시간을 초과할 수 없다.`,
					`${companyUn} 사원대표와의 서면합의에 따라 3개월 이내 또는 3개월 이상 6개월 이내의 단위기간을 평균하여 1주 간의 근로시간이 본 규정에 따른 소정근로시간을 초과하지 아니하는 범위에서 특정한 주에 1주의 소정근로시간을, 특정한 날에 1일의 소정근로시간을 초과하여 근로하게 할 수 있다. 다만, 특정한 주의 근로시간은 52시간을, 특정한 날의 근로시간은 12시간을 초과할 수 없다.`,
					`제1항과 제2항의 탄력적 근로시간제라 하더라도 근로기준법 제53조에 따라 당사자간에 합의에 따라 1주 간에 12시간을 한도로 각각의 근로시간을 초과하게 할 수 있다.`,
					`제1항과 제2항은 15세 이상 18세 미만의 사원과 임신 중인 여성 사원에 대하여는 적용하지 아니한다.`,
					`${companyUn} 제1항 및 제2항의 3개월 이내의 탄력적 근로시간제에 따라 사원을 근로시킬 경우에는 기존의 임금 수준이 낮아지지 아니하도록 임금보전방안(賃金補塡方案)을 강구하여야 한다.`,
					`${companyUn} 제2항의 3개월 이상 6개월이하의 탄력적 근로시간제에 따라 사원을 근로시킬 경우에는 기존의 임금 수준이 낮아지지 아니하도록 임금항목을 조정 또는 신설하거나 가산임금 지급 등의 임금보전방안(賃金補塡方案)을 마련하여 고용노동부장관에게 신고하여야 한다. 다만, 사원대표와의 서면합의로 임금보전방안을 마련한 경우에는 그러하지 아니하다.`,
					`${companyUn} 제1항과 제2항에 따른 단위기간 중 사원이 근로한 기간이 그 단위기간보다 짧은 경우에는 그 단위기간 중 해당 사원이 근로한 기간을 평균하여 1주간에 40시간을 초과하여 근로한 시간 전부에 대하여 가산임금을 지급(또는 보상휴가 부여)하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `선택적 근로시간제`,
				content: [
					`${companyUn} 업무의 시작 및 종료 시각을 사원의 결정에 맡기기로 한 자에 대하여 사원대표와 서면으로 합의한 내용에 따라 근로기준법 제52조에 따른 선택적근로시간제를 시행할 수 있다.`,
					`제1항에 따른 선택적 근로시간제에 관하여 ${companyGa} 사원대표와 서면으로 합의하여야 하는 내용은 다음 각 호와 같다.`,
					[
						`대상사원의 범위`,
						`정산기간`,
						`정산기간의 총 근로시간`,
						`반드시 근로하여야 할 시간대를 정하는 경우에는 그 시작 및 종료시각`,
						`사원이 그의 결정에 따라 근로할 수 있는 시간대를 정하는 경우에는 그 시각 및 종료시각`,
						`표준근로시간(유급휴가 등의 계산기준으로 ${companyWa} 사원대표가 합의하여 정한 1일의 근로시간)`,
						`신상품 또는 신기술의 연구개발 업무로 3개월 이내의 선택적 근로시간제인 경우 근로일 종료 후 다음 근로일 시작 전까지 사원에게 연속하여 11시간 이상의 휴식 시간을 부여할 것을 정하되, 「재난 및 안전관리 기본법」에 따른 재난 또는 이에 준하는 사고가 발생하여 이를 수습하거나 재난 등의 발생이 예상되어 이를 예방하기 위해 긴급한 조치가 필요한 경우와 사람의 생명을 보호하거나 안전을 확보하기 위해 긴급한 조치가 필요한 경우 등은 근로일 종료 후 다음 근로일 시작 전까지 사원에게 연속하여 11시간 이상의 휴식 시간을 부여하지 않아도 된다는 내용.`,
					],
					`${companyGa} 선택적 근로시간제를 시행하는 경우에는 1개월(신상품 또는 신기술의 연구개발 업무의 경우는 3개월) 이내의 정산기간을 평균하여 1주간의 근로시간이 40시간을 초과하지 아니하는 범위에서 1주에 40시간, 1일에 8시간을 초과하여 근로하게 할 수 있다.`,
					`1개월 이내의 정산기간을 평균한 1주간의 근로시간이 40시간을 초과하지 않는 경우, 특정한 날 또는 주에 법정근로시간을 초과한 시간에 대하여는 가산수당을 지급하지 아니한다. 다만, 신상품 또는 신기술의 연구개발 업무로 3개월 이내의 선택적 근로시간제인 경우에는 매 1개월마다 평균하여 1주간의 근로시간이 제5조의 1주 소정근로 시간을 초과한 시간에 대해서는 통상임금의 100분의 50 이상을 가산하여 사원에게 지급해야 한다.`,
					`선택적근로시간제를 적용받는 사원이라 하더라도 소정근로일에 대한 출퇴근의무를 부담하므로 출ㆍ퇴근을 기록해야 한다.`,
					`본 조항은 15세이상 18세 미만의 사원에 대하여는 적용하지 아니한다.`,
					`유연근무제 대상이 아닌 통상 근로자의 지각, 조퇴, 외출 그리고 그들의 근태와 관련된 징계 조항을 본 조항의 선택적 근로시간제 대상 사원에게 적용할 때는, 선택적 근로 대상 사원의 의무적 근로시간대를 통상 사원의 출퇴근 시간으로 보고 적용 여부를 판단한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `재량근로제`,
				content: [
					`업무의 성질에 비추어 업무수행방법을 사원의 재량에 위임할 필요가 있는 업무로서, 다음 각 호의 업무는 ${companyGa} 사원의 대표와 서면합의로 정한 시간을 근로한 것으로 본다.`,
					[
						`신상품 또는 신기술의 연구개발이나 인문사회과학 또는 자연과학분야의 연구 업무`,
						`정보처리시스템의 설계 또는 분석 업무`,
						`신문, 방송 또는 출판 사업에서의 기사의 취재, 편성 또는 편집 업무`,
						`의복ㆍ실내장식ㆍ공업제품ㆍ광고 등의 디자인 또는 고안 업무`,
						`방송 프로그램ㆍ영화 등의 제작 사업에서의 프로듀서나 감독 업무`,
						`회계ㆍ법률사건ㆍ납세ㆍ법무ㆍ노무관리ㆍ특허ㆍ감정평가ㆍ금융투자분석ㆍ투자자산운용 등의 사무에 있어 타인의 위임ㆍ위촉을 받아 상담ㆍ조언ㆍ감정 또는 대행을 하는 업무`,
					],
					`제1항에 따른 업무를 재량근로제도 도입하기 위해서는 서면합의에 다음 각 호의 사항을 명시하여야 한다.`,
					[
						`대상업무`,
						`${companyGa} 업무의 수행 수단 및 시간 배분 등에 관하여 사원에게 구체적인 지시를 하지 아니한다는 내용`,
						`근로시간의 산정은 그 서면합의로 정하는 바에 따른다는 내용`,
					],
					`재량근무제 사원에 대해서는 ${companyWa} 사원대표 간 전항의 서면합의에서 정하는 근로시간을 근로한 것으로 본다.`,
					`전항의 노사 합의에서 정하는 근로시간이 근로기준법 제50조 제1항에서 정한 시간을 초과하는 부분에 대해서는 통상임금의 100분의 50이상의 가산수당을 지급한다.`,
					`재량근로제 사원도 소정근무일에 출퇴근 의무를 부담하되, 구체적인 시간배분을 사원 본인의 자율로 결정하는 것으로 한다.`,
					`재량사원이 휴일근로 또는 야간근로를 48시간 이전까지 ${company}에게 신청하여 승인받은 경우 또는 ${companyGa} 휴일근로 또는 야간근로를 서면 또는 이메일을 포함한 전자적 방법 등 명시적으로 지시한 경우에는 근로기준법에서 정하는 바에 따른 가산수당을 지급한다.`,
					`재량사원의 경우에도 장시간근로 차단 등 건강보호, 연차휴가 산정 등을 위한 복무관리를 위하여 ${companyGa} 요구하는 경우 재량근로제 사원도 출ㆍ퇴근시간을 기록할 의무가 있다.`,
					`${companyUn} 재량사원의 재량을 제한하지 않는 범위 내에서 일정 단계에서의 업무 진행상황, 경과 등을 보고하도록 할 수 있다.`,
					`${companyUn} 재량사원에게 업무 진행상황의 확인, 정보공유, 협업 등을 위하여 회의에 참석하도록 지시할 수 있고, 긴급업무가 발생하는 경우 또는 업무의 완성을 위해 필요한 경우 회의참석, 출장 등을 지시할 수 있다.`,
					`유연근무제 대상이 아닌 통상 근로자의 지각, 조퇴, 외출 그리고 그들 근태와 관련된 징계 조항은 본 조항의 재량 근로시간제 대상 사원에게 적용하지 아니한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `연장근로`,
				content: [
					`사원과 ${companyUn} 근로계약서나 임금계약서의 작성 또는 변경 기타 약정을 통하여 소정근로시간을 초과하는 1주 12시간 한도의 연장근로를 합의할 수 있다.`,
					`다음 각 호의 근로시간은 연장근로로 본다.`,
					[
						`1일 소정근로시간을 초과한 근로시간`,
						`1주 소정근로시간을 초과하여 근무한 시간`,
					],
					`연장근로에 대한 법정수당 또는 보상휴가는 해당사원에게만 인정된다.`,
					`${companyUn} 사원대표와 서면합의에 의하여 연장근로에 대하여 임금을 지급하는 것에 갈음하여 보상휴가를 부여할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `야간근로`,
				content: [
					`${companyUn} 근로계약서나 임금계약서의 작성 또는 개별 약정을 통하여 야간시간인 오후 22시 이후 오전 06시 이전의 시간을 근로시간의 범위에 포함(이하 "야간근로"라 함.)시킬 수 있고, 업무 필요에 따라 동 시간대의 야간근무를 명할 수 있다.`,
					`야간근로에 대한 법정수당 또는 보상휴가는 해당사원에게만 인정된다.`,
					`${companyUn} 사원대표와 서면합의에 의하여 야간근로에 대하여 임금을 지급하는 것에 갈음하여 보상휴가를 부여할 수 있다`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `휴일근로`,
				content: [
					`${companyUn} 근로계약서나 임금계약서의 작성 및 개별적인 협의 또는 합의(18세 이상 여성 사원인 경우)를 통하여 다음 각 호의 휴일에 근로할 수 있다.`,
					[`주휴일(일요일)`, `근로자의날(5월 1일)`, `본 규칙의 공휴일`],
					`휴일근로에 대한 법정 수당과 사원대표와 서면합의에 의한 보상휴가는 해당 사원에게만 지급한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `주휴일 및 근로자의날`,
				content: [
					`${companyUn} 1주 소정근로시간을 개근한 사원에게 일요일을 유급의 주휴일로 부여한다.`,
					`${companyUn} 1주 소정근로일 중 일부를 결근한 사원에게 일요일을 무급의 주휴일로 부여한다.`,
					`1주 소정근로시간은 월요일부터 기산한다.`,
					`${companyUn} 모든 사원에게 근로자의 날(5월 1일)을 유급의 휴일로 부여한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `공휴일`,
				content: [
					`${companyUn} 모든 사원에게 근로기준법 제55조 제2항 및 시행령 제30조에 따른 휴일(일요일을 제외한 국공휴일과 대체휴일)을 유급의 휴일로 부여한다.`,
					`제1항에 따른 휴일은 사원대표와의 서면합의로 특정한 근무일로 대체할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `근로시간 및 휴게, 휴일의 적용제외`,
				content:
					company === `주식회사 세종씨이엠`
						? [
								`${companyUn} 근로기준법 제63조(적용의 제외) 조항에 따라 다음 각 호의 하나에 해당하는 사원에 대하여는 소정근로시간을 초과하여 연장근로하거나 휴일에 근로하더라도 연장근로 및 휴일근로 가산임금을 지급하지 않고, 휴게시간을 4시간에 30분, 8시간에 60분 미만으로 적게 부여하거나 부여하지 아니할 수 있다.`,
								[
									`감시(監視) 또는 단속적(斷續的)으로 근로에 종사하는 사람으로서 사용자가 고용노동부장관의 승인을 받은 사람`,
									`사업의 종류에 관계없이 관리ㆍ감독 업무 또는 기밀을 취급하는 업무를 하는 사람`,
								],
								`제1항의 각 호에 해당하는 사원이 야간(오후 10시부터 다음 날 오전 6시 사이)에 근로한 경우 통상임금의 50%를 가산 하여 지급한다.`,
								`기타 필요한 사항은 별도로 정할 수 있다.`,
						  ]
						: [
								`${companyUn} 근로기준법 제63조(적용의 제외) 조항에 따라 다음 각 호의 하나에 해당하는 사원에 대하여는 소정근로시간을 초과하여 연장근로하거나 휴일에 근로하더라도 연장근로 및 휴일근로 가산임금을 지급하지 않고, 휴게시간을 4시간에 30분, 8시간에 60분 미만으로 적게 부여하거나 부여하지 아니할 수 있다.`,
								[
									`토지의 경작ㆍ개간, 식물의 식재(植栽)ㆍ재배ㆍ채취 사업, 그 밖의 농림 사업`,
									`동물의 사육, 수산 동식물의 채취ㆍ포획ㆍ양식 사업, 그 밖의 축산, 양잠, 수산 사업`,
									`감시(監視) 또는 단속적(斷續的)으로 근로에 종사하는 사람으로서 사용자가 고용노동부장관의 승인을 받은 사람`,
									`사업의 종류에 관계없이 관리ㆍ감독 업무 또는 기밀을 취급하는 업무를 하는 사람`,
								],
								`제1항의 각 호에 해당하는 사원이 야간(오후 10시부터 다음 날 오전 6시 사이)에 근로한 경우 통상임금의 50%를 가산 하여 지급한다.`,
								`기타 필요한 사항은 별도로 정할 수 있다.`,
						  ],
				amended:
					serviceStartDate >= `2024-02-01`
						? []
						: [
								`2024년 2월 1일 개정`,
								`변경전 : 해당사항 없음`,
								`변경후 : 조항전체 신설`,
								`개정이유 : 근로기준법 제63조 적용제외 규정 마련.`,
						  ],
			},
			{
				title: `공민권 행사`,
				content: [
					`${companyUn} 사원이 근무시간 중에 선거권 기타 공민권을 행사하거나 공적인 직무를 집행하기 위하여 필요한 시간을 청구하는 경우에는 필요한 시간을 부여한다.`,
					`${companyUn} 제1항의 청구한 시간이 업무상 지장이 있는 경우에는 권리행사를 방해하지 아니하는 한도 내에서 그 시각을 변경할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `연차유급휴가`,
				content: [
					profile.annualLeaves === `회계년도기준` ||
					profile.annualLeaves === `특정일(회계년도 등)기준`
						? `${companyUn} 회계년도(매년 ${finBaseDateMonth}월 ${finBaseDateDate}일, 매월 ${finBaseDateDate}일) 기준으로 연차유급휴가(년 단위, 월 단위 연차)를 부여한다.`
						: `${companyUn} 사원의 입사일을 기준으로 연차유급휴가(년 단위, 월 단위 연차)를 부여한다.`,
					`${companyUn} 제1항의 기산일을 기준으로 법정휴일, 소정휴일을 제외한 전년도 1년간 소정근로일수 중 출근한 날이 8할 이상인 사원에게 15일의 연차유급휴가를 부여한다.`,
					`${companyUn} 제1항의 기산일을 기준으로 약정육아휴직, 본 규정에 의한 법정휴가를 초과하는 1주일간의 업무외 부상 질병 휴직, 사원 개인 건강 휴직 등 ${company}의 승인받아 근로제공의무가 정지된 날이 속한 경우는 근로제공의무가 정지된 날을 제외한 전년도 1년간 소정근로일수 중 출근한 날이 8할 이상인 사원에게는 15일의 연차휴가를 모두 부여하지 아니하고 해당 공제 일수만큼 비율을 공제한 갯수의 연차유급휴가를 부여한다.`,
					`${companyUn} 제1항의 기산일을 기준으로 계속하여 1년 미만인 사원 또는 1년간 80퍼센트 미만 출근한 사원에게 1개월 개근시 1일의 연차유급휴가를 부여한다.`,
					`${companyUn} 제1항의 기산일을 기준으로 3년 이상 계속 근로한 사원에게 제2항의 규정에 의한 연차유급휴가에 최초 1년을 초과하는 계속 근로 연수 매 2년에 대하여 1일을 가산한 연차유급휴가를 부여한다.`,
					`제2항 내지 제5항에 의하여 발생된 총 연차유급휴가의 일수는 25일을 초과할 수 없다.`,
					`제2항부터 제5항까지의 규정을 적용하는 경우 다음 각 호의 어느 하나에 해당하는 기간은 출근한 것으로 본다.`,
					[
						`업무상 재해로 인한 휴업기간`,
						`임산부보호휴가기간 또는 유산∙사산보호휴가기간`,
						`예비군훈련기간`,
						`민방위훈련 또는 동원기간`,
						`공민권행사를 위한 휴무일`,
						`연차유급휴가기간`,
						`생리휴가기간`,
						`육아휴직기간`,
					],
					`${companyUn} 사원이 연차휴가를 사용하기 최소 1주일전까지 신청한 연차에 대해 청구한 시기에 연차휴가를 부여하는 것을 원칙으로 하되 사원의 직속상관이 공식적으로 인정하는 경우 연차휴가를 사용하기 3일전까지 신청한 연차에 대해서도 청구한 시기에 연차휴가를 부여하고, 동 기간에 대해 통상임금을 지급하는 것을 원칙으로 하되, 동 기간에 약정연장, 약정야간, 약정휴일근로 수당이 포함된 경우에는 해당 금액을 포함하여 지급할 수 있다.`,
					`제8항에 따라 사원이 청구한 시기에 연차휴가를 부여하는 것이 사업상 막대한 지장이 있을 경우에 그 시기를 변경할 수 있다.`,
					profile.annualLeaves === `회계년도기준` ||
					profile.annualLeaves === `특정일(회계년도 등)기준`
						? `제2항 ~ 제5항에 따른 휴가는 제1항의 기산일을 기준으로 1년간(계속하여 근로한 기간이 1년 미만인 사원의 제3항에 따른 연차유급휴가는 최초 1년의 근로가 끝날 때까지의 기간을 말한다. 다만 회사의 연차부여 단위가 회계년도 기준이므로 2년차에 발생한 제3항 연차는 회계년도 말까지의 기간을 말한다.) 행사하지 아니하면 소멸된다. 다만, ${company}의 귀책사유로 사용하지 못한 경우에는 그러하지 아니하다.`
						: `제2항 ~ 제5항에 따른 휴가는 제1항의 기산일을 기준으로 1년간(계속하여 근로한 기간이 1년 미만인 사원의 제3항에 따른 연차유급휴가는 최초 1년의 근로가 끝날 때까지의 기간을 말한다) 행사하지 아니하면 소멸된다. 다만, ${company}의 귀책사유로 사용하지 못한 경우에는 그러하지 아니하다.`,
					`제10항에 따라 소멸한 연차유급휴가일수 중 제14조의 연차사용촉진이 되지 않은 미사용 연차일수는 연차유급휴가미사용 수당(통상임금)으로 전환되고 소멸일이 속한 월의 다음달의 임금지급일에 지급한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
				amended:
					serviceStartDate >= `2021-11-19`
						? null
						: [
								`2021년 11월24일 개정`,
								`변경전 : ⑧ ${companyUn} 사원이 신청한 연차에 대해 청구한 시기에 연차휴가를 부여하는 것을 원칙으로 하고, 동 기간에 대해 통상임금을 지급하는 것을 원칙으로 하되, 동 기간에 약정연장, 약정야간, 약정휴일근로 수당이 포함된 경우에는 해당 금액을 포함하여 지급할 수 있다.`,
								`변경후 : ⑧ ${companyUn} 사원이 연차휴가를 사용하기 최소 1주일전까지 신청한 연차에 대해 청구한 시기에 연차휴가를 부여하는 것을 원칙으로 하되 사원의 직속상관이 공식적으로 인정하는 경우 연차휴가를 사용하기 3일전까지 신청한 연차에 대해서도 청구한 시기에 연차휴가를 부여하고, 동 기간에 대해 통상임금을 지급하는 것을 원칙으로 하되, 동 기간에 약정연장, 약정야간, 약정휴일근로 수당이 포함된 경우에는 해당 금액을 포함하여 지급할 수 있다.`,
								`개정이유 : 연차사용 신청시기 관련 분쟁 예방을 위해 규정 명확화`,
						  ],
			},
			{
				title: `연차사용촉진 및 연차사용대체`,
				content: [
					`${companyGa} 연차유급휴가의 사용을 촉진하기 위하여 다음 각 호의 조치를 하였음에도 불구하고 사원이 전조 제2항 내지 제4항(계속하여 근로한 기간이 1년미만인 자는 2항 적용)의 연차휴가를 사용하지 아니하여 소멸된 경우에는 ${companyUn} 그 사용하지 아니한 휴가에 대하여 보상할 의무가 없고, 같은 항 단서에 따른 ${company}의 귀책사유에 해당하지 아니하는 것으로 본다.`,
					profile.annualLeaves === `회계년도기준` ||
					profile.annualLeaves === `특정일(회계년도 등)기준`
						? [
								`매년 ${alPormotionMonth}월 ${finBaseDateDate}일부터 ${alPormotionMonth}월 ${alPormotionDate}일 사이에 ${companyGa} 사원별로 사용하지 아니한 휴가 일수를 알려주고, 사원이 그 사용 시기를 정하여 ${company}에게 통보하도록 서면으로 촉구할 것.`,
								`제1호에 따른 촉구에도 불구하고 사원이 촉구를 받은 때부터 10일 이내에 사용하지 아니한 휴가의 전부 또는 일부의 사용 시기를 정하여 ${company}에게 통보하지 아니하면 매년 ${alPormotionEndMonth}월 말일까지 ${companyUn} 사용하지 아니한 휴가의 사용 시기를 정하여 사원에게 서면으로 통보할 것.`,
						  ]
						: [
								`전조 제9항 본문에 따른 기간이 끝나기 6개월 전을 기준으로 10일 이내에 ${companyGa} 사원별로 사용하지 아니한 휴가 일수를 알려주고, 사원이 그 사용 시기를 정하여 ${company}에게 통보하도록 서면으로 촉구할 것.`,
								`제1호에 따른 촉구에도 불구하고 사원이 촉구를 받은 때부터 10일 이내에 사용하지 아니한 휴가의 전부 또는 일부의 사용 시기를 정하여 ${company}에게 통보하지 아니하면 전조 제9항 본문에 따른 기간이 끝나기 2개월 전까지 ${companyUn} 사용하지 아니한 휴가의 사용 시기를 정하여 사원에게 서면으로 통보할 것.`,
						  ],
					`${companyGa} 계속하여 근로한 기간이 1년 미만인 사원의 전조 제3항에 따른 유급휴가의 사용을 촉진하기 위하여 다음 각 호의 조치를 하였음에도 불구하고 사원이 휴가를 사용하지 아니하여 전조 제9항 본문에 따라 소멸된 경우에는 ${companyUn} 그 사용하지 아니한 휴가에 대하여 보상할 의무가 없고, 같은 항 단서에 따른 ${company}의 귀책사유에 해당하지 아니하는 것으로 본다.`,
					[
						`최초 1년의 근로기간이 끝나기 3개월 전을 기준으로 10일 이내에 ${companyGa} 사원별로 사용하지 아니한 휴가 일수를 알려주고, 사원이 그 사용 시기를 정하여 ${company}에게 통보하도록 서면으로 촉구할 것. 다만, ${companyGa} 서면 촉구한 후 발생한 휴가에 대해서는 최초 1년의 근로기간이 끝나기 1개월 전을 기준으로 5일 이내에 촉구하여야 한다.`,
						`제1호에 따른 촉구에도 불구하고 사원이 촉구를 받은 때부터 10일 이내에 사용하지 아니한 휴가의 전부 또는 일부의 사용 시기를 정하여 ${company}에게 통보하지 아니하면 최초 1년의 근로기간이 끝나기 1개월 전까지 ${companyGa} 사용하지 아니한 휴가의 사용 시기를 정하여 사원에게 서면으로 통보할 것. 다만, 제1호 단서에 따라 촉구한 휴가에 대해서는 최초 1년의 근로기간이 끝나기 10일 전까지 서면으로 통보하여야 한다.`,
					],
					`${companyUn} 사원대표와의 서면합의에 따라 연차유급휴가일을 갈음하여 특정 근로일에 사원을 휴무시킬 수 있다.`,
					`사원이 전초의 근로자대표와의 서면합의에 의거 소정근로일(예시, 월요일 ~ 금요일)에 발생한 다음 각 호의 휴가를 사용한 경우 연차유급휴가를 사용한 것으로 본다.`,
					[`하계휴가`, `업무외 부상 질병 휴가`],
					profile.annualLeaves === `회계년도기준` ||
					profile.annualLeaves === `특정일(회계년도 등)기준`
						? [
								`회계년도 단위로 퇴직시점에서 부여된 총 연차유급휴가일수가 사원의 입사일을 기준으로 산정한 연차유급휴가일수에 미달하는 경우에는 그 미달하는 연차유급휴가일수를 연차유급휴가근로수당으로 정산해야 한다.`,
								`제5항과 반대로 회계년도 단위로 퇴직시점에서 부여된 총 연차유급휴가일수가 사원의 입사일을 기준으로 산정한 연차유급휴가일수보다 많은 경우 초과 발생한 연차일수 만큼 사원에게 퇴직시 지급되는 임금 및 퇴직금, 기타금품에서 공제하는 것을 원칙으로 하되, ${companyUn} 공제하지 아니할 수도 있다.`,
								`기타 필요한 사항은 별도로 정할 수 있다.`,
						  ]
						: `기타 필요한 사항은 별도로 정할 수 있다.`,
				],
				amended:
					serviceStartDate >= `2024-01-27`
						? null
						: [
								`2024년 1월27일 개정`,
								`변경전 : ④ 사원이 소정근로일(예시, 월요일 ~ 금요일)에 발생한 다음 각 호의 휴가를 사용한 경우 연차유급휴가를 사용한 것으로 본다.`,
								`변경후 : ④ 사원이 전조의 근로자대표와의 서면합의에 의거 소정근로일(예시, 월요일 ~ 금요일)에 발생한 다음 각 호의 휴가를 사용한 경우 연차유급휴가를 사용한 것으로 본다.`,
								`개정이유 : 하계휴가 및 업무외 부상 질병 휴가의 경우도 특정 소정근로일일 수는 있지만, 연차유급휴가로 대체하기 위해서는 근로자대표와의 서면합의가 필요함.`,
						  ],
			},
			{
				title: `임산부의 보호`,
				content: [
					`${companyUn} 임신 중의 여성 사원에게 출산 전과 출산 후를 통하여 90일(한 번에 둘 이상 자녀를 임신한 경우에는 120일)의 출산전후휴가를 주어야 한다. 이 경우 휴가 기간의 배정은 출산 후에 45일(한 번에 둘 이상 자녀를 임신한 경우에는 60일) 이상이 되어야 한다.`,
					`${companyUn} 임신 중인 여성 사원이 유산의 경험 등 다음 각 호로 정하는 사유로 제1항의 휴가를 청구하는 경우 출산 전 어느 때라도 휴가를 나누어 사용할 수 있도록 하여야 한다. 이 경우 출산 후의 휴가 기간은 연속하여 45일(한 번에 둘 이상 자녀를 임신한 경우에는 60일) 이상이 되어야 한다.`,
					[
						`임신한 사원에게 유산ㆍ사산의 경험이 있는 경우`,
						`임신한 사원이 출산전후휴가를 청구할 당시 연령이 만 40세 이상인 경우`,
						`임신한 사원이 유산ㆍ사산의 위험이 있다는 의료기관의 진단서를 제출한 경우`,
					],
					`${companyUn} 임신 중인 여성 사원이 유산 또는 사산한 경우로서 그 사원이 유산 또는 사산한 사원이 휴가 청구 사유, 유산ㆍ사산 발생일 및 임신기간 등을 적은 유산ㆍ사산휴가 신청서에 의료기관의 진단서를 첨부하여 ${companyUn}에게 청구하면 다음 각 호로 정하는 바에 따라 유산ㆍ사산 휴가를 주어야 한다. 다만, 인공 임신중절 수술(「모자보건법」제14조제1항에 따른 경우는 제외한다)에 따른 유산의 경우는 그러하지 아니하다.`,
					[
						`임신기간이 11주 이내인 경우: 유산 또는 사산한 날부터 5일까지`,
						`임신기간이 12주 이상 15주 이내인 경우: 유산 또는 사산한 날부터 10일까지`,
						`임신기간이 16주 이상 21주 이내인 경우: 유산 또는 사산한 날부터 30일까지`,
						`임신기간이 22주 이상 27주 이내인 경우: 유산 또는 사산한 날부터 60일까지`,
						`임신기간이 28주 이상인 경우: 유산 또는 사산한 날부터 90일까지`,
					],
					`제1항부터 제3항까지의 규정에 따른 휴가 중 최초 60일(한 번에 둘 이상 자녀를 임신한 경우에는 75일)은 유급(통상임금)으로 한다. 다만, 「남녀고용평등과 일ㆍ가정 양립 지원에 관한 법률」제18조에 따라 출산전후휴가급여 등이 지급된 경우에는 그 금액의 한도에서 지급의 책임을 면한다.`,
					`${companyUn} 임신 중의 여성 사원에게 시간외근로를 하게 하여서는 아니 되며, 그 사원의 요구가 있는 경우에는 쉬운 종류의 근로로 전환하여야 한다.`,
					`${companyUn} 제1항에 따른 출산전후휴가 종료 후에는 휴가 전과 동일한 업무 또는 동등한 수준의 임금을 지급하는 직무에 복귀시켜야 한다.`,
					`${companyUn} 임신 후 12주 이내 또는 36주 이후에 있는 여성 사원이 1일 2시간의 근로시간 단축을 신청하는 경우 이를 허용하여야 한다. 다만, 1일 근로시간이 8시간 미만인 사원에 대하여는 1일 근로시간이 6시간이 되도록 근로시간 단축을 허용할 수 있다.`,
					`${companyUn} 제7항에 따른 근로시간 단축을 이유로 해당 사원의 임금을 삭감하여서는 아니 된다.`,
					`제8항에 따라 근로시간 단축을 신청하려는 여성 사원은 근로시간 단축 개시 예정일의 3일 전까지 임신기간, 근로시간 단축 개시 예정일 및 종료 예정일, 근무 개시 시각 및 종료 시각 등을 적은 문서(전자문서를 포함한다)에 의사의 진단서(같은 임신에 대하여 근로시간 단축을 다시 신청하는 경우는 제외한다)를 첨부하여 ${companyUn}에게 제출하여야 한다.`,
					`${companyUn} 임신 중인 여성 사원이 1일 소정근로시간을 유지하면서 업무의 시작 및 종료 시각의 변경을 신청하는 경우 이를 허용하여야 한다. 다만, 정상적인 사업 운영에 중대한 지장을 초래하는 경우 등 본 조항 제12조에서 정하는 경우에는 그러하지 아니하다.`,
					`제10조의 본문에 따라 업무의 시작 및 종료 시각의 변경을 신청하려는 여성 사원은 그 변경 예정일의 3일 전까지 임신기간, 업무의 시작 및 종료 시각의 변경 예정 기간, 업무의 시작 및 종료 시각 등을 적은 문서(전자문서를 포함한다)에 임신 사실을 증명하는 의사의 진단서(같은 임신에 대해 업무의 시작 및 종료 시각 변경을 다시 신청하는 경우는 제외한다)를 첨부하여 ${companyUn}에게 제출해야 한다.`,
					`제10조의 단서의 “정상적인 사업 운영에 중대한 지장을 초래하는 경우”란 다음 각 호의 어느 하나에 해당하는 경우를 말한다.`,
					[
						`정상적인 사업 운영에 중대한 지장을 초래하는 경우`,
						`업무의 시작 및 종료 시각을 변경하게 되면 임신 중인 여성 사원의 안전과 건강에 관한 관계 법령을 위반하게 되는 경우`,
					],
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `태아검진 시간의 허용`,
				content: [
					`${companyUn} 임신한 여성 사원이 다음 각 호에 따른 임산부 정기건강진단을 받는데 필요한 시간을 청구하는 경우 이를 부여한다.`,
					[
						`임신 28주까지: 4주마다 1회`,
						`임신 29주에서 36주까지: 2주마다 1회`,
						`임신 37주 이후: 1주마다 1회`,
						`임신한 여성 사원이 「장애인복지법」에 따른 장애인인 경우, 만 35세 이상인 경우, 다태아를 임신한 경우 또는 의사가 고위험 임신으로 판단한 경우에는 1호부터 3호까지에 따른 건강진단 횟수를 넘어 건강진단을 실시할 수 있다.`,
					],
					`전항의 규정에 의한 정기건강진단에 필요한 시간은 유급(통상임금)으로 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `난임치료휴가의 부여`,
				content: [
					`${companyUn} 난임치료(인공수정 또는 체외수정 등)를 위하여 난임치료휴가를 청구한 사원에게 연간 3일 이내의 난임치료휴가를 부여하여야 한다. 이 경우 최초 1일은 유급으로 한다.`,
					`사원이 청구한 시기에 난임치료휴가를 부여하는 것이 정상적인 사업 운영에 중대한 지장을 초래한 경우에는 사원과 협의하여 그 시기를 변경할 수 있다.`,
					`${companyUn} 난임치료휴가를 이유로 해고, 징계 등 불리한 처우를 하지 아니한다.`,
					`${companyUn} 난임치료휴가를 신청한 사원이 난임치료를 받을 사실을 증명할 수 있는 서류의 제출을 요구할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `배우자 출산 휴가`,
				content: [
					`${companyUn} 사원이 배우자의 출산을 이유로 휴가를 청구하는 경우에 10일(유급)의 배우자출산휴가를 부여하여야 한다.`,
					`사원은 배우자 출산휴가를 1회 분할하여 사용할 수 있다.`,
					`제1항에 따른 휴가는 사원의 배우자가 출산한 날부터 90일이 지나면 청구할 수 없다.`,
					`${companyUn} 배우자 출산휴가를 이유로 사원을 해고하거나 그 밖의 불리한 처우를 하여서는 아니 된다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
				amended:
					serviceStartDate >= `2024-01-27`
						? null
						: [
								`2024년 1월27일 개정`,
								`변경전 : ${companyUn} 사원이 배우자의 출산을 이유로 휴가를 청구하는 경우에 10일의 배우자출산휴가를 부여하여야 한다.`,
								`변경후 : ${companyUn} 사원이 배우자의 출산을 이유로 휴가를 청구하는 경우에 10일(유급)의 배우자출산휴가를 부여하여야 한다.`,
								`개정이유 : 유급을 명확히 표시함.`,
						  ],
			},
			{
				title: `생리휴가`,
				content: [
					`여자 사원은 사실상의 생리현상이 있는 날에 생리휴가를 서면으로 청구할 수 있다.`,
					`${companyUn} 여자 사원에게 월 1일의 무급생리휴가를 생리휴가의 청구가 있는 날에 부여한다. 단, 생리현상이 없는 여성 사원 및 임신 중의 여자 사원에게는 생리 휴가를 부여하지 않는다.`,
					`생리휴가의 사용일은 주휴일, 연차유급휴가 등을 부여하기 위한 소정근로일수 및 출근율 산정시 소정근로일수에 포함하고, 그 날은 출근한 것으로 본다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `보상휴가`,
				content: [
					`${companyUn} 사원대표와의 서면합의에 따라 보상휴가 제도를 설정할 수 있고, 이 경우 보상휴가를 산정하기 위한 1개월간 소정근로일수는 매월 1일부터 기산한다.`,
					`${companyUn} 다음 각 호의 임금과 동등한 가치의 보상휴가를 부여한다.`,
					[
						`연장근로에 대한 정상임금과 가산수당`,
						`휴일근로에 대한 정상임금과 가산수당.`,
						`야간근로에 대한 가산수당`,
					],
					`연장근로와 야간근로 및 휴일근로가 중복된 경우에는 각각 기산하여 보상휴가를 부여한다.`,
					`${companyGa} 보상휴가를 부여할 때 정상임금에 대한 보상휴가와 가산수당에 대한 보상휴가를 분리하는 등 일정 부분은 임금, 나머지 부분은 보상휴가로 구분하여 보상할 수 있다.`,
					`본 조에 따른 보상휴가는 회계연도 단위로 발생시키고 다음 연도 말 내로 정산한다.`,
					`기타 필요한 사항은 사원대표와의 서면합의로 별도로 정할 수 있다.`,
				],
			},
			{
				title: `가족돌봄휴가`,
				content: [
					`${companyUn} 사원이 다음 각 호의 가족의 질병, 사고, 노령 또는 자녀의 양육으로 인하여 긴급하게 그 가족을 돌보기 위한 휴가(이하 “가족돌봄휴가”라 한다)를 신청하는 경우 이를 무급으로 허용하여야 한다. 다만, 사원이 청구한 시기에 가족돌봄휴가를 주는 것이 정상적인 사업 운영에 중대한 지장을 초래하는 경우에는 사원과 협의하여 그 시기를 변경할 수 있다.`,
					[
						`본인의 부모`,
						`배우자의 부모`,
						`배우자`,
						`자녀`,
						`직계비속이 없는 조부모`,
						`직계존속이 없는 손자녀`,
					],
					`제1항에도 불구하고 다음 각 호의 경우에는 가족돌봄휴가를 부여하지 아니한다.`,
					[
						`돌봄휴직개시예정일의 전날까지 해당 사업에서 계속 근로한 기간이 6개월 미만인 사원이 신청한 경우`,
						`부모, 배우자, 자녀 또는 배우자의 부모를 돌보기 위하여 가족돌봄휴직을 신청한 사원 외에도 돌봄이 필요한 가족의 부모, 자녀, 배우자 등이 돌봄이 필요한 가족을 돌볼 수 있는 경우`,
						`조부모 또는 손자녀를 돌보기 위하여 가족돌봄휴직을 신청한 사원 외에도 조부모의 직계비속 또는 손자녀의 직계존속이 있는 경우. 다만, 조부모의 직계비속 또는 손자녀의 직계존속에게 질병, 노령, 장애 또는 미성년 등의 사유가 있어 신청한 사원이 돌봐야 하는 경우는 제외한다.`,
						`${companyGa} 직업안정기관에 구인신청을 하고 14일 이상 대체인력을 채용하기 위하여 노력하였으나 대체인력을 채용하지 못한 경우. 다만, 직업안정기관의 장의 직업소개에도 불구하고 정당한 이유 없이 2회 이상 채용을 거부한 경우는 제외한다.`,
						`${companyGa} 사원의 가족돌봄휴직으로 인하여 정상적인 사업 운영에 중대한 지장이 초래되는 경우로서 이를 증명하는 경우`,
					],
					`가족돌봄휴가의 사용기간과 분할횟수 등은 다음 각 호에 따른다.`,
					[
						`가족돌봄휴가 기간은 연간 최장 10일[제2호에 따라 가족돌봄휴가 기간이 연장되는 경우 20일(「한부모가족지원법」 제4조제1호의 모 또는 부에 해당하는 사원의 경우 25일) 이내]로 하며, 일단위로 사용할 수 있을 것. 다만, 가족돌봄휴가 기간은 가족돌봄휴직 기간에 포함된다.`,
						`고용노동부장관은 감염병의 확산 등을 원인으로 「재난 및 안전관리 기본법」 제38조에 따른 심각단계의 위기경보가 발령되거나, 이에 준하는 대규모 재난이 발생한 경우로서 사원에게 가족을 돌보기 위한 특별한 조치가 필요하다고 인정되는 경우 「고용정책 기본법」 제10조에 따른 고용정책심의회의 심의를 거쳐 가족돌봄휴가 기간을 연간 10일(「한부모가족지원법」 제4조제1호에 따른 모 또는 부에 해당하는 사원의 경우 15일)의 범위에서 연장한 경우는 그 기간을 포함한다.`,
					],
					`제3항 제2호에 따라 연장된 가족돌봄휴가는 다음 각 호의 어느 하나에 해당하는 경우에만 사용할 수 있다.`,
					[
						`감염병 확산을 사유로 「재난 및 안전관리 기본법」 제38조에 따른 심각단계의 위기경보가 발령된 경우로서 가족이 위기경보가 발령된 원인이 되는 감염병의 「감염병의 예방 및 관리에 관한 법률」 제2조제13호부터 제15호까지의 감염병환자, 감염병의사환자, 병원체보유자인 경우 또는 같은 법 제2조제15호의2의 감염병의심자 중 유증상자 등으로 분류되어 돌봄이 필요한 경우`,
						`자녀가 소속된 「초ㆍ중등교육법」 제2조의 학교, 「유아교육법」 제2조제2호의 유치원 또는 「영유아보육법」 제2조제3호의 어린이집(이하 이 조에서 “학교등”이라 한다)에 대한 「초ㆍ중등교육법」 제64조에 따른 휴업명령 또는 휴교처분, 「유아교육법」 제31조에 따른 휴업 또는 휴원 명령이나 「영유아보육법」 제43조의2에 따른 휴원명령으로 자녀의 돌봄이 필요한 경우`,
						`자녀가 제1호에 따른 감염병으로 인하여 「감염병의 예방 및 관리에 관한 법률」 제42조제2항제1호에 따른 자가(自家) 격리 대상이 되거나 학교등에서 등교 또는 등원 중지 조치를 받아 돌봄이 필요한 경우`,
					],
					`${companyUn} 가족돌봄휴가를 이유로 해당 사원을 해고하거나 근로조건을 악화시키는 등 불리한 처우를 하여서는 아니 된다.`,
					`가족돌봄휴직 및 가족돌봄휴가 기간은 근속기간에 포함한다. 다만, 「근로기준법」 제2조제1항제6호에 따른 평균임금 산정기간에서는 제외한다.`,
					`${companyUn} 가족돌봄휴가를 신청하려는 사원은 가족돌봄휴가를 사용하려는 날, 가족돌봄휴가 중 돌보는 대상인 가족의 성명ㆍ생년월일, 가족돌봄휴가 신청 연월일, 신청인 등에 대한 사항을 적은 문서(전자문서를 포함한다)를 ${company}에게 제출해야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `경조휴가`,
				content: [
					`다음 각 호의 경사 사원에게 발생할 경우 해당일수만큼 경조휴가를 부여하되 사원은 발생일(발생일이 소정근로일이 아닌 경우에는 도래하는 첫번째 소정근로일)부터 연속으로 이를 사용하여야 하고, 동 경조휴가일에 소정근로일이 포함되면 해당일은 유급(통상임금)으로 한다.`,
					`결혼`,
					[
						`자녀: 1일`,
						`본인의 형제자매, 배우자의 형제자매: 1일`,
						`본인의 형제자매, 배우자의 형제자매: 1일`,
						`본인: 5일`,
					],

					`칠순`,
					[`본인의 부모: 1일`, `배우자의 부모: 1일`],
					`다음 각 호의 애사가 해당일 시업시간 이전과 종업시간 이후에 사원에게 발생할 경우 해당일수 만큼 경조휴가를 부여하되 사원은 발생일부터 연속으로 이를 사용하여야 하고, 근무시간 중에 발생하면 발생일 익일부터 연속으로 이를 사용하고, 동 경조휴가일에 소정근로일이 포함되면 해당일은 유급(통상임금)으로 한다.`,
					`사망`,
					[
						`본인의 형제자매: 3일`,
						`배우자의 형제자매: 3일`,
						`본인의 조부모: 3일`,
						`배우자의 조부모: 3일`,
						`본인의 배우자: 5일`,
						`본인의 자녀: 5일`,
						`본인의 부모: 5일`,
						`배우자의 부모: 5일`,
					],
					`수습기간 중에 있는 사원에게는 경조휴가를 부여하지 않는다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `업무외 부상 질병 휴가`,
				content: [
					`${companyUn} 다음 각 호에 해당하는 사원에게 연차유급휴가 또는 보상휴가의 사용으로 보는 업무외 부상 질병 휴가를 부여할 수 있다.`,
					[
						`8일 이상 14일 이하의 가료 또는 요양이 필요한 업무외 질병 또는 부상의 사원`,
						`전염병에 걸림으로 인하여 그 사원이 출근할 수가 없거나 다른 사원의 건강에 영향을 미칠 우려가 있는 사원`,
					],
					`업무외 부상 질병 휴가 기간이 8일 이상일 경우에는 의사의 진단서를 첨부하여야 한다.`,
					`업무외 부상 질병 휴가를 사용하는 사원은 휴가개시일로부터 3일 이내에 서면의 휴가계를 제출하여야 하며, 만약 그렇지 않으면 무단결근으로 본다.`,
					`본 조항의 업무외 부상 질병 휴가는 사원이 보유한 연차유급휴가나 보상휴가 범위 더하기 1주일 내에서 사용할 수 있으며, 연차유급휴가와 보상휴가를 초과하는 1주일의 업무외 부상 질병 휴가는 무급으로 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `휴가의 신청과 허가`,
				content: [
					`법정휴가(본장(제2장)의 휴가 중 제2항에 따른 소정휴가를 제외한 휴가)를 사용하는 사원은 휴가개시일로부터 3일 이전에 서면의 휴가계를 제출하여야 하며, 만약 그렇지 않으면 무단결근으로 본다.`,
					`소정휴가(업무외 부상 질병 휴가, 경조휴가)를 사용하는 사원은 휴가개시일로부터 3일 이전에 객관적인 소명자료를 첨부한 서면의 휴가계를 제출하여야 하며, 만약 그렇지 않으면 무단결근으로 본다. 단, 애사의 휴가는 그렇지 아니한다.`,
					`사원이 신청한 연차휴가·소정휴가의 사용이 사업상 지장이 없는 경우에는 ${companyUn} 신청된 휴가를 휴가개시일 전일 종업시각까지 허락하여야 한다. 단, 사원이 신청한 연차휴가·소정휴가의 사용이 ${company}의 사업운영에 막대한 지장이 초래될 개연성이 있다면 ${companyUn} 연차휴가·소정휴가에 대한 사용변경권을 행사할 수 있다.`,
					`${companyUn} 법정휴가·소정휴가를 사용하는 사원은 반드시 ${company}의 허가를 늦어도 휴가개시일 전일 종업시각까지 얻어야 한다. 만약 그렇지 않으면 무단결근으로 본다.`,
				],
			},
		];

		// 특정업체 특정조항을 추가하는 코드

		if (['세무법인 온']?.includes(company)) {
			const additionalSection = {
				title: `시업시각과 종업시각`,
				content: [
					`시업시각과 종업시각은 사원별로 근로계약서에 명기한다.`,
					`제1항에도 불구하고 근무지와 근무형태 또는 기타 업무상의 필요에 따라 제1항의 근로시간을 세무법인 온세의 기안 또는 고시에 의하여 변경할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			};
			baseSections.splice(1, 0, additionalSection); // "목적" 다음에 "소정근로시간" 삽입
		}
		///////////////////
		// 특정업체 특정조항을 삭제하는 코드
		const excludedCompanies = ['세무법인 온'];

		// 조건에 따라 섹션 제거
		if (excludedCompanies?.includes(company)) {
			// "title"이 여러 개일 때 제거할 섹션 목록
			const excludedTitles = ['시업시각과 종업시각'];

			baseSections = baseSections.filter(
				(section) => !excludedTitles?.includes(section.title)
			);
		}
		/////////////////////////////
		// 특정업체의 특정조항을 바꾸는 코드
		if (['세무법인 온세']?.includes(company)) {
			// 기존 "시업시각과 종업시각" 섹션을 제거
			baseSections = baseSections.filter(
				(section) => section.title !== '근로시간 및 휴게, 휴일의 적용제외'
			);

			// 세무법인 온세에 대한 새로운 섹션 추가
			const additionalSection = {
				title: `시업시각과 종업시각`,
				content: [
					`시업시각과 종업시각은 사원별로 근로계약서에 명기한다.`,
					`제1항에도 불구하고 근무지와 근무형태 또는 기타 업무상의 필요에 따라 제1항의 근로시간을 세무법인 온세의 기안 또는 고시에 의하여 변경할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			};
			////////////////////////////
			// 특정 섹션 뒤에 삽입하고자 하는 경우
			const targetTitle = '소정근로시간'; // 삽입하려는 기준 섹션의 제목
			const targetIndex = baseSections.findIndex(
				(section) => section.title === targetTitle
			);

			if (targetIndex !== -1) {
				baseSections.splice(targetIndex + 1, 0, additionalSection);
			} else {
				// 만약 기준 섹션이 없으면 맨 마지막에 추가
				baseSections.push(additionalSection);
			}
		}

		/////////////////////////////
		// 특정업체의 특정조항을 바꾸는 코드
		if (['세무법인 온세']?.includes(company)) {
			// 기존 "시업시각과 종업시각" 섹션을 제거
			baseSections = baseSections.filter(
				(section) => section.title !== '경조휴가'
			);

			// 세무법인 온세에 대한 새로운 섹션 추가
			const additionalSection = {
				title: `경조휴가`,
				content: [
					`다음 각 호의 경사 사원에게 발생할 경우 해당일수만큼 경조휴가를 부여하되 사원은 발생일(발생일이 소정근로일이 아닌 경우에는 도래하는 첫번째 소정근로일)부터 연속으로 이를 사용하여야 하고, 동 경조휴가일에 소정근로일이 포함되면 해당일은 유급(통상임금)으로 한다.`,
					`결혼`,
					[`자녀: 1일`, `본인: 5일`],
					`다음 각 호의 애사가 해당일 시업시간 이전과 종업시간 이후에 사원에게 발생할 경우 해당일수 만큼 경조휴가를 부여하되 사원은 발생일부터 연속으로 이를 사용하여야 하고, 근무시간 중에 발생하면 발생일 익일부터 연속으로 이를 사용하고, 동 경조휴가일에 소정근로일이 포함되면 해당일은 유급(통상임금)으로 한다.`,
					`사망`,
					[
						`본인의 조부모: 3일`,
						`본인의 배우자: 5일`,
						`본인의 자녀: 5일`,
						`본인의 부모: 5일`,
					],
					`수습기간 중에 있는 사원에게는 경조휴가를 부여하지 않는다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			};
			////////////////////////////
			// 특정 섹션 뒤에 삽입하고자 하는 경우
			const targetTitle = '가족돌봄휴가'; // 삽입하려는 기준 섹션의 제목
			const targetIndex = baseSections.findIndex(
				(section) => section.title === targetTitle
			);

			if (targetIndex !== -1) {
				baseSections.splice(targetIndex + 1, 0, additionalSection);
			} else {
				// 만약 기준 섹션이 없으면 맨 마지막에 추가
				baseSections.push(additionalSection);
			}
		}

		return baseSections;
	}, [
		serviceStartDate,
		profile,
		company,
		companyUn,
		companyGa,
		alPormotionDate,
		alPormotionEndMonth,
		alPormotionMonth,
		companyWa,
		finBaseDateDate,
		finBaseDateMonth,
	]);

	useEffect(() => {
		setSectionCount(sections.length);
	}, [sections, setSectionCount]);

	const [openSections, setOpenSections] = useState({});

	useEffect(() => {
		const newOpenSections = sections.reduce((acc, section) => {
			acc[section.title] = allOpen;
			return acc;
		}, {});
		setOpenSections(newOpenSections);
	}, [allOpen, sections]);

	const toggleSection = (section) => {
		setOpenSections((prevState) => ({
			...prevState,
			[section]: !prevState[section],
		}));
	};

	return (
		<>
			<div className='jang sectionup'>제2장 근로시간제도</div>
			{sections.map((section, index) => (
				<div key={index}>
					<RuleSection
						number={index + previousSectionCount1 + 1}
						title={section.title}
						content={section.content}
						isOpen={!!openSections[section.title]}
						toggleOpen={() => toggleSection(section.title)}
						amended={section.amended}
					/>
				</div>
			))}
		</>
	);
}
