import React from 'react';
import ClientReqDLCDLawTime from './clientReqDetailedLCDetail/ClientReqDLCDLawTime';
import ClientReqDLCDTime from './clientReqDetailedLCDetail/ClientReqDLCDTime';
import ClientReqDLCDWage from './clientReqDetailedLCDetail/ClientReqDLCDWage';
import ClientReqDLCEndContract from './clientReqDetailedLCDetail/ClientReqDLDCEndContract';
import ClientReqDLDCHoli from './clientReqDetailedLCDetail/ClientReqDLDCHoli';
import ClientReqDLCWorkPeriod from './clientReqDetailedLCDetail/ClientReqDLCWorkPeriod';
import ClientReqDLCDJobAnalysis from './clientReqDetailedLCDetail/ClientReqDLCDJobAnalysis';
import ClientReqDLCSecretEtc from './clientReqDetailedLCDetail/ClientReqDLCSecretEtc';
import ClientReqDLCWorkPlace from './clientReqDetailedLCDetail/ClientReqDLCWorkPlace';
import ClientReqDLCHead from './clientReqDetailedLCDetail/ClientReqDLCHead';
import ClientReqDLCTail from './clientReqDetailedLCDetail/ClientReqDLCTail';
import ClientReqDLCWorkContents from './clientReqDetailedLCDetail/ClientReqDLCWorkContents';
import DongsungSC from './clientReqDetailedLCDetail/specialComment/DongsungSC';
import SonghakService from './clientReqDetailedLCDetail/specialComment/SonghakService';
import { decrypto } from '../../../../app/firestore/firestoreService';
import {
	contractOldWorkerF,
	getAgeFromSocialNumber,
	netAge15To18F,
} from '../../../../app/common/util/util';

export default function ClientReqDetailedLaborContract({
	weekLBTime,
	selectedClientReq4In,
	currentUserProfile,
	companyName,
	isEtcWorker,
	clientInfo,
}) {
	const workerSocialNumberFront = decrypto(
		selectedClientReq4In.workerSocialNumberFront,
		String(process.env.CRYPTO_KEY)
	);
	const netAge = getAgeFromSocialNumber(workerSocialNumberFront);
	const netAge15To18 = netAge15To18F(netAge);

	const contractOldWorker = contractOldWorkerF(
		selectedClientReq4In?.contractOldWorker,
		selectedClientReq4In?.afterRetireAgainContractEndDate,
		selectedClientReq4In?.afterRetireAgainContractStartDate,
		netAge
	);

	const contractWorker =
		contractOldWorker || selectedClientReq4In?.contractEndDate ? true : false;

	const companyId = clientInfo ? clientInfo.id : currentUserProfile?.id;

	//근무요일들
	const days1 = selectedClientReq4In?.selectWorkDays1;
	const days2 = selectedClientReq4In?.selectWorkDays2;
	const days3 = selectedClientReq4In?.selectWorkDays3;
	const days4 = selectedClientReq4In?.selectWorkDays4;
	const days5 = selectedClientReq4In?.selectWorkDays5;
	const days6 = selectedClientReq4In?.selectWorkDays6;

	const daysETL1 = selectedClientReq4In?.selectWorkDaysETL1;
	const daysETL2 = selectedClientReq4In?.selectWorkDaysETL2;
	const daysETL3 = selectedClientReq4In?.selectWorkDaysETL3;
	const daysETL4 = selectedClientReq4In?.selectWorkDaysETL4;
	const daysETL5 = selectedClientReq4In?.selectWorkDaysETL5;
	const daysETL6 = selectedClientReq4In?.selectWorkDaysETL6;
	const daysETL7 = selectedClientReq4In?.selectWorkDaysETL7;

	////////////////////////////////////////////////////실근무시간
	const daysWT1 = selectedClientReq4In?.realWorkTime?.daysWT1;
	const daysWT2 = selectedClientReq4In?.realWorkTime?.daysWT2;
	const daysWT3 = selectedClientReq4In?.realWorkTime?.daysWT3;
	const daysWT4 = selectedClientReq4In?.realWorkTime?.daysWT4;
	const daysWT5 = selectedClientReq4In?.realWorkTime?.daysWT5;
	const daysWT6 = selectedClientReq4In?.realWorkTime?.daysWT6;

	const skipWeek1 =
		selectedClientReq4In?.selectWorkDays1Info?.skipWeek === '격주근무'
			? true
			: false;
	const skipWeek2 =
		selectedClientReq4In?.selectWorkDays2Info?.skipWeek === '격주근무'
			? true
			: false;
	const skipWeek3 =
		selectedClientReq4In?.selectWorkDays3Info?.skipWeek === '격주근무'
			? true
			: false;
	const skipWeek4 =
		selectedClientReq4In?.selectWorkDays4Info?.skipWeek === '격주근무'
			? true
			: false;
	const skipWeek5 =
		selectedClientReq4In?.selectWorkDays5Info?.skipWeek === '격주근무'
			? true
			: false;
	const skipWeek6 =
		selectedClientReq4In?.selectWorkDays6Info?.skipWeek === '격주근무'
			? true
			: false;

	const daysWTETL1 = selectedClientReq4In?.realWorkTime?.daysWTETL1;
	const daysWTETL2 = selectedClientReq4In?.realWorkTime?.daysWTETL2;
	const daysWTETL3 = selectedClientReq4In?.realWorkTime?.daysWTETL3;
	const daysWTETL4 = selectedClientReq4In?.realWorkTime?.daysWTETL4;
	const daysWTETL5 = selectedClientReq4In?.realWorkTime?.daysWTETL5;
	const daysWTETL6 = selectedClientReq4In?.realWorkTime?.daysWTETL6;
	const daysWTETL7 = selectedClientReq4In?.realWorkTime?.daysWTETL7;

	const worker = selectedClientReq4In?.worker?.name;
	const workerEnterDate = selectedClientReq4In?.workerEnteredDate;
	const wageContractStartDate = selectedClientReq4In?.wageContractStartDate;
	const workTimeComment = selectedClientReq4In?.workTimeComment;
	const isExceptTimeLawWorker = selectedClientReq4In?.isExceptTimeLawWorker;

	const isSkipWeekedWorker =
		skipWeek1 || skipWeek2 || skipWeek3 || skipWeek4 || skipWeek5 || skipWeek6
			? true
			: false;

	const numOfWorkers = selectedClientReq4In?.retiredDate
		? selectedClientReq4In?.numOfWorkers
		: clientInfo?.numOfWorkers
		? clientInfo?.numOfWorkers
		: currentUserProfile?.numOfWorkers;

	return (
		<div id='laborContact'>
			<div className='section'>
				<ClientReqDLCHead
					isSkipWeekedWorker={isSkipWeekedWorker}
					isExceptTimeLawWorker={isExceptTimeLawWorker}
					selectedClientReq4In={selectedClientReq4In}
					companyName={companyName}
					worker={worker}
					weekLBTime={weekLBTime}
					netAge15To18={netAge15To18}
					netAge={netAge}
					contractOldWorker={contractOldWorker}
				/>
			</div>
			<div className='section'>
				<ClientReqDLCWorkPeriod
					selectedClientReq4In={selectedClientReq4In}
					numOfWorkers={numOfWorkers}
					companyName={companyName}
					worker={worker}
					contractOldWorker={contractOldWorker}
				/>
			</div>
			<div className='section'>
				<ClientReqDLCWorkPlace
					selectedClientReq4In={selectedClientReq4In}
					companyName={companyName}
					worker={worker}
				/>
			</div>
			<div className='section'>
				<ClientReqDLCWorkContents
					selectedClientReq4In={selectedClientReq4In}
					companyName={companyName}
					worker={worker}
				/>
			</div>
			<div className='section'>
				<ClientReqDLCDLawTime
					netAge15To18={netAge15To18}
					netAge={netAge}
					isExceptTimeLawWorker={isExceptTimeLawWorker}
					isEtcWorker={isEtcWorker}
					weekLBTime={weekLBTime}
					selectedClientReq4In={selectedClientReq4In}
					companyName={companyName}
					worker={worker}
					numOfWorkers={numOfWorkers}
					days1={days1}
					days2={days2}
					days3={days3}
					days4={days4}
					days5={days5}
					days6={days6}
					daysWT2={daysWT2}
					daysWT3={daysWT3}
					daysWT4={daysWT4}
					daysWT5={daysWT5}
					daysWT6={daysWT6}
					skipWeek1={skipWeek1}
					skipWeek2={skipWeek2}
					skipWeek3={skipWeek3}
					skipWeek4={skipWeek4}
					skipWeek5={skipWeek5}
					skipWeek6={skipWeek6}
				/>
			</div>
			<div className='section'>
				<ClientReqDLCDTime
					netAge15To18={netAge15To18}
					netAge={netAge}
					isExceptTimeLawWorker={isExceptTimeLawWorker}
					isEtcWorker={isEtcWorker}
					numOfWorkers={numOfWorkers}
					selectedClientReq4In={selectedClientReq4In}
					companyName={companyName}
					weekLBTime={weekLBTime}
					worker={worker}
					days1={days1}
					days2={days2}
					days3={days3}
					days4={days4}
					days5={days5}
					days6={days6}
					daysWT1={daysWT1}
					daysWT2={daysWT2}
					daysWT3={daysWT3}
					daysWT4={daysWT4}
					daysWT5={daysWT5}
					daysWT6={daysWT6}
					skipWeek1={skipWeek1}
					skipWeek2={skipWeek2}
					skipWeek3={skipWeek3}
					skipWeek4={skipWeek4}
					skipWeek5={skipWeek5}
					skipWeek6={skipWeek6}
					daysETL1={daysETL1}
					daysETL2={daysETL2}
					daysETL3={daysETL3}
					daysETL4={daysETL4}
					daysETL5={daysETL5}
					daysETL6={daysETL6}
					daysETL7={daysETL7}
					daysWTETL1={daysWTETL1}
					daysWTETL2={daysWTETL2}
					daysWTETL3={daysWTETL3}
					daysWTETL4={daysWTETL4}
					daysWTETL5={daysWTETL5}
					daysWTETL6={daysWTETL6}
					daysWTETL7={daysWTETL7}
					workTimeComment={workTimeComment}
				/>
			</div>
			<div className='section'>
				<ClientReqDLDCHoli
					isExceptTimeLawWorker={isExceptTimeLawWorker}
					isEtcWorker={isEtcWorker}
					selectedClientReq4In={selectedClientReq4In}
					companyName={companyName}
					worker={worker}
					numOfWorkers={numOfWorkers}
					contractOldWorker={contractOldWorker}
					clientInfo={clientInfo}
					currentUserProfile={currentUserProfile}
				/>
			</div>
			<div className='section'>
				<ClientReqDLCDWage
					companyName={companyName}
					worker={worker}
					contractOldWorker={contractOldWorker}
				/>
			</div>
			<div className='section'>
				<ClientReqDLCDJobAnalysis
					currentUserProfile={currentUserProfile}
					companyName={companyName}
					worker={worker}
				/>
			</div>
			<div className='section'>
				{/*  제9조 근로관계의 종료(해고, 해지, 해고예고) */}
				<ClientReqDLCEndContract
					selectedClientReq4In={selectedClientReq4In}
					numOfWorkers={numOfWorkers}
					companyName={companyName}
					worker={worker}
				/>
			</div>
			<div className='section'>
				{/* 제10조 비밀유지 및 손해배상 제11조 특약 등 */}
				<ClientReqDLCSecretEtc
					contractOldWorker={contractOldWorker}
					contractWorker={contractWorker}
					companyName={companyName}
					worker={worker}
					numOfWorkers={numOfWorkers}
					selectedClientReq4In={selectedClientReq4In}
					clientInfo={clientInfo}
					currentUserProfile={currentUserProfile}
				/>
			</div>
			<div className='section'>
				{/* 제11조 동성기업만의 특약 --- 특약이 있는 경우만 만든다. */}
				<DongsungSC
					companyName={companyName}
					companyId={companyId}
					worker={worker}
					workerEnterDate={workerEnterDate}
					wageContractStartDate={wageContractStartDate}
				/>
			</div>

			<div className='section'>
				{/* 제11조 송학서비스 특약 --- 특약이 있는 경우만 만든다. */}
				<SonghakService
					companyName={companyName}
					worker={worker}
					workerEnterDate={workerEnterDate}
					wageContractStartDate={wageContractStartDate}
				/>
			</div>
			<div className='section'>
				{/* 제11조 동성기업만의 특약 --- 특약이 있는 경우만 만든다. */}
				<DongsungSC
					companyName={companyName}
					worker={worker}
					workerEnterDate={workerEnterDate}
					wageContractStartDate={wageContractStartDate}
				/>
			</div>
			<div className='section'>
				{/* ************************************************************ */}
				<ClientReqDLCTail
					clientInfo={clientInfo}
					currentUserProfile={currentUserProfile}
					selectedClientReq4In={selectedClientReq4In}
					companyName={companyName}
					worker={worker}
				/>
			</div>
			<footer>
				저작권 © AI NOMUSA(노무법인 최상인업). All rights reserved since 2002.
			</footer>
		</div>
	);
}
