import React from 'react';
import { Table } from 'semantic-ui-react';
import {
	absentDaysOrdinaryWageF,
	isShortTimeRegularWorkerF,
	numberToCommaString,
	numStringToNumber,
	roundDown,
	roundUp,
	workingDaysInWeekF,
} from '../../../app/common/util/util';

import PaydocuDeductDetail from './PaydocuDeductDetail';
import PaydocuPayDetailRegularWorker from './PaydocuPayDetailRegularWorker';
import PaydocuPayDetailDayTimeWorker from './PaydocuPayDetailDayTimeWorker';

export default function PaydocuPayDetail({
	isBizWorker,
	perDayMealTaxFree,
	plusIrregularDayMealTFDays,
	plusIrregularDayMealTFWage,

	workerRetiredDate,
	maternityDeductWage,
	maternityFinalDedectOrdWage,
	maternityFinalDedectLawOverWage,
	selectedClientReq4In,
	numFinalRetireHealthCareRecalFee,
	numFinalRetireOldCareRecalFee,

	annualLeavesIncludeMonthWage,
	dayWorker,
	countOfNomalDayWork,
	countOfHoliDayWork,
	countOfOverWorkingDayWork,

	totalDayWorkerWage,
	totalDayWorkerHoliWage,
	totalDayWorkerOverWorkDayWage,

	dayWorkerWage,
	dayWorkerHoliWage,
	dayWorkerOverWorkDayWage,
	dayWokerTimeWage,

	workDayOTimePaidWage,
	workDayNTimePaidWage,
	holiBTimePaidWage,
	holiOTimePaidWage,
	holiNTimePaidWage,
	sumOfLawOverWage,
	absentDaysLawOverWage,
	plusRegularBonus,
	plusRegularBonusComment,
	plusIrregularBonus,
	plusIrregularBonusComment,

	plusUnusedAnnualLeavesWage,
	plusUnusedAnnualLeavesWageComment,
	plusLastYearUnusedAnnualLeavesWage,
	plusLastYearUnusedAnnualLeavesWageComment,

	plusRetiredUnusedAnnualLeaves,
	plusSpecialRandomWage,
	plusLastMonthAverWage,
	plusLastMonthAverWageComment,
	plusPersonalIncentive,
	plusPersonalIncentiveComment,

	plusDeductHealthCare,
	plusDeductHealthCareComment,
	plusDeductOldCare,
	plusDeductOldCareComment,
	plusDeductWorkTax,
	plusDeductWorkTaxComment,
	plusDeductWorkRegionTax,
	plusDeductWorkRegionTaxComment,
	plusDeductDuru,
	plusMinus4Ins,
	plusDeductDuruComment,
	plusMinus4InsComment,
	plusDeductWorkerCompanyLoan,
	plusDeductWorkerCompanyLoanComment,

	isInterInWorker,
	selectedPaydocu,
	year,
	inputTax,
	handelInputChange,
	weekLBTime,
	numOfWorkers,
	hourOrdinaryWage,
	paidHolidayLBTime,
	inputWageStyle,
	centerStyle,
	inputCommentStyle,
	monthOWT,
	monthNWT,
	monthHoliBasicTime,
	monthOWTHoli,
	monthNWTHoli,

	plusMonthOWT,
	plusMonthNWT,
	plusMonthHoliBasicTime,
	plusMonthOWTHoli,
	plusMonthNWTHoli,

	monthBasicWage,
	cTFMealWage,
	cTFCarWage,
	cTFChildWage,
	cTFStudyWage,
	cTFEtc,
	cOWTitle,
	cOWJob,
	cOWLicense,
	cOWLongTerm,
	cOWMonthlyBonus,
	cOWEtc,

	cOWChildManageStudyWage,
	cOWChildManageStudyWageComment,
	cOWChildStudyWage,
	cOWChildStudyWageComment,
	cOWChildCareWage,
	cOWChildCareWageComment,
	cOWChildActivityWage,
	cOWChildActivityWageComment,

	cAWage1,
	cAWage2,
	cAWage3,
	cAWage4,

	cAWageTF1,
	cAWENameTF1,
	cAWageTF1Comment,
	cAWageTF2,
	cAWENameTF2,
	cAWageTF2Comment,
	cAWageTF3,
	cAWENameTF3,
	cAWageTF3Comment,
	cAWageTF4,
	cAWENameTF4,
	cAWageTF4Comment,

	userFixedCAWageTF1,
	userFixedCAWageTF1Name,
	userFixedCAWageTF1Comment,
	userFixedCAWageTF2,
	userFixedCAWageTF2Name,
	userFixedCAWageTF2Comment,
	userFixedCAWageTF3,
	userFixedCAWageTF3Name,
	userFixedCAWageTF3Comment,
	userFixedCAWageTF4,
	userFixedCAWageTF4Name,
	userFixedCAWageTF4Comment,

	userFixedCAWage1,
	userFixedCAWage1Name,
	userFixedCAWage1Comment,
	userFixedCAWage2,
	userFixedCAWage2Name,
	userFixedCAWage2Comment,
	userFixedCAWage3,
	userFixedCAWage3Name,
	userFixedCAWage3Comment,
	userFixedCAWage4,
	userFixedCAWage4Name,
	userFixedCAWage4Comment,

	cAWage5,
	cAWEName5,
	cAWage5Comment,
	cAWage6,
	cAWEName6,
	cAWage6Comment,
	cAWage7,
	cAWEName7,
	cAWage7Comment,
	deductEtcName1,
	deductEtcAWage1,
	deductEtcWage1Comment,
	deductEtcName2,
	deductEtcAWage2,
	deductEtcWage2Comment,
	deductEtcName3,
	deductEtcAWage3,
	deductEtcWage3Comment,
	monthBasicWageComment,
	cTFMealWageComment,
	cTFCarWageComment,
	cTFChildWageComment,
	cTFStudyWageComment,
	cTFEtcComment,
	cOWTitleComment,
	cOWJobComment,
	cOWLicenseComment,
	cOWLongTermComment,
	cOWMonthlyBonusComment,
	cOWEtcComment,
	cAWage1Comment,
	cAWage2Comment,
	cAWage3Comment,
	cAWage4Comment,

	userFixedTax,
	userFixedRegionTax,
	userFixedHealthCare,
	userFixedOldCare,
	userFixedPension,
	userFixedNoJobInsurance,
	userFixedTaxComment,
	userFixedRegionTaxComment,
	userFixedHealthCareComment,
	userFixedOldCareComment,
	userFixedPensionComment,
	userFixedNoJobInsuranceComment,

	daysOfInterInAbusent,
	daysOfInterOutAbusent,
	daysOfInterInAbusentOrdinaryWage,
	daysOfInterInAbusentLawOverWage,
	daysOfInterOutAbusentOrdinaryWage,
	daysOfInterOutAbusentLawOverWage,

	absentDays,
	absentPaidHoliDays,
	absentTimes,
	daysInMonth,
	monthOrdinaryWage,
	cAWEName1,
	cAWEName2,
	cAWEName3,
	cAWEName4,

	calTax,
	calRegionTax,
	calPension,
	calHealthCare,
	calOldCare,
	calNoJobInsurance,
	dedectTaxEtc,
	deductWage,
	totalMonthWageAmount,

	taxText,
	regionTaxText,
	noJobText,
	pensionText,
	oldCareText,
	healthCareText,

	taxPayType,
	calTaxRate,
	pensionPayType,
	pensionBaseWage,
	healthCarePayType,
	healthCareBaseWage,
	oldCarePayType,
	oldCareBaseWage,
	noJobPayType,
	noJobBaseWage,
	taxFreeWageSum,
	taxWageSum,

	numDeductEtcAWage1,
	numDeductEtcAWage2,
	numDeductEtcAWage3,

	dayWorkerPlusOTime,
	dayWorkerPlusNTime,
	dayWorkerPlusHBTime,
	dayWorkerPlusHOTime,
	dayWorkerPlusHNTime,
	dayWorkerAbsentTimes,

	daylyTimePay,
	timeWorkerPlusMonthOWT,
	timeWorkerPlusMonthNWT,
	timeWorkerPlusMonthHoliBasicTime,
	timeWorkerPlusMonthOWTHoli,
	timeWorkerPlusMonthOffBasicTime,
	timeWorkerPlusMonthOWTOff,
	numOWageForTW,
	numNWageForTW,
	numHBWageForTW,
	numHOWageForTW,
	numOffWageForTW,
	numOffOWageForTW,

	totalTimeWorkerWage,
	totalTimeWorkerPaidHoliWage,
	timeWorkerTimeWage,
	timeWorkerTimePaidHoliWage,
	week14OverBLTime,
	numPaidHoliTimeW,
	finalPaidHoliWageTimeSum,
	monthTotalWorkTime,
	finalPaidHoliWageTime,
	normalWorkerWeekWorkDays,
	paidHoliWageDays,
	paidHoliWageText,

	dayWorkerHealthCareTextOption,
	dayWorkerNoJobTextOption,
	dayWorkerPensionTextOption,
	dayWorkerOldCareTextOption,

	dayWorkerTaxTextOption,
	dayWorkerRegionTaxTextOption,

	dayWorkerCAWEName5,
	dayWorkerCAWage5,
	dayWorkerCAWage5Comment,
	dayWorkerCAWage6,
	dayWorkerCAWEName6,
	dayWorkerCAWage6Comment,
	dayWorkerCAWage7,
	dayWorkerCAWEName7,
	dayWorkerCAWage7Comment,
	dayWorkerTotalMonthWageAmount,
	dayWorkerUserFixedTax,
	dayWorkerUserFixedTaxComment,
	dayWorkerCalTax,

	dayWorkerUserFixedRegionTax,
	dayWorkerUserFixedRegionTaxComment,
	dayWorkerCalRegionTax,

	dayWorkerUserFixedHealthCare,
	dayWorkerUserFixedHealthCareComment,
	dayWorkerCalHealthCare,

	dayWorkerUserFixedOldCare,
	dayWorkerUserFixedOldCareComment,
	dayWorkerCalOldCare,

	dayWorkerUserFixedPension,
	dayWorkerUserFixedPensionComment,
	dayWorkerCalPension,

	dayWorkerUserFixedNoJobInsurance,
	dayWorkerUserFixedNoJobInsuranceComment,
	dayWorkerCalNoJobInsurance,

	dayWorkerDeductEtcWage1,
	dayWorkerDeductEtcName1,
	dayWorkerDeductEtcWage1Comment,

	dayWorkerDeductEtcWage2,
	dayWorkerDeductEtcName2,
	dayWorkerDeductEtcWage2Comment,

	dayWorkerDeductEtcWage3,
	dayWorkerDeductEtcName3,
	dayWorkerDeductEtcWage3Comment,
	dayWorkerDedectTaxEtc,
	dayWorkerDeductWage,

	dayWorkerPlusDeductHealthCare,
	dayWorkerPlusDeductHealthCareComment,
	dayWorkerPlusDeductOldCare,
	dayWorkerPlusDeductOldCareComment,
	dayWorkerPlusDeductWorkTax,
	dayWorkerPlusDeductWorkTaxComment,
	dayWorkerPlusDeductWorkRegionTax,
	dayWorkerPlusDeductWorkRegionTaxComment,
	dayWorkerPlusDeductDuru,
	dayWorkerPlusMinus4Ins,
	dayWorkerPlusDeductDuruComment,
	dayWorkerPlusMinus4InsComment,
	dayWorkerPlusDeductWorkerCompanyLoan,
	dayWorkerPlusDeductWorkerCompanyLoanComment,

	numDayWorkerOWage,
	numDayWorkerNWage,
	numDayWorkerHBwage,
	numDayWorkerHOwage,
	numDayWorkerHNwage,

	dayWorkerSpecialBonus,
	timeWorkerSpecialBonus,
	numPaidHoliDayW,
	bizTotalIncome,
	bizDeductedTotal,
	bizDeductionAfterTax,
	bizIncomeTax,
	bizIncomeRegionTax,
	bizDeductWage,
	bizDeductionAfterTaxComment,
}) {
	const gumakStyle = { textAlign: 'right' };
	const numAbsentDays = numStringToNumber(absentDays);
	const numAbsentPaidHoliDays = numStringToNumber(absentPaidHoliDays);
	const numAbsentTimes = numStringToNumber(absentTimes);
	const numDayWorkerAbsentTimes = numStringToNumber(dayWorkerAbsentTimes);
	const dayWorkerAbsentTimeWage = roundDown(
		dayWokerTimeWage * numDayWorkerAbsentTimes
	);
	const isExceptTimeLawWorker = selectedPaydocu?.isExceptTimeLawWorker;

	const numMealWage = numStringToNumber(cTFMealWage);
	const numCarWage = numStringToNumber(cTFCarWage);
	const numChildWage = numStringToNumber(cTFChildWage);
	const numStudyWage = numStringToNumber(cTFStudyWage);
	const numTFEtcWage = numStringToNumber(cTFEtc);

	const workingDaysInWeek = workingDaysInWeekF(
		selectedClientReq4In?.selectWorkDays1?.length || 0,
		selectedClientReq4In?.selectWorkDays2?.length || 0,
		selectedClientReq4In?.selectWorkDays3?.length || 0,
		selectedClientReq4In?.selectWorkDays4?.length || 0,
		selectedClientReq4In?.selectWorkDays5?.length || 0,
		selectedClientReq4In?.selectWorkDays6?.length || 0,
		selectedClientReq4In?.selectWorkDays7?.length || 0
	);

	const isShortTimeRegularWorker = isShortTimeRegularWorkerF(workingDaysInWeek);

	const ordinaryDayWorkingTime =
		selectedClientReq4In?.lawBase?.weekLBTime / workingDaysInWeek;

	const absentDaysOrdinaryWage = absentDaysOrdinaryWageF(
		monthOrdinaryWage,
		daysInMonth,
		numAbsentDays,
		isShortTimeRegularWorker,
		ordinaryDayWorkingTime,
		hourOrdinaryWage
	);

	const absentPaidHoliDaysOrdinaryWage = roundDown(
		hourOrdinaryWage * paidHolidayLBTime * numAbsentPaidHoliDays
	);
	const absentTimeWage = roundDown(hourOrdinaryWage * numAbsentTimes);
	const numUserFixedCAWageTF1 = numStringToNumber(userFixedCAWageTF1);
	const numUserFixedCAWageTF2 = numStringToNumber(userFixedCAWageTF2);
	const numUserFixedCAWageTF3 = numStringToNumber(userFixedCAWageTF3);
	const numUserFixedCAWageTF4 = numStringToNumber(userFixedCAWageTF4);

	const numUserFixedCAWage1 = numStringToNumber(userFixedCAWage1);
	const numUserFixedCAWage2 = numStringToNumber(userFixedCAWage2);
	const numUserFixedCAWage3 = numStringToNumber(userFixedCAWage3);
	const numUserFixedCAWage4 = numStringToNumber(userFixedCAWage4);
	const monthOWTText = selectedPaydocu?.lawOverTimeWage?.monthOWTText;
	const monthNWTText = selectedPaydocu?.lawOverTimeWage?.monthNWTText;
	const monthHoliBasicTimeText =
		selectedPaydocu?.lawOverTimeWage?.monthHoliBasicTimeText;
	const monthOWTHoliText = selectedPaydocu?.lawOverTimeWage?.monthOWTHoliText;
	const monthNWTHoliText = selectedPaydocu?.lawOverTimeWage?.monthNWTHoliText;
	return (
		<>
			<Table.Row>
				<Table.Cell colSpan='9'>{` `}</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell textAlign='left' colSpan='9'>
					<span style={{ color: 'darkcyan', fontWeight: 'bold' }}>
						{isBizWorker
							? '2. 사업소득세 명세서'
							: '3. 월급 구성항목 및 계산방법'}
					</span>
				</Table.Cell>
			</Table.Row>
			<Table.Row>
				<Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>
					구분
				</Table.Cell>
				<Table.Cell style={{ width: '11.11%', textAlign: 'center' }}>
					항목
				</Table.Cell>
				<Table.Cell className='gumak' style={centerStyle}>
					금액[원]
				</Table.Cell>
				<Table.Cell
					colSpan='6'
					className='sulmung'
					style={{ textAlign: 'center' }}>
					설명 및 계산방법
				</Table.Cell>
			</Table.Row>

			{!dayWorker ? (
				<PaydocuPayDetailRegularWorker
					selectedClientReq4In={selectedClientReq4In}
					isBizWorker={isBizWorker}
					companyName={selectedClientReq4In?.companyName}
					perDayMealTaxFree={perDayMealTaxFree}
					plusIrregularDayMealTFDays={plusIrregularDayMealTFDays}
					plusIrregularDayMealTFWage={plusIrregularDayMealTFWage}
					absentDaysOrdinaryWage={absentDaysOrdinaryWage}
					absentPaidHoliDaysOrdinaryWage={absentPaidHoliDaysOrdinaryWage}
					absentTimeWage={absentTimeWage}
					numUserFixedCAWageTF1={numUserFixedCAWageTF1}
					numUserFixedCAWageTF2={numUserFixedCAWageTF2}
					numUserFixedCAWageTF3={numUserFixedCAWageTF3}
					numUserFixedCAWageTF4={numUserFixedCAWageTF4}
					numUserFixedCAWage1={numUserFixedCAWage1}
					numUserFixedCAWage2={numUserFixedCAWage2}
					numUserFixedCAWage3={numUserFixedCAWage3}
					numUserFixedCAWage4={numUserFixedCAWage4}
					monthOWTText={monthOWTText}
					monthNWTText={monthNWTText}
					monthHoliBasicTimeText={monthHoliBasicTimeText}
					monthOWTHoliText={monthOWTHoliText}
					monthNWTHoliText={monthNWTHoliText}
					isExceptTimeLawWorker={isExceptTimeLawWorker}
					gumakStyle={gumakStyle}
					numAbsentTimes={numAbsentTimes}
					maternityDeductWage={maternityDeductWage}
					maternityFinalDedectOrdWage={maternityFinalDedectOrdWage}
					maternityFinalDedectLawOverWage={maternityFinalDedectLawOverWage}
					annualLeavesIncludeMonthWage={annualLeavesIncludeMonthWage}
					workDayOTimePaidWage={workDayOTimePaidWage}
					workDayNTimePaidWage={workDayNTimePaidWage}
					holiBTimePaidWage={holiBTimePaidWage}
					holiOTimePaidWage={holiOTimePaidWage}
					holiNTimePaidWage={holiNTimePaidWage}
					sumOfLawOverWage={sumOfLawOverWage}
					absentDaysLawOverWage={absentDaysLawOverWage}
					plusRegularBonus={plusRegularBonus}
					plusRegularBonusComment={plusRegularBonusComment}
					plusIrregularBonus={plusIrregularBonus}
					plusIrregularBonusComment={plusIrregularBonusComment}
					plusUnusedAnnualLeavesWage={plusUnusedAnnualLeavesWage}
					plusUnusedAnnualLeavesWageComment={plusUnusedAnnualLeavesWageComment}
					plusLastYearUnusedAnnualLeavesWage={
						plusLastYearUnusedAnnualLeavesWage
					}
					plusLastYearUnusedAnnualLeavesWageComment={
						plusLastYearUnusedAnnualLeavesWageComment
					}
					plusRetiredUnusedAnnualLeaves={plusRetiredUnusedAnnualLeaves}
					plusSpecialRandomWage={plusSpecialRandomWage}
					plusLastMonthAverWage={plusLastMonthAverWage}
					plusLastMonthAverWageComment={plusLastMonthAverWageComment}
					plusPersonalIncentive={plusPersonalIncentive}
					plusPersonalIncentiveComment={plusPersonalIncentiveComment}
					selectedPaydocu={selectedPaydocu}
					weekLBTime={weekLBTime}
					numOfWorkers={numOfWorkers}
					hourOrdinaryWage={hourOrdinaryWage}
					paidHolidayLBTime={paidHolidayLBTime}
					monthOWT={monthOWT}
					monthNWT={monthNWT}
					monthHoliBasicTime={monthHoliBasicTime}
					monthOWTHoli={monthOWTHoli}
					monthNWTHoli={monthNWTHoli}
					plusMonthOWT={plusMonthOWT}
					plusMonthNWT={plusMonthNWT}
					plusMonthHoliBasicTime={plusMonthHoliBasicTime}
					plusMonthOWTHoli={plusMonthOWTHoli}
					plusMonthNWTHoli={plusMonthNWTHoli}
					monthBasicWage={monthBasicWage}
					cTFMealWage={cTFMealWage}
					cTFCarWage={cTFCarWage}
					cTFChildWage={cTFChildWage}
					cTFStudyWage={cTFStudyWage}
					cTFEtc={cTFEtc}
					cOWTitle={cOWTitle}
					cOWJob={cOWJob}
					cOWLicense={cOWLicense}
					cOWLongTerm={cOWLongTerm}
					cOWMonthlyBonus={cOWMonthlyBonus}
					cOWEtc={cOWEtc}
					cOWChildManageStudyWage={cOWChildManageStudyWage}
					cOWChildManageStudyWageComment={cOWChildManageStudyWageComment}
					cOWChildStudyWage={cOWChildStudyWage}
					cOWChildStudyWageComment={cOWChildStudyWageComment}
					cOWChildCareWage={cOWChildCareWage}
					cOWChildCareWageComment={cOWChildCareWageComment}
					cOWChildActivityWage={cOWChildActivityWage}
					cOWChildActivityWageComment={cOWChildActivityWageComment}
					cAWage1={cAWage1}
					cAWage2={cAWage2}
					cAWage3={cAWage3}
					cAWage4={cAWage4}
					cAWageTF1={cAWageTF1}
					cAWENameTF1={cAWENameTF1}
					cAWageTF1Comment={cAWageTF1Comment}
					cAWageTF2={cAWageTF2}
					cAWENameTF2={cAWENameTF2}
					cAWageTF2Comment={cAWageTF2Comment}
					cAWageTF3={cAWageTF3}
					cAWENameTF3={cAWENameTF3}
					cAWageTF3Comment={cAWageTF3Comment}
					cAWageTF4={cAWageTF4}
					cAWENameTF4={cAWENameTF4}
					cAWageTF4Comment={cAWageTF4Comment}
					userFixedCAWageTF1={userFixedCAWageTF1}
					userFixedCAWageTF1Name={userFixedCAWageTF1Name}
					userFixedCAWageTF1Comment={userFixedCAWageTF1Comment}
					userFixedCAWageTF2={userFixedCAWageTF2}
					userFixedCAWageTF2Name={userFixedCAWageTF2Name}
					userFixedCAWageTF2Comment={userFixedCAWageTF2Comment}
					userFixedCAWageTF3={userFixedCAWageTF3}
					userFixedCAWageTF3Name={userFixedCAWageTF3Name}
					userFixedCAWageTF3Comment={userFixedCAWageTF3Comment}
					userFixedCAWageTF4={userFixedCAWageTF4}
					userFixedCAWageTF4Name={userFixedCAWageTF4Name}
					userFixedCAWageTF4Comment={userFixedCAWageTF4Comment}
					userFixedCAWage1={userFixedCAWage1}
					userFixedCAWage1Name={userFixedCAWage1Name}
					userFixedCAWage1Comment={userFixedCAWage1Comment}
					userFixedCAWage2={userFixedCAWage2}
					userFixedCAWage2Name={userFixedCAWage2Name}
					userFixedCAWage2Comment={userFixedCAWage2Comment}
					userFixedCAWage3={userFixedCAWage3}
					userFixedCAWage3Name={userFixedCAWage3Name}
					userFixedCAWage3Comment={userFixedCAWage3Comment}
					userFixedCAWage4={userFixedCAWage4}
					userFixedCAWage4Name={userFixedCAWage4Name}
					userFixedCAWage4Comment={userFixedCAWage4Comment}
					cAWage5={cAWage5}
					cAWEName5={cAWEName5}
					cAWage5Comment={cAWage5Comment}
					cAWage6={cAWage6}
					cAWEName6={cAWEName6}
					cAWage6Comment={cAWage6Comment}
					cAWage7={cAWage7}
					cAWEName7={cAWEName7}
					cAWage7Comment={cAWage7Comment}
					monthBasicWageComment={monthBasicWageComment}
					cTFMealWageComment={cTFMealWageComment}
					cTFCarWageComment={cTFCarWageComment}
					cTFChildWageComment={cTFChildWageComment}
					cTFStudyWageComment={cTFStudyWageComment}
					cTFEtcComment={cTFEtcComment}
					cOWTitleComment={cOWTitleComment}
					cOWJobComment={cOWJobComment}
					cOWLicenseComment={cOWLicenseComment}
					cOWLongTermComment={cOWLongTermComment}
					cOWMonthlyBonusComment={cOWMonthlyBonusComment}
					cOWEtcComment={cOWEtcComment}
					cAWage1Comment={cAWage1Comment}
					cAWage2Comment={cAWage2Comment}
					cAWage3Comment={cAWage3Comment}
					cAWage4Comment={cAWage4Comment}
					daysOfInterInAbusent={daysOfInterInAbusent}
					daysOfInterOutAbusent={daysOfInterOutAbusent}
					daysOfInterInAbusentOrdinaryWage={daysOfInterInAbusentOrdinaryWage}
					daysOfInterInAbusentLawOverWage={daysOfInterInAbusentLawOverWage}
					daysOfInterOutAbusentOrdinaryWage={daysOfInterOutAbusentOrdinaryWage}
					daysOfInterOutAbusentLawOverWage={daysOfInterOutAbusentLawOverWage}
					absentDays={absentDays}
					absentPaidHoliDays={absentPaidHoliDays}
					absentTimes={absentTimes}
					daysInMonth={daysInMonth}
					monthOrdinaryWage={monthOrdinaryWage}
					cAWEName1={cAWEName1}
					cAWEName2={cAWEName2}
					cAWEName3={cAWEName3}
					cAWEName4={cAWEName4}
				/>
			) : (
				<PaydocuPayDetailDayTimeWorker
					daylyTimePay={daylyTimePay}
					isExceptTimeLawWorker={isExceptTimeLawWorker}
					gumakStyle={gumakStyle}
					weekLBTime={weekLBTime}
					numOfWorkers={numOfWorkers}
					centerStyle={centerStyle}
					totalDayWorkerWage={totalDayWorkerWage}
					dayWorkerWage={dayWorkerWage}
					countOfNomalDayWork={countOfNomalDayWork}
					countOfHoliDayWork={countOfHoliDayWork}
					totalDayWorkerHoliWage={totalDayWorkerHoliWage}
					dayWorkerHoliWage={dayWorkerHoliWage}
					totalDayWorkerOverWorkDayWage={totalDayWorkerOverWorkDayWage}
					countOfOverWorkingDayWork={countOfOverWorkingDayWork}
					dayWorkerOverWorkDayWage={dayWorkerOverWorkDayWage}
					dayWorkerCAWage5={dayWorkerCAWage5}
					dayWorkerCAWEName5={dayWorkerCAWEName5}
					dayWorkerCAWage5Comment={dayWorkerCAWage5Comment}
					dayWorkerCAWage6={dayWorkerCAWage6}
					dayWorkerCAWage6Comment={dayWorkerCAWage6Comment}
					dayWorkerCAWEName6={dayWorkerCAWEName6}
					dayWorkerCAWage7={dayWorkerCAWage7}
					dayWorkerCAWEName7={dayWorkerCAWEName7}
					dayWorkerCAWage7Comment={dayWorkerCAWage7Comment}
					dayWorkerPlusOTime={dayWorkerPlusOTime}
					numDayWorkerOWage={numDayWorkerOWage}
					dayWokerTimeWage={dayWokerTimeWage}
					dayWorkerPlusNTime={dayWorkerPlusNTime}
					numDayWorkerNWage={numDayWorkerNWage}
					dayWorkerPlusHBTime={dayWorkerPlusHBTime}
					numDayWorkerHBwage={numDayWorkerHBwage}
					dayWorkerPlusHOTime={dayWorkerPlusHOTime}
					numDayWorkerHOwage={numDayWorkerHOwage}
					dayWorkerPlusHNTime={dayWorkerPlusHNTime}
					numDayWorkerHNwage={numDayWorkerHNwage}
					dayWorkerAbsentTimes={dayWorkerAbsentTimes}
					dayWorkerAbsentTimeWage={dayWorkerAbsentTimeWage}
					timeWorkerPlusMonthOWT={timeWorkerPlusMonthOWT}
					timeWorkerPlusMonthNWT={timeWorkerPlusMonthNWT}
					timeWorkerPlusMonthHoliBasicTime={timeWorkerPlusMonthHoliBasicTime}
					timeWorkerPlusMonthOWTHoli={timeWorkerPlusMonthOWTHoli}
					timeWorkerPlusMonthOffBasicTime={timeWorkerPlusMonthOffBasicTime}
					timeWorkerPlusMonthOWTOff={timeWorkerPlusMonthOWTOff}
					numOWageForTW={numOWageForTW}
					numNWageForTW={numNWageForTW}
					numHBWageForTW={numHBWageForTW}
					numHOWageForTW={numHOWageForTW}
					numOffWageForTW={numOffWageForTW}
					numOffOWageForTW={numOffOWageForTW}
					totalTimeWorkerWage={totalTimeWorkerWage}
					totalTimeWorkerPaidHoliWage={totalTimeWorkerPaidHoliWage}
					timeWorkerTimeWage={timeWorkerTimeWage}
					timeWorkerTimePaidHoliWage={timeWorkerTimePaidHoliWage}
					week14OverBLTime={week14OverBLTime}
					numPaidHoliTimeW={numPaidHoliTimeW}
					finalPaidHoliWageTimeSum={finalPaidHoliWageTimeSum}
					monthTotalWorkTime={monthTotalWorkTime}
					finalPaidHoliWageTime={finalPaidHoliWageTime}
					normalWorkerWeekWorkDays={normalWorkerWeekWorkDays}
					paidHoliWageDays={paidHoliWageDays}
					paidHoliWageText={paidHoliWageText}
					dayWorkerSpecialBonus={dayWorkerSpecialBonus}
					timeWorkerSpecialBonus={timeWorkerSpecialBonus}
					numPaidHoliDayW={numPaidHoliDayW}
				/>
			)}
			{isBizWorker ? (
				<Table.Row>
					<Table.Cell className='gubun' style={centerStyle}>
						공제전
					</Table.Cell>
					<Table.Cell className='hangmok' style={centerStyle}>
						사업소득 총액
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(roundUp(bizTotalIncome))}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						세전 사업소득 총액
					</Table.Cell>
				</Table.Row>
			) : (
				<Table.Row>
					<Table.Cell className='gubun' style={centerStyle}>
						공제전
					</Table.Cell>
					<Table.Cell className='hangmok' style={centerStyle}>
						임금총액
					</Table.Cell>
					<Table.Cell className='gumak' style={gumakStyle}>
						{numberToCommaString(
							roundUp(
								dayWorker ? dayWorkerTotalMonthWageAmount : totalMonthWageAmount
							)
						)}
					</Table.Cell>
					<Table.Cell colSpan='6' className='sulmung'>
						세전 임금 총액
					</Table.Cell>
				</Table.Row>
			)}
			<PaydocuDeductDetail
				isBizWorker={isBizWorker}
				workerRetiredDate={workerRetiredDate}
				selectedClientReq4In={selectedClientReq4In}
				numFinalRetireHealthCareRecalFee={numFinalRetireHealthCareRecalFee}
				numFinalRetireOldCareRecalFee={numFinalRetireOldCareRecalFee}
				dayWorker={dayWorker}
				centerStyle={centerStyle}
				plusDeductHealthCare={plusDeductHealthCare}
				plusDeductHealthCareComment={plusDeductHealthCareComment}
				plusDeductOldCare={plusDeductOldCare}
				plusDeductOldCareComment={plusDeductOldCareComment}
				plusDeductWorkTax={plusDeductWorkTax}
				plusDeductWorkTaxComment={plusDeductWorkTaxComment}
				plusDeductWorkRegionTax={plusDeductWorkRegionTax}
				plusDeductWorkRegionTaxComment={plusDeductWorkRegionTaxComment}
				plusDeductDuru={plusDeductDuru}
				plusMinus4Ins={plusMinus4Ins}
				plusDeductDuruComment={plusDeductDuruComment}
				plusMinus4InsComment={plusMinus4InsComment}
				plusDeductWorkerCompanyLoan={plusDeductWorkerCompanyLoan}
				plusDeductWorkerCompanyLoanComment={plusDeductWorkerCompanyLoanComment}
				isInterInWorker={isInterInWorker}
				selectedPaydocu={selectedPaydocu}
				year={year}
				inputTax={inputTax}
				numMealWage={numMealWage}
				numCarWage={numCarWage}
				numChildWage={numChildWage}
				numStudyWage={numStudyWage}
				numTFEtcWage={numTFEtcWage}
				totalMonthWageAmount={totalMonthWageAmount}
				// selectedClientReq4In={selectedClientReq4In}
				weekLBTime={weekLBTime}
				inputWageStyle={inputWageStyle}
				inputCommentStyle={inputCommentStyle}
				deductEtcName1={deductEtcName1}
				deductEtcAWage1={deductEtcAWage1}
				deductEtcWage1Comment={deductEtcWage1Comment}
				deductEtcName2={deductEtcName2}
				deductEtcAWage2={deductEtcAWage2}
				deductEtcWage2Comment={deductEtcWage2Comment}
				deductEtcName3={deductEtcName3}
				deductEtcAWage3={deductEtcAWage3}
				deductEtcWage3Comment={deductEtcWage3Comment}
				handelInputChange={handelInputChange}
				userFixedTax={userFixedTax}
				userFixedRegionTax={userFixedRegionTax}
				userFixedHealthCare={userFixedHealthCare}
				userFixedOldCare={userFixedOldCare}
				userFixedPension={userFixedPension}
				userFixedNoJobInsurance={userFixedNoJobInsurance}
				userFixedTaxComment={userFixedTaxComment}
				userFixedRegionTaxComment={userFixedRegionTaxComment}
				userFixedHealthCareComment={userFixedHealthCareComment}
				userFixedOldCareComment={userFixedOldCareComment}
				userFixedPensionComment={userFixedPensionComment}
				userFixedNoJobInsuranceComment={userFixedNoJobInsuranceComment}
				calTax={calTax}
				calRegionTax={calRegionTax}
				calPension={calPension}
				calHealthCare={calHealthCare}
				calOldCare={calOldCare}
				calNoJobInsurance={calNoJobInsurance}
				dedectTaxEtc={dedectTaxEtc}
				deductWage={deductWage}
				taxText={taxText}
				regionTaxText={regionTaxText}
				noJobText={noJobText}
				pensionText={pensionText}
				oldCareText={oldCareText}
				healthCareText={healthCareText}
				taxPayType={taxPayType}
				calTaxRate={calTaxRate}
				pensionPayType={pensionPayType}
				pensionBaseWage={pensionBaseWage}
				healthCarePayType={healthCarePayType}
				healthCareBaseWage={healthCareBaseWage}
				oldCarePayType={oldCarePayType}
				oldCareBaseWage={oldCareBaseWage}
				noJobPayType={noJobPayType}
				noJobBaseWage={noJobBaseWage}
				taxFreeWageSum={taxFreeWageSum}
				taxWageSum={taxWageSum}
				numDeductEtcAWage1={numDeductEtcAWage1}
				numDeductEtcAWage2={numDeductEtcAWage2}
				numDeductEtcAWage3={numDeductEtcAWage3}
				dayWorkerUserFixedTax={dayWorkerUserFixedTax}
				dayWorkerUserFixedTaxComment={dayWorkerUserFixedTaxComment}
				dayWorkerCalTax={dayWorkerCalTax}
				dayWorkerUserFixedRegionTax={dayWorkerUserFixedRegionTax}
				dayWorkerUserFixedRegionTaxComment={dayWorkerUserFixedRegionTaxComment}
				dayWorkerCalRegionTax={dayWorkerCalRegionTax}
				dayWorkerUserFixedHealthCare={dayWorkerUserFixedHealthCare}
				dayWorkerUserFixedHealthCareComment={
					dayWorkerUserFixedHealthCareComment
				}
				dayWorkerCalHealthCare={dayWorkerCalHealthCare}
				dayWorkerUserFixedOldCare={dayWorkerUserFixedOldCare}
				dayWorkerUserFixedOldCareComment={dayWorkerUserFixedOldCareComment}
				dayWorkerCalOldCare={dayWorkerCalOldCare}
				dayWorkerUserFixedPension={dayWorkerUserFixedPension}
				dayWorkerUserFixedPensionComment={dayWorkerUserFixedPensionComment}
				dayWorkerCalPension={dayWorkerCalPension}
				dayWorkerUserFixedNoJobInsurance={dayWorkerUserFixedNoJobInsurance}
				dayWorkerUserFixedNoJobInsuranceComment={
					dayWorkerUserFixedNoJobInsuranceComment
				}
				dayWorkerCalNoJobInsurance={dayWorkerCalNoJobInsurance}
				dayWorkerHealthCareTextOption={dayWorkerHealthCareTextOption}
				dayWorkerNoJobTextOption={dayWorkerNoJobTextOption}
				dayWorkerPensionTextOption={dayWorkerPensionTextOption}
				dayWorkerOldCareTextOption={dayWorkerOldCareTextOption}
				dayWorkerTaxTextOption={dayWorkerTaxTextOption}
				dayWorkerRegionTaxTextOption={dayWorkerRegionTaxTextOption}
				dayWorkerDeductEtcWage1={dayWorkerDeductEtcWage1}
				dayWorkerDeductEtcName1={dayWorkerDeductEtcName1}
				dayWorkerDeductEtcWage1Comment={dayWorkerDeductEtcWage1Comment}
				dayWorkerDeductEtcWage2={dayWorkerDeductEtcWage2}
				dayWorkerDeductEtcName2={dayWorkerDeductEtcName2}
				dayWorkerDeductEtcWage2Comment={dayWorkerDeductEtcWage2Comment}
				dayWorkerDeductEtcWage3={dayWorkerDeductEtcWage3}
				dayWorkerDeductEtcName3={dayWorkerDeductEtcName3}
				dayWorkerDeductEtcWage3Comment={dayWorkerDeductEtcWage3Comment}
				dayWorkerDedectTaxEtc={dayWorkerDedectTaxEtc}
				dayWorkerDeductWage={dayWorkerDeductWage}
				dayWorkerPlusDeductHealthCare={dayWorkerPlusDeductHealthCare}
				dayWorkerPlusDeductHealthCareComment={
					dayWorkerPlusDeductHealthCareComment
				}
				dayWorkerPlusDeductOldCare={dayWorkerPlusDeductOldCare}
				dayWorkerPlusDeductOldCareComment={dayWorkerPlusDeductOldCareComment}
				dayWorkerPlusDeductWorkTax={dayWorkerPlusDeductWorkTax}
				dayWorkerPlusDeductWorkTaxComment={dayWorkerPlusDeductWorkTaxComment}
				dayWorkerPlusDeductWorkRegionTax={dayWorkerPlusDeductWorkRegionTax}
				dayWorkerPlusDeductWorkRegionTaxComment={
					dayWorkerPlusDeductWorkRegionTaxComment
				}
				dayWorkerPlusDeductDuru={dayWorkerPlusDeductDuru}
				dayWorkerPlusMinus4Ins={dayWorkerPlusMinus4Ins}
				dayWorkerPlusDeductDuruComment={dayWorkerPlusDeductDuruComment}
				dayWorkerPlusMinus4InsComment={dayWorkerPlusMinus4InsComment}
				dayWorkerPlusDeductWorkerCompanyLoan={
					dayWorkerPlusDeductWorkerCompanyLoan
				}
				dayWorkerPlusDeductWorkerCompanyLoanComment={
					dayWorkerPlusDeductWorkerCompanyLoanComment
				}
				bizDeductionAfterTaxComment={bizDeductionAfterTaxComment}
				bizDeductWage={bizDeductWage}
				bizIncomeTax={bizIncomeTax}
				bizIncomeRegionTax={bizIncomeRegionTax}
				bizDeductionAfterTax={bizDeductionAfterTax}
				bizDeductedTotal={bizDeductedTotal}
			/>
		</>
	);
}
