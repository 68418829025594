import {
	CREATE_CLIENTREQ_4IN,
	DELETE_CLIENTREQ_4IN,
	UPDATE_CLIENTREQ_4IN,
	FETCH_CLIENTREQS_4IN,
	FETCH_WORKER_USERS,
	CLEAR_WORKER_USERS,
	LISTEN_TO_CLIENTREQ_CHAT_4IN,
	CLEAR_COMMENTS_4IN,
	LISTEN_TO_SELECTED_CLIENTREQ_4IN,
	CLEAR_CLIENTREQS_4IN,
	SET_START_DATE_CLIENTREQ_4IN,
	RETAIN_STATE_CLIENTREQ_4IN,
	CLEAR_SELECTED_CLIENTREQ_4IN,
	CLEAR_CLIENTREQS_TABLE_4IN,
	FETCH_CLIENTREQS_TABLE_4IN,
	SET_FILTER_CLIENTREQ_4IN,
	AFTER_TAX_WAGE,
	SUM_TAX_FREE_WAGE,
	AFTER_PENSION_BASE_WAGE,
	AFTER_HEALTHCARE_BASE_WAGE,
	AFTER_NOJOB_BASE_WAGE,
	AFTER_NUM_OF_TAX_FAMILY,
	AFTER_NUM_OF_TAX_FAMILY_7_TO_20,
	FINAL_RETIRE_FUND,
	RETIRE_AVERAGE_FINAL_DAY_WAGE,
	RETIRE_FUND,
	FINAL_RETIRED_FUND_TAX,
	FINAL_RETIRED_FUND_REGION_TAX,
	SELECTED_WORKER_REGISTRY_TYPE,
	SET_ACTIVE_TAB,
	CLEAR_WORKER_REGISTRY_TYPE,
} from './clientReqConstants4In';

const initialState4In = {
	clientReqs4In: [],
	workerUsers: [],
	clientReqsTable4In: [],
	comments4In: [],
	moreClientReqs4In: true,
	selectedClientReq4In: null,
	lastVisible: null,
	filter: 'isHosting',
	startDate: new Date(),
	retainState: false,
	totalTaxWage: '',
	sumTaxFreeWage: '',
	pensionBaseWage: '',
	healthCareBaseWage: '',
	noJobBaseWage: '',
	numOfTaxFamily: 0,
	numOfTaxFamily7To20: 0,
	finalRetiredFund: 0,
	retireAverageFinalDayWage: '',
	retireFund: '',
	finalRetiredFundTax: '',
	finalRetiredFundRegionTax: '',
	workerRegistryType: '',
	activeTab: 0,
};

export default function clientReqReducer4In(
	state = initialState4In,
	{ type, payload }
) {
	switch (type) {
		case SELECTED_WORKER_REGISTRY_TYPE:
			return {
				...state,
				workerRegistryType: payload,
			};
		case CREATE_CLIENTREQ_4IN:
			return {
				...state,
				clientReqs4In: [...state.clientReqs4In, payload],
			};
		case UPDATE_CLIENTREQ_4IN:
			return {
				...state,
				clientReqs4In: [
					...state.clientReqs4In.filter((evt) => evt.id !== payload.id),
					payload,
				],
			};
		case DELETE_CLIENTREQ_4IN:
			return {
				...state,
				clientReqs4In: [
					...state.clientReqs4In.filter((evt) => evt.id !== payload),
				],
			};
		case FETCH_CLIENTREQS_4IN:
			return {
				...state,
				clientReqs4In: [
					...state.clientReqs4In,
					...(payload.clientReqs4In || []),
				],
				moreClientReqs4In: payload.moreClientReqs4In ?? state.moreClientReqs4In,
				lastVisible: payload.lastVisible ?? state.lastVisible,
			};
		case FETCH_WORKER_USERS:
			return {
				...state,
				workerUsers: [...state.workerUsers, ...payload.workerUsers],
			};
		case CLEAR_WORKER_USERS:
			return {
				...state,
				clientReqs4In: [],
			};
		case CLEAR_WORKER_REGISTRY_TYPE:
			return {
				...state,
				workerRegistryType: '',
			};
		case FETCH_CLIENTREQS_TABLE_4IN:
			return {
				...state,
				clientReqsTable4In: [
					...state.clientReqsTable4In,
					...payload.clientReqsTable4In,
				],
			};
		case LISTEN_TO_CLIENTREQ_CHAT_4IN:
			return {
				...state,
				comments4In: payload,
			};
		case CLEAR_COMMENTS_4IN:
			return {
				...state,
				comments4In: [],
			};
		case LISTEN_TO_SELECTED_CLIENTREQ_4IN:
			return {
				...state,
				selectedClientReq4In: payload,
			};
		case CLEAR_SELECTED_CLIENTREQ_4IN:
			return {
				...state,
				selectedClientReq4In: null,
			};
		case CLEAR_CLIENTREQS_4IN:
			return {
				...state,
				clientReqs4In: [],
				moreClientReqs4In: true,
				lastVisible: null,
			};
		case CLEAR_CLIENTREQS_TABLE_4IN:
			return {
				...state,
				clientReqsTable4In: [],
			};
		case SET_FILTER_CLIENTREQ_4IN:
			return {
				...state,
				retainState: false,
				moreClientReqs4In: true,
				filter: payload,
			};
		case SET_START_DATE_CLIENTREQ_4IN:
			return {
				...state,
				retainState: false,
				moreClientReqs4In: true,
				startDate: payload,
			};
		case RETAIN_STATE_CLIENTREQ_4IN:
			return {
				...state,
				retainState: true,
			};
		case AFTER_TAX_WAGE:
			return {
				...state,
				totalTaxWage: payload,
			};
		case AFTER_PENSION_BASE_WAGE:
			return {
				...state,
				pensionBaseWage: payload,
			};
		case AFTER_HEALTHCARE_BASE_WAGE:
			return {
				...state,
				healthCareBaseWage: payload,
			};
		case AFTER_NOJOB_BASE_WAGE:
			return {
				...state,
				noJobBaseWage: payload,
			};
		case AFTER_NUM_OF_TAX_FAMILY:
			return {
				...state,
				numOfTaxFamily: payload,
			};
		case AFTER_NUM_OF_TAX_FAMILY_7_TO_20:
			return {
				...state,
				numOfTaxFamily7To20: payload,
			};
		case SUM_TAX_FREE_WAGE:
			return {
				...state,
				sumTaxFreeWage: payload,
			};
		case FINAL_RETIRE_FUND:
			return {
				...state,
				finalRetiredFund: payload,
			};
		case RETIRE_AVERAGE_FINAL_DAY_WAGE:
			return {
				...state,
				retireAverageFinalDayWage: payload,
			};
		case RETIRE_FUND:
			return {
				...state,
				retireFund: payload,
			};
		case FINAL_RETIRED_FUND_TAX:
			return {
				...state,
				finalRetiredFundTax: payload,
			};
		case FINAL_RETIRED_FUND_REGION_TAX:
			return {
				...state,
				finalRetiredFundRegionTax: payload,
			};
		case SET_ACTIVE_TAB:
			return {
				...state,
				activeTab: payload,
			};
		default:
			return state;
	}
}
