import React from 'react';
import { isValid, parseISO, format } from 'date-fns';
import {
	josaGaType,
	josaUnType,
	josaWaType,
} from '../../../../../app/common/util/util';

export default function ClientReqDLCWorkPeriod({
	selectedClientReq4In,
	numOfWorkers,
	companyName,
	worker,
	contractOldWorker,
}) {
	const safeFormat = (date, dateFormat) => {
		const parsedDate = typeof date === 'string' ? parseISO(date) : date;
		if (isValid(parsedDate)) {
			return format(parsedDate, dateFormat);
		}
		return ''; // Return an empty string or any placeholder for invalid dates
	};

	//계약직 입사일과 촉탁직 입사일 변경
	const againStartEnterDate = selectedClientReq4In?.againContractStartDate
		? selectedClientReq4In?.againContractStartDate
		: null;
	const afterRetireAgainStartEnterDate =
		selectedClientReq4In?.afterRetireAgainContractStartDate
			? selectedClientReq4In?.afterRetireAgainContractStartDate
			: null;
	const changeEnterDate =
		contractOldWorker && afterRetireAgainStartEnterDate
			? afterRetireAgainStartEnterDate
			: againStartEnterDate
			? againStartEnterDate
			: null;
	const judgeAgainEnterDate = changeEnterDate
		? changeEnterDate
		: selectedClientReq4In?.workerEnteredDate;
	const eDate = safeFormat(judgeAgainEnterDate, 'yyyy-MM-dd');

	// 일반 계약종료일
	const cEDate = selectedClientReq4In?.contractEndDate
		? safeFormat(selectedClientReq4In.contractEndDate, 'yyyy-MM-dd')
		: null;
	// 촉탁 계약종료일
	const afterRetireAgainContractEndDate =
		selectedClientReq4In?.afterRetireAgainContractEndDate
			? selectedClientReq4In?.afterRetireAgainContractEndDate
			: null;

	const changeEndDate =
		contractOldWorker && afterRetireAgainContractEndDate
			? afterRetireAgainContractEndDate
			: cEDate
			? cEDate
			: '';
	const eEndDate = changeEndDate ? safeFormat(changeEndDate, 'yyyy-MM-dd') : '';

	// 수습 기간
	const traineeStartDate = selectedClientReq4In?.traineeStartDate
		? safeFormat(selectedClientReq4In?.traineeStartDate, 'yyyy-MM-dd')
		: null;
	const traineeEndDate = selectedClientReq4In?.traineeEndDate
		? safeFormat(selectedClientReq4In?.traineeEndDate, 'yyyy-MM-dd')
		: null;

	//회사 국어조사
	const companyUn = josaUnType(companyName);
	const companyWa = josaWaType(companyName);

	//직원 국어조사
	const workerUn = josaUnType(worker);
	const workerGa = josaGaType(worker);
	const workerWa = josaWaType(worker);

	const jung1Hang =
		numOfWorkers >= 10 ? (
			<li>
				① {worker}의 근로계약기간은 {eDate}부터 {companyName} 규정 상의
				정년까지로 한다.
			</li>
		) : (
			<li>
				① {workerUn} 입사일인 {eDate}부터 정규직으로 한다.
			</li>
		);
	const jung2Hang = (
		<li>
			{`② ${worker}의 수습기간은 ${traineeStartDate}부터 ${traineeEndDate}로 하고, 동 기간동안 회사 규정 등에서 정하는 평가기준에 미달하는 경우 또는 매월 진행되는 수습 평가 점수가 60 점이하인 경우에는 
      수습 종료와 동시에 ${companyUn} ${workerWa} 근로관계를 종료할 수 있다.`}
		</li>
	);
	const jung3Hang = (
		<li>
			{`③ 제2항에 따른 ${worker}의 수습기간은 당연히 ${worker}의 계속 근로 연수에 포함된다.`}
		</li>
	);
	const bi1Hang = (
		<li>
			{`① ${worker}의 근로계약기간은 ${eDate}부터 ${eEndDate}로 하되, 계약기간만료일 전까지 당사자간에 계약 연장에 관한 별도의 합의가 없는 한, 계약종료일 다음날 ${companyWa} ${worker}의 근로관계는 종료된다. 
      단, ${companyName}의 행정상의 착오로 ${eEndDate} 이후에도 ${workerGa} 계속 근무하고 있는 경우의 근로계약기간은 ${companyWa} ${workerGa} 최초 계약기간 만큼 연장된 것으로 간주한다.`}
		</li>
	);
	const bi5Over2Hang = (
		<li>
			{contractOldWorker
				? `② 제1항 및 단서에도 불구하고 ${worker}의 입사일부터 근속년수가 2년을 초과하면 초과한 날부터 기간제 근로자 및 단시간 근로자 보호등에 관한 법률에 의거 정규직으로 전환되는 것이 원칙이지만, 
          ${workerUn} 기간제 및 단시간근로자 보호 등에 관한 법률 제4조 제1항 제4호의 고용상 연령차별금지 및 고령자고용촉진에 관한 법률 제2조 제1호의 고령자이므로 최초 촉탁직 계약직 근로일부터 계속근로기간이 2년을 초과하여도 정규직으로 전환되지 아니한다.`
				: `② 제1항 및 단서에도 불구하고 ${worker}의 입사일부터 근속년수가 2년을 초과하면 초과한 날부터 기간제 근로자 및 단시간 근로자 보호등에 관한 법률에 의거 정규직으로 전환된다. 
          단, 동 법률 제4조 단서조항에 따른 정규직 전환 예외에 해당하는 경우에 ${workerUn} 계속근로기간이 2년을 초과하여도 정규직으로 전환되지 아니한다.`}
		</li>
	);
	const bi5Under2Hang = (
		<li>
			{`② ${companyUn} 5인 미만 사업장으로 소속 근로자의 계속근로기간 2년초과시 적용되는 기간제 근로자 및 단시간 근로자 보호등에 관한 법률상 정규직 전환규정은 적용되지 아니한다. `}
		</li>
	);
	const bi3Hang = (
		<li>
			{`③ ${worker}의 수습기간은 ${traineeStartDate}부터 ${traineeEndDate}로 하고, 동 기간동안 취업규칙 등에서 정하는 평가기준에 미달하는 경우 또는 매월 진행되는 수습 평가 점수가 60 점이하인 경우에는 
      수습 종료와 동시에 ${companyUn} ${workerWa} 근로관계를 종료할 수 있다.`}
		</li>
	);
	const bi4Hang = (
		<li>
			{`④ 제3항에 따른 ${worker}의 수습기간은 당연히 ${worker}의 계속 근로 연수에 포함된다.`}
		</li>
	);

	return (
		<>
			<div className='jau__contents'>
				<span style={{ fontSize: '1rem' }}>
					<strong>{`제1조 ${
						contractOldWorker ? '촉탁 계약직' : cEDate ? '계약직 ' : ''
					} 근로계약기간 ${
						traineeStartDate && traineeEndDate ? '및 수습계약기간' : ''
					}`}</strong>
				</span>
				{numOfWorkers >= 5 ? (
					<>
						{traineeStartDate && traineeEndDate ? (
							<>
								{!eEndDate ? (
									<ul>
										{jung1Hang}
										{jung2Hang}
										{jung3Hang}
									</ul>
								) : (
									<ul>
										{bi1Hang}
										{bi5Over2Hang}
										{bi3Hang}
										{bi4Hang}
									</ul>
								)}
							</>
						) : (
							<>
								{!eEndDate ? (
									<ul>{jung1Hang}</ul>
								) : (
									<ul>
										{bi1Hang}
										{bi5Over2Hang}
									</ul>
								)}
							</>
						)}
					</>
				) : (
					<>
						{traineeStartDate && traineeEndDate ? (
							<>
								{!eEndDate ? (
									<ul>
										{jung1Hang}
										{jung2Hang}
										{jung3Hang}
									</ul>
								) : (
									<ul>
										{bi1Hang}
										{bi5Under2Hang}
										{bi3Hang}
										{bi4Hang}
									</ul>
								)}
							</>
						) : (
							<>
								{!eEndDate ? (
									<ul>{jung1Hang}</ul>
								) : (
									<ul>
										{bi1Hang}
										{bi5Under2Hang}
									</ul>
								)}
							</>
						)}
					</>
				)}
			</div>
		</>
	);
}
