import React, { useEffect, useRef, useState } from 'react';
import { Table, Button } from 'semantic-ui-react';
import * as XLSX from 'xlsx';

function SortableTable({
	headers = [], // headers의 기본값 설정
	data = [], // data의 기본값 설정
	sortFunctions = {},
	footer = null,
	title = '',
	customRenderers = {},
	noExcelButton = true,
}) {
	const scrollContainerRef = useRef(null);
	const [maxHeight, setMaxHeight] = useState('500px'); // 초기 상태를 '500px'로 설정

	const [sortColumn, setSortColumn] = useState(null);
	const [sortDirection, setSortDirection] = useState(null);

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(10);

	// sortedData 상태 추가
	const [sortedData, setSortedData] = useState(data);

	useEffect(() => {
		let newSortedData = [...data];
		if (sortColumn && sortDirection && sortFunctions[sortColumn]) {
			newSortedData.sort((a, b) => {
				const compareResult = sortFunctions[sortColumn](a, b);
				return sortDirection === 'desc' ? -compareResult : compareResult;
			});
		}
		setSortedData(newSortedData);
	}, [data, sortColumn, sortDirection, sortFunctions]);

	const handleSort = (column) => {
		const newDirection =
			sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
		setSortColumn(column);
		setSortDirection(newDirection);
	};

	// 페이지네이션을 위한 계산을 정렬된 데이터에 대해 적용
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

	// 페이지 번호를 설정하기 위한 함수
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	if (sortColumn && sortDirection && sortFunctions[sortColumn]) {
		sortedData.sort((a, b) => {
			const compareResult = sortFunctions[sortColumn](a, b);
			return sortDirection === 'desc' ? -compareResult : compareResult;
		});
	}
	const companyName = data.map((row) => row.companyName)[0];

	const exportToExcel = () => {
		// 첫 번째 행에 한글 레이블 추가
		const headerLabels = headers.map((header) => header.label);

		const processedData = data?.map((row) => {
			return headers?.map((header) => {
				let cellValue = row[header.key];

				// If it's the date field, return the value directly without any conversion
				if (header.key === 'workerEnteredDate') {
					return cellValue;
				}

				// If the cell value contains a comma, remove it and try to convert it to a number
				if (typeof cellValue === 'string' && cellValue?.includes(',')) {
					cellValue = cellValue.replace(/,/g, '');

					// Convert string to number if it's a valid number
					const numberValue = parseFloat(cellValue);
					if (!isNaN(numberValue) && /^-?\d+(\.\d+)?$/.test(cellValue)) {
						return numberValue;
					}
				}
				if (typeof cellValue === 'string') {
					// Convert string to number if it's a valid number
					const numberValue = parseFloat(cellValue);
					if (!isNaN(numberValue) && /^-?\d+(\.\d+)?$/.test(cellValue)) {
						return numberValue;
					}
				}

				return cellValue;
			});
		});
		// Calculate the number of columns
		const totalColumns = headerLabels.length;

		// Create title row
		const tableTitle = `${companyName} ${title}`;
		const titleRow = Array(totalColumns).fill('');
		titleRow[0] = tableTitle;

		const mergedData = [titleRow, headerLabels, ...processedData];
		const ws = XLSX.utils.aoa_to_sheet(mergedData);

		// Merge the title cell across the width of the table
		ws['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: totalColumns - 1 } }];

		// Style the title cell
		ws['A1'].s = {
			font: { bold: true, sz: 14 },
			alignment: { vertical: 'center', horizontal: 'center' },
		};

		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Data');
		XLSX.writeFile(wb, `${title}.xlsx`);
	};

	useEffect(() => {
		const updateMaxHeight = () => {
			// 여기서는 예시로 화면 높이에서 100px를 빼서 설정합니다. 필요에 따라 조정하세요.
			setMaxHeight(`${window.innerHeight - 100}px`);
		};

		// 컴포넌트 마운트 시에 높이를 설정
		updateMaxHeight();

		// 브라우저 창 크기가 변경될 때마다 높이를 업데이트
		window.addEventListener('resize', updateMaxHeight);

		// 컴포넌트 언마운트 시 이벤트 리스너 제거
		return () => window.removeEventListener('resize', updateMaxHeight);
	}, []); // 의존성 배열을 빈 배열로 설정하여 컴포넌트가 마운트될 때만 실행

	return (
		<>
			<div ref={scrollContainerRef} style={{ overflowY: 'auto', maxHeight }}>
				<hr />
				{noExcelButton && (
					<Button color='blue' onClick={exportToExcel}>
						엑셀파일 추출
					</Button>
				)}
				<div
					style={{
						maxWidth: '100%',
						overflowX: 'auto',
						overflowY: 'auto',
						marginTop: '20px',
					}}>
					<Table
						style={{
							overflowX: 'auto',
							width: '100%',
							whiteSpace: 'nowrap',
							borderCollapse: 'collapse',
							border: '1',
							textAlign: 'center',
						}}
						id='table-to-xls'>
						<Table.Header>
							<Table.Row>
								{headers.map((header, index) => (
									<Table.HeaderCell
										key={index}
										onClick={() => handleSort(header.key)}>
										{header.label}
										{sortColumn === header.key &&
											(sortDirection === 'asc' ? '▲' : '▼')}
									</Table.HeaderCell>
								))}
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{currentItems.map((row, rowIndex) => (
								<Table.Row key={rowIndex}>
									{headers.map((header, index) => (
										<Table.Cell key={index}>
											{customRenderers && customRenderers[header.key]
												? customRenderers[header.key](row)
												: String(row[header.key] || '')}
										</Table.Cell>
									))}
								</Table.Row>
							))}
						</Table.Body>
						{footer && <Table.Footer>{footer}</Table.Footer>}
					</Table>
					<Pagination
						itemsPerPage={itemsPerPage}
						totalItems={data.length}
						paginate={paginate}
						currentPage={currentPage}
					/>
				</div>
			</div>
		</>
	);
}

function Pagination({ itemsPerPage, totalItems, paginate, currentPage }) {
	const totalPages = Math.ceil(totalItems / itemsPerPage);
	const pageNumberLimit = 10;
	let maxPageNumberLimit = Math.min(
		Math.ceil(currentPage / pageNumberLimit) * pageNumberLimit,
		totalPages
	);
	let minPageNumberLimit = maxPageNumberLimit - pageNumberLimit + 1;

	if (minPageNumberLimit < 1) {
		minPageNumberLimit = 1;
		maxPageNumberLimit = Math.min(pageNumberLimit, totalPages);
	}

	const pageNumbers = [];
	for (let i = minPageNumberLimit; i <= maxPageNumberLimit; i++) {
		pageNumbers.push(i);
	}

	return (
		<nav>
			<ul className='pagination'>
				{currentPage > 1 && (
					<li className='page-item'>
						<a
							onClick={(e) => {
								e.preventDefault();
								paginate(1);
							}}
							href='!#'
							className='page-link'>
							제일처음
						</a>
					</li>
				)}
				{minPageNumberLimit > 1 && (
					<li className='page-item'>
						<a
							onClick={(e) => {
								e.preventDefault();
								paginate(minPageNumberLimit - 1);
							}}
							href='!#'
							className='page-link'>
							이전 10개
						</a>
					</li>
				)}
				{pageNumbers.map((number) => (
					<li
						key={number}
						className={
							number === currentPage ? 'page-item active' : 'page-item'
						}>
						<a
							onClick={(e) => {
								e.preventDefault();
								paginate(number);
							}}
							href='!#'
							className='page-link'>
							{number}
						</a>
					</li>
				))}
				{maxPageNumberLimit < totalPages && (
					<li className='page-item'>
						<a
							onClick={(e) => {
								e.preventDefault();
								paginate(maxPageNumberLimit + 1);
							}}
							href='!#'
							className='page-link'>
							다음 10개
						</a>
					</li>
				)}
				{currentPage < totalPages && (
					<li className='page-item'>
						<a
							onClick={(e) => {
								e.preventDefault();
								paginate(totalPages);
							}}
							href='!#'
							className='page-link'>
							제일마지막
						</a>
					</li>
				)}
			</ul>
		</nav>
	);
}

export default SortableTable;
