import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
	numberToCommaString,
	safeStringCompare,
	sumOfBizDeductAfterTaxCom,
	sumOfBizDeductWageCom,
	sumOfBizIncomeRegionTaxCom,
	sumOfBizIncomeTaxCom,
	sumOfBizTotalIncomeCom,
	sumOfBizIncomeCom,
} from '../../../app/common/util/util';
import SortableTable from '../../../app/common/form/SortableTable';
import { decrypto } from '../../../app/firestore/firestoreService';
export default function PaydocusTableListBiz({
	paydocus,
	searchResults,
	setSearchResults,
	searchTerm,
	titleOfTable,
}) {
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { clientInfo } = useSelector((state) => state.companyReducer);
	const [loading, setLoading] = useState(true);

	const id = clientInfo?.id
		? clientInfo?.id
		: clientInfo?.clientUid
		? clientInfo?.clientUid
		: currentUserProfile?.id;

	const authLevel = currentUserProfile?.authLevel;
	const isSuperAdmin = authLevel >= 100 ? true : false;

	// searchTerm 변경 시 searchResults 상태 업데이트
	useEffect(() => {
		setLoading(true);
		const filteredResults = searchTerm
			? paydocus.filter(
					(paydocu) =>
						paydocu?.workerInfo?.worker
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase()) ||
						paydocu?.companyInfo?.companyName
							?.toLowerCase()
							?.includes(searchTerm.toLowerCase())
			  )
			: paydocus;
		setSearchResults(filteredResults);
		setLoading(false);
	}, [searchTerm, paydocus, setSearchResults]);

	const bizWorkers = searchResults.filter((item) => item.isBizWorker);

	const bizDeductionAfterTaxComment = bizWorkers.filter(
		(a) => a.bizDeductionAfterTaxComment
	)[0]?.bizDeductionAfterTaxComment;

	const headersCompany = [
		{ key: 'companyName', label: '회사명' },
		isSuperAdmin && { key: 'yearMonth', label: '년월' },
		isSuperAdmin && { key: 'workerSocialNumber', label: '주민등록번호' },
		{ key: 'year', label: '년' },
		{ key: 'month', label: '월' },
		{ key: 'workerComNumber', label: '사번' },
		{ key: 'workerDivision', label: '부서명' },
		{ key: 'workerTitle', label: '직위/직책' },
		{ key: 'worker', label: '직원명' },
		{ key: 'workerEnteredDate', label: '입사일' },
		{ key: 'bizIncome', label: '사업소득' },
		{ key: 'bizTotalIncome', label: '사업소득 합계' },
		{ key: 'bizIncomeTax', label: '사업소득세' },
		{ key: 'bizIncomeRegionTax', label: '사업소득지방세' },
		{ key: 'bizDeductionAfterTax', label: `${bizDeductionAfterTaxComment}` },
		// { key: 'dedectTaxEtc', label: '공제계' },
		{ key: 'bizDeductWage', label: '실수령액' },

		// { key: 'dcRetireFund', label: 'DC형 퇴직연금' },
	].filter(Boolean);

	const sortFunctionsCompany = {
		companyName: (a, b) => safeStringCompare(a.companyName, b.companyName),
		yearMonth: (a, b) => safeStringCompare(a.yearMonth, b.yearMonth),
		workerSocialNumber: (a, b) =>
			safeStringCompare(a.workerSocialNumber, b.workerSocialNumber),
		year: (a, b) => safeStringCompare(a.year, b.year),
		month: (a, b) => safeStringCompare(a.month, b.month),
		workerComNumber: (a, b) =>
			safeStringCompare(a.workerComNumber, b.workerComNumber),
		workerDivision: (a, b) =>
			safeStringCompare(a.workerDivision, b.workerDivision),
		workerTitle: (a, b) => safeStringCompare(a.workerTitle, b.workerTitle),
		worker: (a, b) => safeStringCompare(a.worker, b.worker),
		workerEnteredDate: (a, b) =>
			safeStringCompare(a.workerEnteredDate, b.workerEnteredDate),

		bizIncome: (a, b) => safeStringCompare(a.bizIncome, b.bizIncome),
		bizTotalIncome: (a, b) =>
			safeStringCompare(a.bizTotalIncome, b.bizTotalIncome),
		bizIncomeTax: (a, b) => safeStringCompare(a.bizIncomeTax, b.bizIncomeTax),
		bizIncomeRegionTax: (a, b) =>
			safeStringCompare(a.bizIncomeRegionTax, b.bizIncomeRegionTax),
		bizDeductionAfterTax: (a, b) =>
			safeStringCompare(a.bizDeductionAfterTax, b.bizDeductionAfterTax),
		// dedectTaxEtc: (a, b) => safeStringCompare(a.dedectTaxEtc, b.dedectTaxEtc),
		bizDeductWage: (a, b) =>
			safeStringCompare(a.bizDeductWage, b.bizDeductWage),

		// dcRetirefund: (a, b) => safeStringCompare(a.dcRetirefund, b.dcRetirefund),
	};

	const formattedDataCompany = bizWorkers
		.filter(
			(result) =>
				result.companyInfo.companyId === id ||
				result?.companyInfo?.agentUid === id ||
				currentUserProfile?.authLevel >= 100
		)
		.filter((result) => result?.workerInfo?.dayWorker !== true)
		.map((result) => ({
			companyName: result?.companyInfo?.companyName,
			yearMonth: result?.info?.yearMonth,
			workerSocialNumber: `${decrypto(
				result?.workerInfo?.workerSocialNumberFront,
				String(process.env.CRYPTO_KEY)
			)}-${decrypto(
				result?.workerInfo?.workerSocialNumberBack,
				String(process.env.CRYPTO_KEY)
			)}`,
			year: result?.info?.year,
			month: result?.info?.month,
			workerComNumber: result?.workerInfo?.workerComNumber,
			workerDivision: result?.workerInfo?.workerDivisions,
			workerTitle: result?.workerInfo?.workerTitles,
			worker: result?.workerInfo?.worker,
			workerEnteredDate: result?.workerInfo?.workerEnteredDate,
			bizIncome: numberToCommaString(result?.bizIncome),
			bizTotalIncome: numberToCommaString(result?.bizTotalIncome),
			bizIncomeTax: numberToCommaString(
				result?.deductTaxAnd4In?.userFixedTax !== '' &&
					!isNaN(result?.deductTaxAnd4In?.userFixedTax)
					? result?.deductTaxAnd4In?.userFixedTax
					: result?.bizIncomeTax
			),
			bizIncomeRegionTax: numberToCommaString(
				result?.deductTaxAnd4In?.userFixedRegionTax !== '' &&
					!isNaN(result?.deductTaxAnd4In?.userFixedRegionTax)
					? result?.deductTaxAnd4In?.userFixedRegionTax
					: result?.bizIncomeRegionTax
			),
			bizDeductionAfterTax: numberToCommaString(result?.bizDeductionAfterTax),
			bizDeductWage: numberToCommaString(result?.bizDeductWage),
		}));

	const footerDataCompany = {
		companyName: '',
		yearMonth: '',
		workerSocialNumber: '',
		year: '',
		month: '',
		workerComNumber: '',
		workerDivision: '',
		workerTitle: '',
		worker: '',
		workerEnteredDate: '합계',
		bizIncome: numberToCommaString(sumOfBizIncomeCom(bizWorkers)),
		bizTotalIncome: numberToCommaString(sumOfBizTotalIncomeCom(bizWorkers)),
		bizIncomeTax: numberToCommaString(sumOfBizIncomeTaxCom(bizWorkers)),
		bizIncomeRegionTax: numberToCommaString(
			sumOfBizIncomeRegionTaxCom(bizWorkers)
		),

		bizDeductionAfterTax: numberToCommaString(
			sumOfBizDeductAfterTaxCom(bizWorkers)
		),
		bizDeductWage: numberToCommaString(sumOfBizDeductWageCom(bizWorkers)),
	};

	const conbinedDataCompany = [...formattedDataCompany, footerDataCompany];

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<>
			{paydocus.length !== 0 && (
				<>
					<SortableTable
						headers={headersCompany}
						data={conbinedDataCompany}
						sortFunctions={sortFunctionsCompany}
						title={titleOfTable}
					/>
				</>
			)}
		</>
	);
}
