import React from 'react';
import { josaUnType } from '../../../../../../app/common/util/util';

// import { josaGaType, josaUnType, josaWaType } from '../../../../../../app/common/util/util';

export default function DeawangOilSC({ worker, companyName }) {
	const companyUn = josaUnType(companyName);
	// const companyGa = josaGaType(companyName);
	// const companyWa = josaWaType(companyName);
	// const workerUn = josaUnType(worker);

	return (
		<>
			{(companyName === '대왕석유무역(주)' ||
				companyName === '노무법인 인업 김경모') && (
				<div className='jau__contents'>
					<span style={{ fontSize: '1rem' }}>
						<strong>제8조 기타 특약사항 등</strong>
					</span>
					<ul>
						<li>
							{`① ${companyUn} 매년 또는 특정주기별로 ${worker}의 임금액을 결정할 때, ${worker}의 인사고과 및 ${companyName}의 경영실적 등에 의거 ${worker}의 연간 임금총액의 10% 범위 내에서 임금총액을 가감 조정할 수 있다.`}
						</li>
						<b>
							<li style={{ color: 'blue' }}>
								{`위 ①항 확인자 : ${worker} (인 또는 sign)`}
							</li>
						</b>
						{/* {(workerEnterDate < new Date("2021-09-01") || wageContractStartDate < new Date("2021-09-01"))  &&
              <>
                <li>{`② ${companyWa} ${workerUn} 본 근로계약 및 임금계약 이전에 ${companyGa} 임금의 구성항목 및 계산방법 지급방법 등이 표시되지 않았던 잘못된 근로계약서 및 연봉 근로계약서를 작성했지만, 
                이는 ${companyGa} 노동법을 잘못 해석해서 작성된 것이였음을 서로 확인하며, 본 근로계약 및 임금계약의 소정근로시간, 휴일, 휴가, 임금의 구성항목, 계산방법, 지급방법, 2주단위 탄력적 근로시간제(토요일 격주근무) 등의 
                근로조건은 입사시부터 ${worker} 본인에게 적용되던 근로조건임을 확인합니다.`}
                </li>
                <b>
                  <li style={{color : 'blue' }}>
                    {`위 ②항 확인자 : ${worker} (인 또는 sign)`}
                  </li>
                </b>
              </>
            } */}
					</ul>
				</div>
			)}
		</>
	);
}
